import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import Select, { ActionMeta, Props, ValueType } from 'react-select';
import { GroupedOptions, SingleSelectOption } from '@api';
import { allOption, fileOption } from './new-select.constants';
import { isEqualFileOption } from './new-select.utils';
import { useMediaPoints } from '@core/hooks';

type NewSelectProps = Props<SingleSelectOption> & {
  label?: string;
  caption?: string;
  isError?: boolean;
  withFileOption?: boolean;
  classnames?: {
    root?: string;
    select?: string;
    caption?: string;
  };
};

const useNewSelect = ({
  options = [],
  onChange,
  isMulti,
  value,
  withFileOption
}: NewSelectProps) => {
  const selectRef = useRef<Select>(null);
  const actualValue = useRef<NewSelectProps['value']>(value);
  const { isTabletDownOrEqual } = useMediaPoints();

  const formattedOptions: SingleSelectOption[] = useMemo(
    () =>
      (Array.isArray(options) ? options : []).flatMap((option) =>
        option.options ? option.options : option
      ),
    [options.length]
  );

  const selectOptions = useMemo(() => {
    if (isMulti) {
      const additionalOptions = withFileOption
        ? [fileOption, allOption]
        : [allOption];

      return [...additionalOptions, ...options] as
        | GroupedOptions[]
        | SingleSelectOption[];
    }

    return options;
  }, [options.length, isMulti, withFileOption]);

  const _onChange = (
    nextValue: ValueType<SingleSelectOption>,
    meta: ActionMeta<SingleSelectOption>
  ) => {
    const getNewValue = () => {
      if (meta.action === 'clear') {
        return null;
      }

      if (meta?.option?.value === fileOption.value) {
        selectRef.current.blur();
        return [fileOption];
      }

      if (meta?.option?.value === allOption.value) {
        const isAllSelected =
          Array.isArray(actualValue.current) &&
          actualValue.current.length === formattedOptions.length;

        return isAllSelected
          ? null
          : (formattedOptions as SingleSelectOption[]);
      }

      if (Array.isArray(nextValue)) {
        return nextValue.filter(({ value }) => value !== fileOption.value);
      }

      return nextValue;
    };

    const newValue = getNewValue();

    //magic
    //value provided from props is one-step-late
    actualValue.current = newValue;

    onChange(newValue, meta);
  };

  const _isOptionSelected = (option: SingleSelectOption) => {
    if (!isMulti) {
      return (
        option.value ===
        (actualValue.current as unknown as SingleSelectOption)?.value
      );
    }

    if (!Array.isArray(actualValue.current)) return false;

    if (isEqualFileOption(option)) {
      return isEqualFileOption(actualValue.current[0]);
    }

    //important order, must be after file option check
    const isAllSelected =
      Array.isArray(actualValue.current) &&
      actualValue.current.length === formattedOptions.length;

    return (
      isAllSelected ||
      (actualValue.current as SingleSelectOption[]).some(
        ({ value }) => value === option.value
      )
    );
  };

  const _noOptionMessage = useCallback(
    () =>
      options.length ? 'За даним запитом нічого не знайдено' : 'Список пустий',
    [options.length]
  );

  const closeMenuOnScroll = (event: Event) => {
    if (!selectRef.current.select.menuListRef) {
      return false;
    }

    return (selectRef.current.select.menuListRef as any) !== event.target;
  };

  useLayoutEffect(() => {
    if (!isTabletDownOrEqual || !selectRef.current?.state?.menuIsOpen) return;

    const timer = setTimeout(() => {
      (
        selectRef.current.select.menuListRef as unknown as HTMLElement
      ).scrollIntoView();
    }, 75);

    return () => {
      clearTimeout(timer);
    };
  }, [selectRef.current?.state?.menuIsOpen]);

  return {
    isTabletDownOrEqual,
    selectRef,
    isOptimized: formattedOptions.length > 600,
    _isOptionSelected,
    _onChange,
    _noOptionMessage,
    selectOptions,
    closeMenuOnScroll
  };
};

export { useNewSelect, NewSelectProps };
