import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useLocation } from 'react-router';
import { isEqual } from 'lodash';
import copyToClipboard from 'copy-to-clipboard';

import {
  getCodes,
  getIdent,
  getIdentInfo,
  saveClient,
  setEditing,
  updateIdentification
} from '@clients/store';
import { useClientId } from '@clients/pages/edit-client/hooks';
import { useTypedSelector } from '@shared/lib/use-typed-selector';
import { show } from '@store/toast';
import { Warning } from './identification.types';
import { renderCellWithTooltip } from './data-grid-with-tooltip.component';
import moment from 'moment';

const useIdentProps = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { selectedIdent, ident } = useTypedSelector((state) => state.clients);

  const clientId = useClientId();

  const identificationInfo = useFormik({
    initialValues: {
      id: selectedIdent?.id,
      fllIdent: selectedIdent?.fllIdent,
      docsIdent: selectedIdent?.docsIdent,
      questIdent: selectedIdent?.questIdent,
      innIdent: selectedIdent?.innIdent,
      photoIdent: selectedIdent?.photoIdent,
      isSuccess: selectedIdent?.isSuccess,
      nextIdentDate: moment(selectedIdent?.nextIdentDate?.slice(0, 10)).format(
        'DD-MM-YYYY'
      ),
      identDate: moment(selectedIdent?.identDate?.slice(0, 10)).format(
        'DD-MM-YYYY'
      )
    },
    onSubmit: (values, { setSubmitting }) => {
      const updateValues = {
        isSuccess: values?.isSuccess,

        nextIdentDate: moment(values?.nextIdentDate).format('DD-MM-YYYY'),
        identDate: moment(values?.identDate).format('DD-MM-YYYY'),
        fllIdent: values?.fllIdent,
        docsIdent: values?.docsIdent,
        questIdent: values?.questIdent,
        innIdent: values?.innIdent,
        photoIdent: values?.photoIdent
      };
      dispatch(updateIdentification({ ...updateValues, id: selectedIdent.id }));
      dispatch(saveClient(values));
      setSubmitting(false);
      dispatch(setEditing(false));
      dispatch(getIdentInfo(clientId));
    },
    enableReinitialize: true
  });

  const onOpen = ({ id }: { id: string }) => {
    dispatch(getIdent(id));
  };

  useEffect(() => {
    dispatch(getIdentInfo(clientId));
    dispatch(getCodes(''));
  }, [pathname]);

  useEffect(() => {
    dispatch(
      setEditing(
        !isEqual(identificationInfo.initialValues, identificationInfo.values)
      )
    );
  }, [identificationInfo.values, identificationInfo.initialValues]);

  return {
    identification: (ident ?? []).map((value) => ({
      id: value?.id,
      identId: value?.id,
      isSuccess: value?.isSuccess == true ? 'Так' : 'Ні',
      nextIdentDate: value?.nextIdentDate?.slice(0, 10),
      identDate: value?.identDate?.slice(0, 10)
    })),

    identificationInfo,
    onOpen
  };
};

const useIdentificationWarningsProps = () => {
  const dispatch = useDispatch();
  const { selectedIdent } = useTypedSelector((state) => state.clients);

  const warnings: Warning[] = (selectedIdent?.warnings ?? []).map(
    ({ code, id, description }) => ({
      id,
      title: code.title,
      description
    })
  );

  const onCopyActionClick = (params: GridRowParams<Warning>) => () => {
    copyToClipboard(params.row.description);

    dispatch(show(`Успішно скопійовано`, 'success'));
  };

  return { warnings, onCopyActionClick };
};

const warningsColumns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Код проблеми',
    minWidth: 200
  },
  {
    field: 'description',
    headerName: 'Опис проблеми',
    flex: 1,
    renderCell: renderCellWithTooltip
  }
];

const columns: GridColDef[] = [
  {
    field: 'identId',
    headerName: 'Ідентифікатор',
    width: 130,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'isSuccess',
    headerName: 'Успішна',
    width: 80,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'nextIdentDate',
    headerName: 'Дата наступної ідентифікації',
    width: 230,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'identDate',
    headerName: 'Дата проведення ідентифікації',
    type: 'number',
    width: 232,
    sortable: false,
    disableColumnMenu: true
  }
];

export {
  useIdentProps,
  useIdentificationWarningsProps,
  columns,
  warningsColumns
};

export type { Warning };
