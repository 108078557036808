/* eslint-disable no-console */
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@shared/lib/use-typed-selector';
import { useEffect } from 'react';
import {
  getAddress,
  getAddressTypes,
  getAdressInfo,
  getCountryNames,
  getHouseTypes,
  getRegions,
  getRoomTypes,
  getSettlements,
  getSettlementTypes,
  getStreetTypes,
  setEditing,
  updateAddress
} from '@clients/store';
import { useFormik } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import { useClientId } from '@clients/pages/edit-client/hooks';
import { useLocation } from 'react-router';
import { isEqual } from 'lodash';
import { toSelectOption } from '@clients/pages/edit-client/lib';

const useAdressProps = () => {
  const dispatch = useDispatch();
  const clientId = useClientId();
  const { pathname } = useLocation();

  const {
    settlements,
    roomTypes,
    houseTypes,
    streetTypes,
    regions,
    countries,
    addressTypes,
    adress,
    selectedAddress,
    settlementsPending,
    regionsPending,
    countriesPending
  } = useTypedSelector((state) => state.clients);

  const addressInfo = useFormik({
    initialValues: {
      addressTypeId: selectedAddress?.type?.id,
      countryId: selectedAddress?.country?.id,
      settlementRegionId: selectedAddress?.settlementRegion?.id,
      settlementTypeId: selectedAddress?.settlement?.type?.title,
      settlementId: selectedAddress?.settlement?.id,
      district: selectedAddress?.district,
      streetTypeId: selectedAddress?.streetType?.id,
      street: selectedAddress?.street,
      houseTypeId: selectedAddress?.houseType?.id,
      houseNumber: selectedAddress?.houseNumber,
      housePartNumber: selectedAddress?.housePartNumber,
      roomTypeId: selectedAddress?.roomType?.id,
      roomNumber: selectedAddress?.roomNumber,
      zipCode: selectedAddress?.zipCode,
      numberMonthsStay: selectedAddress?.numberMonthsStay,
      statusId: selectedAddress?.status?.title,
      fullAddress: selectedAddress?.fullAddress
    },

    onSubmit: (values, { setSubmitting }) => {
      const updateValues = {
        typeId: values?.addressTypeId,
        countryId: values?.countryId,
        settlementId: values?.settlementId,
        settlementRegionId: values?.settlementRegionId,
        zipCode: values?.zipCode,
        // @ts-expect-error
        numberMonthsStay: parseInt(values?.numberMonthsStay),
        district: values?.district,
        street: values?.street,
        streetTypeId: values?.streetTypeId,
        houseNumber: values?.houseNumber,
        houseTypeId: values?.houseTypeId,
        housePartNumber: values?.housePartNumber,
        roomNumber: values?.roomNumber,
        roomTypeId: values?.roomTypeId,
        fullAddress: values?.fullAddress
      };

      dispatch(updateAddress({ ...updateValues, id: selectedAddress.id }));
      setSubmitting(false);
      setTimeout(() => {
        dispatch(getAdressInfo(clientId));
      }, 1000);
      dispatch(setEditing(false));
    },
    enableReinitialize: true
  });

  useEffect(() => {
    dispatch(
      setEditing(!isEqual(addressInfo.values, addressInfo.initialValues))
    );
  }, [addressInfo.values, addressInfo.initialValues]);

  const onOpen = (event) => dispatch(getAddress(event.id));

  useEffect(() => {
    dispatch(getAdressInfo(clientId));
  }, [pathname]);

  useEffect(() => {
    if (!selectedAddress) return;

    dispatch(getStreetTypes());
    dispatch(getAddressTypes());
    dispatch(getSettlementTypes());
    dispatch(getCountryNames());
    dispatch(getHouseTypes());
    dispatch(getRoomTypes());
  }, [selectedAddress]);

  useEffect(() => {
    if (!selectedAddress) return;

    dispatch(getRegions(addressInfo.values.countryId));
  }, [addressInfo.values.countryId]);

  useEffect(() => {
    if (!selectedAddress) return;

    dispatch(getSettlements(addressInfo.values.settlementRegionId));
  }, [addressInfo.values.settlementRegionId]);

  return {
    isCountryEntered: !!addressInfo.values.countryId,
    isRegionEntered: !!addressInfo.values.settlementRegionId,

    settlementsPending,
    regionsPending,
    countriesPending,

    regionsNames: regions.map(toSelectOption),
    countriesNames: countries.map(toSelectOption),
    houseTypesNames: houseTypes.map(toSelectOption),
    roomTypesNames: roomTypes.map(toSelectOption),
    settlementsNames: settlements.map(toSelectOption),
    strTypes: streetTypes.map(toSelectOption),

    types: addressTypes
      .filter(
        window.location.href.includes('fo')
          ? (item) => item?.clientType == 'CUSTOMER'
          : (item) => item?.clientType == 'LEGAL_ENTITY'
      )
      .map((cont) => ({
        id: cont?.id,
        name: cont?.title,
        type: cont?.clientType
      })),
    identification: (adress ?? []).map((value) => ({
      id: value?.id,
      addressTypeId: value?.type?.title,
      countryId: value?.country?.title,
      settlementId: value?.settlement?.title,
      zipCode: value?.zipCode,
      statusId: value?.status?.title
    })),
    addressInfo,
    addressValues,
    onOpen,

    onCountryChange: (countryId: string) => {
      addressInfo.setFieldValue('countryId', countryId);
      addressInfo.setFieldValue('settlementRegionId', null);
      addressInfo.setFieldValue('settlementId', null);
      addressInfo.setFieldValue('settlementTypeId', null);
    },

    onRegionChange: (regionId: string) => {
      addressInfo.setFieldValue('settlementRegionId', regionId);
      addressInfo.setFieldValue('settlementId', null);
      addressInfo.setFieldValue('settlementTypeId', null);
    },

    onSettlementChange: (settlementId: string) => {
      addressInfo.setFieldValue('settlementId', settlementId);

      const selected = settlements.find((s) => s.id === settlementId);

      addressInfo.setFieldValue(
        'settlementTypeId',
        // @ts-expect-error Wrong typing
        selected?.type?.title ?? null
      );
    }
  };
};

const addressValues = [
  { name: 'district', label: 'Район' },
  { name: 'street', label: 'Вулиця' },
  { name: 'houseNumber', label: 'Номер будинку' },
  { name: 'housePartNumber', label: 'Номер Частини будинку' },
  { name: 'roomNumber', label: 'Номер кімнати' },
  { name: 'zipCode', label: 'Індекс' },
  { name: 'numberMonthsStay', label: 'К-сть місяців' },
  { name: 'statusId', label: 'Статус' }
];

const columns: GridColDef[] = [
  {
    field: 'addressTypeId',
    headerName: 'Тип',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'settlementId',
    headerName: 'Населений пункт',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'countryId',
    headerName: 'Країна',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'zipCode',
    headerName: 'Індекс',
    width: 80,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'statusId',
    headerName: 'Статус',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  }
];

export { useAdressProps, columns };
