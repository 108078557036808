import { useSelector, useDispatch } from 'react-redux';
import { State } from '@store';
import { show } from '@store/toast';

/**
 * <Toast /> props
 */
const useToastProps = () => {
  const dispatch = useDispatch();
  const { toasts } = useSelector((state: State) => state.toast);

  const onCloseClick = (id: string) => {
    dispatch(show.end(id));
  };

  return {
    toasts,
    onCloseClick
  };
};

export { useToastProps };
