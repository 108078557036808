import React, { FC, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeActiveTab, setEditing } from '@clients/store';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

import {
  ActivityCodes,
  Adress,
  Attributes,
  Contacts,
  Documents,
  ExtendedInformation,
  FatcaInformation,
  Identification,
  Incomes,
  LegalExtendedInformation,
  LegalMainInformation,
  MainInformation,
  Property,
  Relationships,
  Restrictions
} from '../components';
import styles from './client-info.module.scss';
import { Role } from '@entities/role';
import { useHasRole } from '@entities/role/model';
import { Tab, Tabs } from '@mui/material';

const ClientInfo: FC<{ children?: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();

  const { activeTab } = useTypedSelector((state) => state.clients);

  const tabItems = [
    {
      id: 'personalInfo',
      title: 'Персональна інформація',
      component: window.location.href.includes('legal-entity') ? (
        <LegalMainInformation />
      ) : (
        <MainInformation />
      ),
      role: window.location.href.includes('legal-entity')
        ? Role.LegalEntityRead
        : Role.PhysicalsRead
    },
    {
      id: 'moreInfo',
      title: 'Розширена інформація',
      component: window.location.href.includes('legal-entity') ? (
        <LegalExtendedInformation />
      ) : (
        <ExtendedInformation />
      ),
      role: window.location.href.includes('legal-entity')
        ? Role.LegalEntityFullRead
        : Role.PhysicalsFullRead
    },
    {
      id: 'contact',
      title: 'Контакти',
      component: <Contacts />,
      role: Role.ContactsRead
    },

    {
      id: 'document',
      title: 'Документи',
      component: <Documents />,
      role: Role.DocumentRead
    },
    {
      id: 'address',
      title: 'Адреси',
      component: <Adress />,
      role: Role.AddressesRead
    },
    {
      id: 'identification',
      title: 'Ідентифікація',
      component: <Identification />,
      role: Role.IdentificationHistoryRead
    },
    {
      id: 'fatca',
      title: 'Fatca',
      component: <FatcaInformation />,
      role: Role.FatcaRead,
      skip: window.location.href.includes('legal-entity')
    },
    {
      id: 'property',
      title: 'Майно',
      component: <Property />,
      role: Role.OwnershipRead
    },
    {
      id: 'incomes',
      title: 'Доходи',
      component: <Incomes />,
      role: Role.IncomesRead
    },
    {
      id: 'relationships',
      title: "Зв'язки",
      component: <Relationships />,
      role: Role.RelationshipsRead
    },
    {
      id: 'attributes',
      title: 'Атрибути',
      component: <Attributes />,
      role: Role.AdditionalAttributesRead
    },
    {
      id: 'activityCodes',
      title: 'ФОП',
      component: <ActivityCodes />,
      role: Role.IndividualEntrepreneursRead,
      skip: window.location.href.includes('legal-entity')
    },
    {
      id: 'restrictions',
      title: 'Обмеження',
      component: <Restrictions />,
      role: Role.RestrictionsRead
    }
  ];

  const onTabChange = (newTab: string) => {
    dispatch(changeActiveTab(newTab));
  };

  const showTabContent = () =>
    tabItems.find((tabItem) => tabItem.id === activeTab).component;

  useEffect(
    () => () => {
      dispatch(changeActiveTab(tabItems[0].id));
    },
    []
  );

  return (
    <>
      <div className={styles.fields}>
        {showTabContent()}

        {children}
      </div>

      <Tabs
        orientation='vertical'
        variant='scrollable'
        value={activeTab}
        onChange={(_, id: string) => {
          onTabChange(id);
          dispatch(setEditing(false));
        }}
        sx={{
          ml: 3,
          borderLeft: 1,
          borderColor: 'divider',
          '& .MuiTabs-indicator': { right: 'unset', left: 0 }
        }}
      >
        {tabItems.map(({ title, id, role, skip }) => {
          const canAccess = useHasRole(role);

          if (!canAccess || skip) return null;

          return <Tab role={role} key={id} label={title} value={id} />;
        })}
      </Tabs>
    </>
  );
};

export { ClientInfo };
