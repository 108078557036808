import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import {
  getRestrCriteria,
  getRestriction,
  getRestrictionInfo,
  getRestrSources,
  getRestrTypes,
  saveClient
} from '@clients/store';
import { useFormik } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import { useClientId } from '@clients/pages/edit-client/hooks';
import { useLocation } from 'react-router';
import { useTypedSelector } from '@shared/lib/use-typed-selector';
import { toSelectOption } from '../../lib';

const useRestrictionProps = () => {
  const dispatch = useDispatch();
  const clientId = useClientId();
  const { pathname } = useLocation();

  const {
    restrCriteria,
    restrSources,
    restrTypes,
    selectedRestriction,
    restrictions
  } = useTypedSelector((state) => state.clients);

  const initialValues = selectedRestriction?.restrictionContacts
    ? {
        contactId: selectedRestriction?.restrictionContacts?.[0]?.contactValue,
        contactValue:
          selectedRestriction?.restrictionContacts?.[0]?.contactValue,
        contactType: selectedRestriction?.restrictionContacts?.[0]?.contactType,
        restrTypeId: selectedRestriction?.type?.id,
        restrSourceId: selectedRestriction?.source?.id,
        restrCriterionId: selectedRestriction?.criteria?.id,
        //
        docId: selectedRestriction?.restrictionDocs?.[0]?.series,
        number: selectedRestriction?.restrictionDocs?.[0]?.number,
        series: selectedRestriction?.restrictionDocs?.[0]?.series,
        docType: selectedRestriction?.restrictionDocs?.[0]?.docType,
        //
        addressId: selectedRestriction?.restrictionAddresses?.[0]?.cityName,
        cityName: selectedRestriction?.restrictionAddresses?.[0]?.cityName,
        streetName: selectedRestriction?.restrictionAddresses?.[0]?.streetName,
        building: selectedRestriction?.restrictionAddresses?.[0]?.building,
        flat: selectedRestriction?.restrictionAddresses?.[0]?.flat,
        //
        firstName: selectedRestriction?.restrictionProfileInfos?.[0]?.firstName,
        secondName:
          selectedRestriction?.restrictionProfileInfos?.[0]?.secondName,
        inn: selectedRestriction?.restrictionProfileInfos?.[0]?.inn,
        nameJur: selectedRestriction?.restrictionProfileInfos?.[0]?.nameJur
      }
    : {
        docId: selectedRestriction?.id,
        number: selectedRestriction?.restrictionDocs?.[0]?.number,
        series: selectedRestriction?.restrictionDocs?.[0]?.series,
        docType: selectedRestriction?.restrictionDocs?.[0]?.docType
      };

  const restrictionInfo = useFormik({
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(saveClient(values));
      setSubmitting(false);
    },
    enableReinitialize: true
  });

  const onOpen = ({ id }: { id: string }) => {
    dispatch(getRestriction(id));
  };

  useEffect(() => {
    dispatch(getRestrictionInfo(clientId));
    dispatch(getRestrTypes(''));
    dispatch(getRestrCriteria(''));
    dispatch(getRestrSources(''));
  }, [pathname]);

  return {
    restrictionVariant: selectedRestriction?.restrictionContacts?.[0]
      ? 'contact'
      : selectedRestriction?.restrictionAddresses?.[0]
      ? 'address'
      : selectedRestriction?.restrictionDocs?.[0]
      ? 'docs'
      : selectedRestriction?.restrictionProfileInfos?.[0]
      ? 'profile'
      : 'qwer',
    sources: restrSources?.map(toSelectOption),
    criteria: restrCriteria?.map(toSelectOption),
    types: restrTypes?.map(toSelectOption),
    identification: (restrictions ?? []).map((value) => ({
      id: value?.id,
      restrTypeId: value?.type?.title,
      restrSourceId: value?.source?.title,
      restrCriterionId: value?.criteria?.title,
      createdAt: value?.createdAt?.slice(0, 10),
      updatedAt: value?.updatedAt?.slice(0, 10)
    })),
    restrictionInfo,
    onOpen
  };
};
const columns: GridColDef[] = [
  {
    field: 'restrTypeId',
    headerName: 'Тип обмеження',
    width: 127,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'restrSourceId',
    headerName: 'Джерело обмеження',
    width: 165,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'restrCriterionId',
    headerName: 'Критерій',
    width: 95,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'createdAt',
    headerName: 'Дата створення',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'updatedAt',
    headerName: 'Дата оновлення',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  }
];

export { useRestrictionProps, columns };
