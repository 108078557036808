import React from 'react';
import { components } from 'react-select';
import { SelectMultiValueProps } from './multi-value.props';
import styles from './multi-value.module.scss';
import classNames from 'classnames';

const MultiValue: React.FC<SelectMultiValueProps> = ({
  children,
  ...props
}) => (
  <components.MultiValue
    {...props}
    className={classNames(props.className, styles.multi)}
  >
    {children}
  </components.MultiValue>
);

export { MultiValue };
