import { object, string } from 'yup';

const selectSchema = object().required("Поле обов'язкове").nullable();

const stringSelectSchema = selectSchema.shape({
  value: string()
});

const expandedSelectSchema = selectSchema.shape({
  label: string(),
  value: string()
});

export { selectSchema, expandedSelectSchema, stringSelectSchema };
