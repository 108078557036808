import { make } from 'redux-chill';

import {
  CreateDirectory,
  Directories,
  ApplyChangesToDirectoryDto,
  CreateDirectoryDto,
  DirectoryVariant,
  ProductOwnerDto,
  SearchDirectoryContract,
  SearchDirectoryItemsContract
} from '@shared/api';

const selectDirectoryVariant = make(
  '[entity/directory] select directory variant'
).stage((variant: DirectoryVariant) => ({ variant }));

const searchDirectories = make('[entity/directory] search directories')
  .stage((query: string, variant: DirectoryVariant) => ({ query, variant }))
  .stage('done', (results: SearchDirectoryContract[]) => ({ results }))
  .stage('fail');
const setQuery = make('[query] set').stage((query: string) => query);
const setButton = make('[button] set').stage(
  (isConstant: boolean) => isConstant
);
const setSelected = make('[selected] set').stage((selected) => selected);
const setType = make('[type] set').stage((type) => type);
const selectDirectory = make('[entity/directory] select dictionary id').stage(
  (directory: SearchDirectoryContract) => ({ directory })
);

const searchDirectoryItems = make('[entity/directory] search directories items')
  .stage('done', (results: SearchDirectoryItemsContract[]) => ({ results }))
  .stage('fail');

const getDirectories = make('[searchedDirectories] get')
  .stage('success', (searchedDirectories: Directories[]) => searchedDirectories)
  .stage('fail');

const getProductDirectories = make('[searchedProductDirectories] get')
  .stage(
    'success',
    (searchedProductDirectories: Directories[]) => searchedProductDirectories
  )
  .stage('fail');
const getBusinessDirectories = make('[searchedBusinessDirectories] get')
  .stage(
    'success',
    (searchedBusinessDirectories: Directories[]) => searchedBusinessDirectories
  )
  .stage('fail');
const getDirectory = make('[selectedDirectory] get')
  .stage((directoryId: string) => directoryId)
  .stage('success', (directory) => directory)
  .stage('fail');
const postDirectory = make('[newDirectory] post')
  .stage((data) => data)
  .stage('success', (createdDirectory: CreateDirectory) => createdDirectory)
  .stage('fail');
const postProduct = make('[newProduct] post')
  .stage((data) => data)
  .stage('success', (createdProduct: CreateDirectory) => createdProduct)
  .stage('fail');
const postBusinessOwner = make('[businessOwner] post')
  .stage((data) => data)
  .stage('success', (createdOwner: CreateDirectory) => createdOwner)
  .stage('fail');

const updateDirectory = make('[updatedDirectory] put')
  .stage((data) => data)
  .stage('success', (selectedDirectory) => selectedDirectory);
const applyChanges = make('[entity/directory] apply changes to items')
  .stage(
    (
      id: SearchDirectoryContract['id'],
      dto: ApplyChangesToDirectoryDto,
      onResult: (success: boolean) => void
    ) => ({
      id,
      dto,
      onResult
    })
  )
  .stage('done', (results: SearchDirectoryItemsContract[]) => ({ results }))
  .stage('fail');

const getProductOwners = make('[entity/directory] get product owners')
  .stage('done', (results: ProductOwnerDto[]) => ({ results }))
  .stage('fail');

const createDirectory = make('[entity/directory] create directory')
  .stage(
    (
      variant: DirectoryVariant,
      dto: CreateDirectoryDto,
      onResult: (success: boolean) => void
    ) => ({
      variant,
      dto,
      onResult
    })
  )
  .stage('done', (directory: SearchDirectoryContract) => ({ directory }));

export {
  postBusinessOwner,
  postProduct,
  setType,
  getBusinessDirectories,
  getProductDirectories,
  setButton,
  updateDirectory,
  postDirectory,
  setSelected,
  getDirectory,
  setQuery,
  getDirectories,
  applyChanges,
  selectDirectory,
  getProductOwners,
  searchDirectories,
  searchDirectoryItems,
  selectDirectoryVariant,
  createDirectory
};
