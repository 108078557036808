import React from 'react';
import classNames from 'classnames';
import { ErrorContainerProps } from './error-container.props';
import styles from './error-container.module.scss';

const ErrorContainer: React.FC<ErrorContainerProps> = ({
  className,
  children,
  error
}) => (
  <div className={classNames(styles.container, className)}>
    {children}

    <span className={styles.text}>
      {Array.isArray(error) ? error.join(', ') : error}
    </span>
  </div>
);

export { ErrorContainer };
