import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  getChannels,
  getClientGoals,
  getCountryNames,
  getExtendedInfo,
  getFullProfileFoCustomer,
  getGenders,
  getGoalsInfo,
  getSegments
} from '@clients/store';
import { useClientId } from '@clients/pages/edit-client/hooks';
import { useTypedSelector } from '@shared/lib/use-typed-selector';
import { toSelectOption } from '../../lib';

const useExtendedInformationProps = () => {
  const dispatch = useDispatch();
  const clientId = useClientId();

  const { clientGoals, channels, segments, countries, genders } =
    useTypedSelector((state) => state.clients);

  useEffect(() => {
    dispatch(getFullProfileFoCustomer(clientId));
    dispatch(getExtendedInfo());
    dispatch(getGoalsInfo());
    dispatch(getCountryNames(''));
    dispatch(getSegments(''));
    dispatch(getChannels(''));
    dispatch(getGenders(''));
    dispatch(getClientGoals(''));
  }, []);

  return {
    names: clientGoals,
    gendersNames: genders.map(toSelectOption),

    birthCountries: countries.map(toSelectOption),
    customerChannels: channels.map(toSelectOption),
    customerSegments: segments.map(toSelectOption)
  };
};

export { useExtendedInformationProps };
