import { useExtendedInformationProps } from './extended.props';
import styles from './extended.module.scss';
import React from 'react';
import { hoc } from '@core';
import { Field } from '@core/components';
import { SectionLayout } from '../atoms';
import { Role } from '@entities/role';

const ExtendedInformation = hoc(
  useExtendedInformationProps,
  ({
    names,
    gendersNames,

    birthCountries,
    customerChannels,
    customerSegments
  }) => (
    <SectionLayout
      read={Role.PhysicalsFullRead}
      update={Role.PhysicalsUpdate}
      disableFlex
    >
      {({ canUpdate }) => (
        <>
          <Field.Input
            className={styles.input}
            name={'lastName'}
            label={'Прізвище'}
            theme='primary'
            editable={canUpdate}
          />

          <Field.Input
            className={styles.input}
            name={'firstName'}
            label={"Ім'я"}
            theme='primary'
            editable={canUpdate}
          />

          <Field.Input
            className={styles.input}
            name={'middleName'}
            label={'По Батькові'}
            theme='primary'
            editable={canUpdate}
          />

          <Field.Input
            className={styles.input}
            name={'clientStatusId'}
            label={'Статус клієнта'}
            theme='primary'
            editable={false}
          />

          <Field.Select
            className={styles.inputMargin}
            name='genderId'
            options={gendersNames}
            label={'Стать'}
            disabled={!canUpdate}
          />

          <Field.Date
            className={styles.input}
            name={'calendarDateOfBirth'}
            label={'Дата народження'}
            theme='primary'
            editable={canUpdate}
            disabled={!canUpdate}
          />

          <Field.Input
            className={styles.input}
            name={'innCode'}
            label={'ІНН'}
            theme='primary'
            editable={canUpdate}
          />

          <Field.Input
            className={styles.input}
            name={'latFirstName'}
            label={"Лат. Ім'я"}
            theme='primary'
            editable={canUpdate}
          />

          <Field.Input
            className={styles.input}
            name={'latLastName'}
            label={'Лат. Прізвище'}
            theme='primary'
            editable={canUpdate}
          />

          <Field.NewSwitch
            name={'existsCredOut'}
            label={'Кредити в інших банках'}
            disabled={!canUpdate}
          />

          <Field.NewSwitch
            name={'isReligious'}
            label={'Релігіозний'}
            disabled={!canUpdate}
          />

          <Field.NewSwitch
            name={'isPublicFigure'}
            label={'Публічна особа'}
            disabled={!canUpdate}
          />

          <Field.NewSwitch
            name={'isResident'}
            label={'Резидент'}
            disabled={!canUpdate}
          />

          <Field.Input
            className={styles.input}
            name={'eddrCode'}
            label={'ЄДДР код'}
            theme='primary'
            editable={canUpdate}
          />

          <Field.Select
            className={styles.inputMargin}
            name='citizenCountryId'
            options={birthCountries}
            label={'Країна громадянства'}
            disabled={!canUpdate}
          />

          <Field.Select
            className={styles.inputMargin}
            name='birthCountryId'
            options={birthCountries}
            label={'Країна народження'}
            disabled={!canUpdate}
          />

          <Field.Select
            className={styles.inputMargin}
            name='segmentId'
            options={customerSegments}
            label={'Сегмент'}
            disabled={!canUpdate}
          />

          <Field.Select
            className={styles.inputMargin}
            name='channelId'
            options={customerChannels}
            label={'Джерело реєстрації'}
            disabled={!canUpdate}
          />

          <Field.Input
            className={styles.input}
            name={'registrationDate'}
            label={'Дата реєстрації'}
            theme='primary'
            editable={false}
          />

          <Field.Input
            className={styles.input}
            name={'registrationPlaceCode'}
            label={'Місце реєстрації'}
            theme='primary'
            editable={canUpdate}
          />

          <Field.NewMultipleSelect
            name='codes'
            names={names}
            inputLabel='Цілі'
            disabled={!canUpdate}
          />

          <div className={styles.switch}>
            <Field.NewSwitch
              name={'canDisturb'}
              label={'Можна турбувати'}
              disabled={!canUpdate}
            />

            <Field.NewSwitch
              name={'permReceivePromoMailings'}
              label={'Розсилка'}
              disabled={!canUpdate}
            />

            <Field.NewSwitch
              name={'permProcessData'}
              label={'Оброблення даних'}
              disabled={!canUpdate}
            />
          </div>
        </>
      )}
    </SectionLayout>
  )
);

export { ExtendedInformation };
