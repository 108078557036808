import { useMemo } from 'react';
import { ControlProps } from '@core/form';
import { DateFormat, DateUtils } from '@core/utils';
import moment from 'moment';

type CalendarProps = ControlProps & {
  format?: DateFormat;
  onChange?: (value: string, hide?: boolean) => any;
  onEdit?: () => void;
};

/**
 * <Calendar/> props
 */
const useCalendarProps = ({ value, format, onChange }: CalendarProps) => {
  const date = useMemo(() => moment(value, format).toDate(), [value]);

  const onPickerChange = (newDate: Date) => {
    onChange(DateUtils.formatDefault(newDate), true);
  };

  const showNextYear = () => {
    if (date.toString() != 'Invalid Date') {
      onChange(moment(date).add(1, 'year').format());
      return;
    }
    onChange(moment().add(1, 'year').format());
  };

  const showPreviousYear = () => {
    if (date.toString() != 'Invalid Date') {
      onChange(moment(date).subtract(1, 'year').format());
      return;
    }
    onChange(moment().subtract(1, 'year').format());
  };

  return {
    isValid: DateUtils.isValid(date),
    date,
    onPickerChange,
    showNextYear,
    showPreviousYear
  };
};

export { useCalendarProps };
