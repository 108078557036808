import { useSelector } from 'react-redux';
import { State } from '@store';
import { ControlProps } from '../../form/control';

/**
 * Props
 */
type NewSwitchProps<V = string> = ControlProps<V>;

const useNewSwitchProps = ({ onChange, value }: NewSwitchProps) => {
  const {
    clients: { client, activeTab }
  } = useSelector((state: State) => state);

  const onSwitchChanged = (event) => {
    onChange(event.target.checked);
  };

  const haveFATCA = client?.haveFATCA;

  return {
    activeTab,
    haveFATCA,
    onSwitchChanged,
    value
  };
};

export { useNewSwitchProps };
