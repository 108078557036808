import { show } from '@store/toast';
import { useDispatch } from 'react-redux';
import { ControlProps } from '../../form/control';
import { ReactNode, useMemo, useRef, useState } from 'react';

/**
 * Props
 */
type InputProps<V = string> = ControlProps<V> & {
  /**
   * Html input type
   */
  type?: 'text' | 'number' | 'password';

  /**
   * units of measure
   */
  units?: string;

  /**
   * Input mask
   */
  mask?: string;

  /**
   * Editable
   */
  editable?: boolean;

  /**
   * Mask char
   */
  maskChar?: string;

  /**
   * Focus handler
   */
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => any;

  /**
   * Blur handler
   */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => any;

  /**
   * Key down handler
   */
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => any;

  /**
   * After content
   */
  after?: JSX.Element | ReactNode;

  reference?: React.MutableRefObject<InputRef>;

  iconName?: string;

  _iconOnClick?: () => void;

  theme?: 'primary' | 'group';

  canEdit?: boolean;

  visibleValue?: boolean;

  group?: boolean;

  datepicker?: boolean;

  select?: boolean;

  showPlaceholder?: boolean;

  autocomplete?: boolean;

  socialIconName?: 'facebook' | 'linkedin' | 'google';

  customErrorsName?: 'country' | 'region' | 'city';

  formatValue?: (value: V) => V;

  icon?: ReactNode;
};

type InputRef = Pick<HTMLInputElement, 'blur' | 'focus'>;

/**
 * Use input
 */
const useInputProps = ({
  iconName,
  type,
  value,
  onBlur,
  onFocus,
  onChange,
  formatValue,
  reference,
  showPlaceholder
}: InputProps) => {
  const [focused, setFocused] = useState(false);
  const hasValue = Boolean(value) || value == '0';
  const input = useRef<HTMLInputElement>();
  const _onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);

    onFocus(event);
  };

  const _onBlur = (event) => {
    setFocused(false);

    onBlur(event);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!focused) setFocused(true);

    const { value } = event.target;

    onChange(!formatValue ? value : formatValue(value));
  };

  const dispatch = useDispatch();

  const outRef = useMemo(
    () => ({
      focus: () => {
        input.current.focus();
      },
      blur: () => {
        input.current.blur();
      }
    }),
    [input.current]
  );
  const showMessage = (message: string) => {
    dispatch(show(message, 'success', 3000));
  };
  if (reference) {
    reference.current = outRef;
  }

  const [isShowCustomError] = useState(false);
  const [errorMessage] = useState('');

  const [icon] = useState(iconName);
  const [inputType] = useState(type);

  return {
    icon,
    inputType,
    input,
    focused,
    _onBlur,
    _onFocus,
    hasValue,
    onInputChange,
    showMessage,
    iconName,
    showPlaceholder,
    isShowCustomError,
    errorMessage
  };
};

export { InputProps, InputRef, useInputProps };
