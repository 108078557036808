import { ControlProps } from '../../form/control';
import { uniqBy } from 'lodash';

type NewSwitchProps<V = string> = ControlProps<V> & {
  value: any[];
  names: { id: string; title: string };
};

const useNewMultipleSelectProps = ({
  value,
  names,
  onChange
}: NewSwitchProps) => {
  const handleChange = (event, selected) => {
    if (value?.find((val) => val.title === event.target.innerText)) {
      onChange(
        uniqBy(selected, 'id').filter(
          (val) => val.toString() !== event.target.innerText
        ) as any
      );

      return;
    }

    onChange(uniqBy(selected, 'id') as any);
  };

  return {
    handleChange,
    value,
    names
  };
};

export { useNewMultipleSelectProps };
