import React from 'react';
import classNames from 'classnames';
import { NewTextFieldProps } from './new-text-field.props';
import styles from './new-text-field.module.scss';

const NewTextField: React.FC<NewTextFieldProps> = ({
  label,
  error,
  className,
  classnames,
  disabled,
  caption,
  children
}) => (
  <label
    className={classNames(styles.wrapper, classnames?.root, {
      [styles.error]: !!error
    })}
  >
    <label
      className={classNames(styles.label, className, classnames?.labelRoot, {
        [styles.error]: !!error,
        [styles.disabled]: disabled
      })}
    >
      <span
        className={classNames(styles.span, classnames?.label, {
          [styles.disabled]: disabled
        })}
      >
        {label}
      </span>

      {children}
    </label>

    {caption && (
      <p className={classNames(styles.caption, classnames?.caption)}>
        {caption}
      </p>
    )}

    {error && (
      <span className={classNames(styles.errorMessage, classnames?.error)}>
        {error}
      </span>
    )}
  </label>
);

export { NewTextField };
