import React from 'react';
import classNames from 'classnames';
import styles from './label.module.scss';

/**
 * <Label />
 */
const Label: React.FC<React.HTMLAttributes<HTMLLabelElement>> = ({
  className,
  ...props
}) => <label className={classNames(styles.label, className)} {...props} />;

export { Label };
