import './app/styles/index.module.scss';

import React from 'react';
import { render } from 'react-dom';
import { createBrowserHistory } from 'history';

import { createStore } from '@store';
import { App } from '@app';
import { Provider } from './app/provider';

const setup = () => {
  const history = createBrowserHistory();
  const store = createStore(history);

  render(
    <Provider store={store} history={history}>
      <App />
    </Provider>,
    document.getElementById('app')
  );
};

setup();
