import React from 'react';
import { components } from 'react-select';
import classNames from 'classnames';
import { SelectControlProps } from './control.props';
import styles from './control.module.scss';

const Control: React.FC<SelectControlProps> = ({
  children,
  className,
  label: _,
  ...rest
}) => (
  <components.Control
    {...rest}
    className={classNames(className, styles.control, {
      [styles.focused]: rest.isFocused
    })}
  >
    {children}
  </components.Control>
);

export { Control };
