import React from 'react';
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon
} from '@mui/icons-material';

import { hoc } from '@core';
import { TableHeader } from '@app';

import { createClientData } from './client-list.lib';
import { ClientsTableFilter } from './clients-filter';
import { useClientsListProps } from './clients-list.props';
import styles from './clients-list.module.scss';
import moment from 'moment';

const ClientsList = hoc(
  useClientsListProps,
  ({
    openedId,
    names,
    clients,
    onOpenClick,
    isFilterOpen,
    closeFilter,
    openFilter,
    onIconClick,
    onRowOpen
  }) => (
    <div className={styles.flex1}>
      <TableHeader
        tableHeader='Клієнти ФО і ФОП'
        onOpenFilterClick={openFilter}
      />

      <div className={styles.flex1}>
        <TableContainer className={styles.flex1} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 66 }} />
                <TableCell>Дата народження</TableCell>
                <TableCell>Прізвище</TableCell>
                <TableCell>Ім'я</TableCell>
                <TableCell>По батькові</TableCell>
                <TableCell>Стать</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {clients?.map((client) => (
                <Row
                  key={client.id}
                  row={createClientData(
                    openedId,
                    onRowOpen,
                    () => onIconClick(client.id),
                    () => onOpenClick(client.id),
                    moment(client?.dateOfBirth).format('DD-MM-YYYY'),
                    client?.lastName,
                    client?.firstName,
                    client?.middleName,
                    client?.gender?.title,
                    names?.rnokpp,
                    client?.id,
                    names?.phone,
                    names?.ugbId,
                    names?.seria,
                    names?.number
                  )}
                />
              ))}
            </TableBody>
          </Table>

          {!clients.length && (
            <Grid
              container
              alignItems='center'
              justifyContent='center'
              flex={1}
            >
              <Typography variant='h5' textAlign='center'>
                Немає даних.
                <br /> Уточніть деталі пошуку!
              </Typography>
            </Grid>
          )}
        </TableContainer>
      </div>

      {isFilterOpen && <ClientsTableFilter closeFilter={closeFilter} />}
    </div>
  )
);

function Row(props: { row: ReturnType<typeof createClientData> }) {
  const {
    row,
    row: { openedId, setOpenedId, id }
  } = props;

  return (
    <React.Fragment>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
        onClick={props.row.onClick}
      >
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={(event) => {
              event.stopPropagation();
              setOpenedId(openedId === id ? null : id);
              row.onIconClick();
            }}
          >
            {openedId === id ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>

        <TableCell component='th' scope='row'>
          {row.birthDate}
        </TableCell>

        <TableCell>{row.lastName}</TableCell>

        <TableCell>{row.firstName}</TableCell>

        <TableCell>{row.middleName}</TableCell>

        <TableCell>{row.gender}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openedId === id} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                Детальніше
              </Typography>

              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>РНОКПП</TableCell>

                    <TableCell>Фінансовий номер</TableCell>

                    <TableCell align='right'>Код UGB</TableCell>

                    <TableCell align='right'>Серія і номер паспорта</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow key={row.rnokpp}>
                    <TableCell component='th' scope='row'>
                      {row.rnokpp}
                    </TableCell>

                    <TableCell>{row.phone}</TableCell>

                    <TableCell align='right'>{row.ugbId}</TableCell>

                    <TableCell align='right'>
                      {(row.seria ? row.seria : '') + row.number}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export { ClientsList };
