enum ClientContact {
  phone = 1,
  email = 2
}

enum ClientContactStatus {
  verified = 'verified',
  notVerified = 'not verified',
  blocked = 'blocked',
  detached = 'detached'
}

class StatusUpdate {
  public status: string;
  public comment: string;
}

class UpdateClientInfo {
  public additionalPhone: string;
  public additionalPhoneComment: string;
  public lastName: string;
  public firstName: string;
  public middleName: string;
  public mainPhone: string;
  public mainPhoneComment: string;
  public email: string;
  public settlementId: string;
  public registrationChannel: string;
  public clientRank: number;
}

class ContactType {
  public id: number;
  public name: string;
}

class CommonResponseType {
  public id: number;
  public name: string;
}

class ClientContacts {
  public id: number;
  public clientId: number;
  public contactTypeId: number;
  public contactId: string;
  public status: string;
  public contactType: ContactType;
  public blockComment: string;
  public active?: boolean;
}

class Client {
  public clientId: string;
  public phoneStatus?: string;
  public phoneType?: string;
  public systemId?: string;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public externalId?: string;
  public canDisturb: boolean;
  public permProcessData: boolean;
  public permReceivePromoMailings: boolean;
  public clientType: string;
  public clientSegment?: string;
  public mainPhone: string;
  public canEditProfile: string;
}

type Filters = {
  lastName: boolean;
  firstName: boolean;
  middleName: boolean;
  mainPhone: boolean;
  additionalPhone: boolean;
  clientSegmentRFM: boolean;
  email: boolean;
  registrationDate: boolean;
};

class Labels {
  public label: string;
  public filterName: string;
}

class ProfileSection {
  public title: string;
  public isOpened: boolean;
}

class NavElement {
  public label: string;
  public anchor: string;
  public isNavActive: boolean;
  public writable: boolean;
}

enum ClientStatus {
  blocked = 'Заблокований',
  active = 'Активний',
  deleted = 'Видалений'
}

enum ClientType {
  customer = 'CUSTOMER',
  legalEntity = 'LEGAL_ENTITY',
  individual = 'INDIVIDUAL_ENTREPRENEUR'
}

export {
  Client,
  Filters,
  ClientContact,
  ProfileSection,
  CommonResponseType,
  Labels,
  ClientStatus,
  UpdateClientInfo,
  ClientContactStatus,
  StatusUpdate,
  NavElement,
  ClientContacts,
  ClientType
};
