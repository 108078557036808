import { make } from 'redux-chill';
import { ToastOptions } from './state';

/**
 * Show toast
 */
const show = make('[toast] show')
  .stage(
    (
      message: ToastOptions['message'],
      type: ToastOptions['type'] = 'info',
      duration: ToastOptions['duration'] = 5000
    ) => ({
      type,
      message,
      duration
    })
  )
  .stage('start', (options: ToastOptions) => options)
  .stage('end', (id: ToastOptions['id']) => id);

export { show };
