import { KeyboardEvent, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';

import { show } from '@store/toast';
import { navigate } from '@store/router';
import { setCurrentClientGroup } from '@store/general';

import {
  getCustomer,
  getLegalCustomer,
  resetBlocks,
  saveClient,
  setEditing,
  updateActivityCode,
  updateCustomer,
  updateFatca,
  updateLegalCustomer
} from '@clients/store';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

// import { parseFullName } from '@shared/utils/string';

import { useClientId } from './hooks';
import moment from 'moment';

const useEditClientProps = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const clientId = useClientId();

  const {
    client,
    legalEntityClient,
    editing,
    fatca,
    extendedCustomer,
    activeTab,
    activityCode,
    fullClient
  } = useTypedSelector((state) => state.clients);

  //TODO: refact
  const isLegalEntity = window.location.href.includes('legal-entity');

  const clientInfo = isLegalEntity
    ? useFormik({
        initialValues: {
          edrpou: client?.edrpou,
          ugbCode: client?.ugbId,
          eddrCode: client?.eddrCode,
          ownershipTypeId: client?.ownershipType?.id,
          name: client?.name,
          isResident: fullClient?.legalEntity?.isResident,
          expectedIncome: fullClient?.legalEntity?.expectedIncome,
          clientSegmentId: fullClient?.clientSegment?.id,
          registrationChannelId: fullClient?.registrationChannel?.id,
          registrationPlaceCode: fullClient?.registrationPlaceCode,
          registrationDate: moment(
            fullClient?.registrationDate?.slice(0, 10)
          ).format('DD-MM-YYYY'),
          subTypeId: fullClient?.clientSubType?.id,
          status: fullClient?.status?.title,
          employeesExistence: fullClient?.legalEntity?.employeesExistence,
          licenseExistence: fullClient?.legalEntity?.licenseExistence,
          otherBankAccountsExistence:
            fullClient?.legalEntity?.otherBankAccountsExistence,
          otherBankRejection: fullClient?.legalEntity?.otherBankRejection,
          canDisturb: fullClient?.canDisturb,
          permProcessData: fullClient?.permProcessData,
          permReceivePromoMailings: fullClient?.permReceivePromoMailings,
          existsCredOut: fullClient?.existsCredOut,
          codes: fullClient?.registrationGoals || [],
          ownershipType: client?.ownershipType?.title,
          clientEconomicActivities: fullClient?.clientEconomicActivities || [],

          responsibleManager: fullClient?.responsibleManager?.employeeLdapId

          // parseFullName(
          //   fullClient?.responsibleManager?.managerProfile?.firstName,

          //   fullClient?.responsibleManager?.managerProfile?.lastName,

          //   fullClient?.responsibleManager?.managerProfile.middleName
          // )
        },

        onSubmit: (values, { setSubmitting }) => {
          const updateValues = {
            // @ts-expect-error
            companyName: values?.companyName,
            ownershipTypeId: values?.ownershipTypeId,
            // @ts-expect-error
            edrpou: values?.companyName,

            additionalInfo: {
              isResident: values?.isResident,
              // @ts-expect-error
              registrationPlaceCode: values?.companyName,
              eddrCode: values?.eddrCode,
              canDisturb: values?.canDisturb,
              permProcessData: values?.permProcessData,
              permReceivePromoMailings: values?.permReceivePromoMailings,
              employeesExistence: values?.employeesExistence,
              licenseExistence: values?.licenseExistence,
              otherBankAccountsExistence: values?.otherBankAccountsExistence,
              otherBankRejection: values?.otherBankRejection,
              existsCredOut: values?.existsCredOut,
              // @ts-expect-error
              expectedIncome: parseInt(values?.expectedIncome),
              registrationChannelId: values?.registrationChannelId,
              // @ts-expect-error
              segmentId: values?.segmentId,
              subTypeId: values?.subTypeId
            },
            registrationGoals: values?.codes.map((item) => item.id),
            economicActivityCodes: values?.clientEconomicActivities?.map(
              (item) => item.id
            )
          };
          dispatch(updateLegalCustomer({ ...updateValues, id: fullClient.id }));

          setSubmitting(false);
        },
        enableReinitialize: true
      })
    : useFormik({
        initialValues: {
          ...client,
          dateOfBirth: moment(client?.dateOfBirth).format('DD-MM-YYYY'),
          calendarDateOfBirth: client?.dateOfBirth,

          ugbId: client?.ugbId,
          genderId: client?.gender?.id,
          gender: client?.gender?.title,
          eddrCode: fullClient?.eddrCode,
          haveFATCA: client?.haveFATCA,
          segmentId: fullClient?.clientSegment?.id,
          channelId: fullClient?.registrationChannel?.id,
          citizenCountryId: fullClient?.customer?.citizenCountry?.id,
          birthCountryId: fullClient?.customer?.birthCountry?.id,
          birthPlaceId: extendedCustomer?.birthPlace,
          innCode: client?.inn,
          status: client?.status.title,
          birthPlace: fullClient?.customer?.birthPlace,
          clientStatusId: client?.status.title,
          latFirstName: fullClient?.customer?.latFirstName,
          latLastName: fullClient?.customer?.latLastName,
          registrationPlaceCode: fullClient?.registrationPlaceCode,
          canDisturb: fullClient?.canDisturb,
          permProcessData: fullClient?.permProcessData,
          permReceivePromoMailings: fullClient?.permReceivePromoMailings,
          isReligious: fullClient?.customer?.isReligious,
          isPublicFigure: fullClient?.customer?.isPublicFigure,
          isResident: fullClient?.customer?.isResident,
          existsCredOut: fullClient?.existsCredOut,
          registrationDate: moment(
            fullClient?.registrationDate?.slice(0, 10)
          ).format('DD-MM-YYYY'),
          codes: fullClient?.registrationGoals || [],
          ownershipType: client?.ownershipType?.title,
          responsibleManager: fullClient?.responsibleManager?.employeeLdapId

          // parseFullName(
          //   fullClient?.responsibleManager?.managerProfile?.firstName,

          //   fullClient?.responsibleManager?.managerProfile?.lastName,

          //   fullClient?.responsibleManager?.managerProfile.middleName
          // )
        },
        onSubmit: (values, { setSubmitting }) => {
          const updateValues = {
            firstName: values?.firstName,
            lastName: values?.lastName,
            middleName: values?.middleName,
            dateOfBirth: moment(values?.calendarDateOfBirth).format(
              'YYYY-MM-DD'
            ),
            isReligious: values?.isReligious,
            registrationChannelId: values?.channelId,
            segmentId: values?.segmentId,
            inn: values?.innCode,
            existsCredOut: values?.existsCredOut,

            additionalInfo: {
              isResident: values?.isResident,
              latFirstName: values?.latFirstName,
              latLastName: values?.latLastName,
              birthCountryId: values?.birthCountryId,
              birthPlace: values?.birthPlace,
              genderId: values?.genderId,
              citizenCountryId: values?.citizenCountryId,
              isPublicFigure: values?.isPublicFigure,
              registrationPlaceCode: values?.registrationPlaceCode,
              eddrCode: values?.eddrCode,
              canDisturb: values?.canDisturb,
              permProcessData: values?.permProcessData,
              permReceivePromoMailings: values?.permReceivePromoMailings
            },

            registrationGoalsId: values?.codes.map((item) => item.id)
          };
          setSubmitting(false);
          dispatch(updateCustomer({ ...updateValues, id: fullClient.id }));
        },
        enableReinitialize: true
      });

  const fatcaInfo = useFormik({
    initialValues: {
      isCitizenUsa: fatca?.isCitizenUsa,
      isTaxpayerUsa: fatca?.isTaxpayerUsa,
      isBornUsa: fatca?.isBornUsa,
      commentNoCitizenshipUsa: fatca?.commentNoCitizenshipUsa,
      isRegistrationUsa: fatca?.isRegistrationUsa,
      commentNotTaxpayerUsa: fatca?.commentNotTaxpayerUsa
    },
    onSubmit: (values, { setSubmitting }) => {
      dispatch(saveClient(values));
      dispatch(updateFatca({ ...values, id: client?.id }));
      setSubmitting(false);
    },
    enableReinitialize: true
  });

  const activityCodesInfo = useFormik({
    initialValues: {
      employeesExistence: activityCode?.employeesExistence,
      licenseExistence: activityCode?.licenseExistence,
      otherBankAccountsExistence: activityCode?.otherBankAccountsExistence,
      otherBankRejection: activityCode?.otherBankRejection,
      expectedIncome: parseInt(activityCode?.expectedIncome),
      codes: activityCode?.clientEconomicActivities || []
    },
    onSubmit: (values, { setSubmitting }) => {
      const updateValues = {
        employeesExistence: values?.employeesExistence,
        licenseExistence: values?.licenseExistence,
        otherBankAccountsExistence: values?.otherBankAccountsExistence,
        otherBankRejection: values?.otherBankRejection,
        // @ts-expect-error
        expectedIncome: parseInt(values?.expectedIncome),
        economicActivityCodes: values?.codes.map((item) => item.id)
      };
      dispatch(saveClient(values));
      dispatch(updateActivityCode({ ...updateValues, id: activityCode?.id }));
      setSubmitting(false);
    },
    enableReinitialize: true
  });

  const tab = useMemo(() => {
    if (activeTab == 'fatca') {
      return fatcaInfo;
    }

    if (activeTab == 'activityCodes') {
      return activityCodesInfo;
    }

    return clientInfo;
  }, [clientInfo, fatcaInfo, activityCodesInfo]);

  const showMessage = (message: string) => {
    dispatch(show(message, 'success', 3000));
  };

  const commitEdit = (force = true) => {
    //force = false return ?

    if (!(force || clientInfo.dirty)) return;

    dispatch(setEditing(true));
  };

  const onPressEnter = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key !== 'Enter') return;

    commitEdit();
  };

  const createFormHandlers = (form: ReturnType<typeof useFormik>) => [
    () => {
      dispatch(setEditing(false));
      form.submitForm();
    },
    () => {
      dispatch(setEditing(false));
      form.resetForm();
    }
  ];

  const [onSubmitForm, onCancelClick] = createFormHandlers(clientInfo);
  const [onFatcaSubmitForm, onFatcaCancelClick] = createFormHandlers(fatcaInfo);
  const [onActivitySubmitForm, onActivityCancelClick] =
    createFormHandlers(activityCodesInfo);

  const onBackClick = () => {
    dispatch(setCurrentClientGroup(''));
    dispatch(resetBlocks());

    const path = isLegalEntity ? '/clients/legal-entity' : '/clients/fo';

    dispatch(navigate(path));
  };

  useEffect(() => {
    dispatch(setEditing(activityCodesInfo.dirty));
  }, [activityCodesInfo.dirty]);

  useEffect(() => {
    dispatch(setEditing(fatcaInfo?.dirty));
  }, [fatcaInfo?.dirty]);

  useEffect(() => {
    dispatch(setEditing(clientInfo?.dirty));
  }, [clientInfo?.dirty]);

  useEffect(() => {
    const action = isLegalEntity ? getLegalCustomer : getCustomer;

    dispatch(action(clientId));
  }, [pathname]);

  return {
    tab,
    activeTab,
    legalEntityClient,
    client,
    editing,

    fatcaInfo,
    clientInfo,
    fullClient,
    activityCodesInfo,

    showMessage,
    onBackClick,

    onPressEnter,
    commitEdit,
    onCancelClick,
    onSubmitForm,
    onFatcaSubmitForm,
    onFatcaCancelClick,
    onActivityCancelClick,
    onActivitySubmitForm
  };
};

export { useEditClientProps };
