import { useWindowSize } from './use-window-size';

const MOBILE_SIZE = 451;
const TABLET_SIZE = 767;

/**
 * Use media points
 */
const useMediaPoints = (size = 0) => {
  const { width } = useWindowSize();

  return {
    width,
    isSizeDown: width < size,
    isSizeDownOrEqual: width <= size,
    isSizeUp: width > size,
    isSizeUpOrEqual: width >= size,
    isMobileDown: width < MOBILE_SIZE,
    isMobileDownOrEqual: width <= MOBILE_SIZE,
    isMobileUp: width > MOBILE_SIZE,
    isMobileUpOrEqual: width >= MOBILE_SIZE,
    isTabletDown: width < TABLET_SIZE,
    isTabletDownOrEqual: width <= TABLET_SIZE,
    isTabletUp: width > TABLET_SIZE,
    isTabletUpOrEqual: width >= TABLET_SIZE
  };
};

export { useMediaPoints, MOBILE_SIZE, TABLET_SIZE };
