import React from 'react';
import { ReplaceWithFieldProps } from '@core/form';
import { ErrorContainer } from '../error-container';
import { NewSelect } from './new-select.component';
import { NewSelectProps } from './new-select.props';

const NewSelectForm: React.VFC<ReplaceWithFieldProps<NewSelectProps>> = ({
  touched,
  error,
  isError,
  ...props
}) => (
  <ErrorContainer error={touched && error}>
    <NewSelect isError={touched && isError} {...props} />
  </ErrorContainer>
);

export { NewSelectForm };
