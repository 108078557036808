import { useState } from 'react';
import { logout } from '@store/general';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store';

const useLogoutPopupProps = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state: State) => state.general);

  const [visible, setVisible] = useState(false);

  const onLogoutClick = () => {
    dispatch(logout());
  };

  const onShowPopupClick = () => {
    setVisible(!visible);
  };

  return {
    userDisplayRoleShort: user?.accessGroup?.shortSystemGroupName,
    userDisplayRole: user?.accessGroup?.groupName,
    visible,
    onLogoutClick,
    onShowPopupClick
  };
};

export { useLogoutPopupProps };
