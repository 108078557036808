import React from 'react';
import { hoc } from '@core';
import { LegalClientsTableFilter } from './legal-clients-filter';
import { TableHeader } from '@app';
import { useLegalEntityClientsListProps } from './legal-entity-list.props';
import styles from './clients-list.module.scss';
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { createLegalEntityData } from '@clients/pages/clients-list/legal-entity-list.lib';

//TODO: create widget
const LegalEntityClientsList = hoc(
  useLegalEntityClientsListProps,
  ({
    names,
    clients,
    onOpenClick,
    isFilterOpen,
    closeFilter,
    openedId,
    openFilter,
    onIconClick,
    onRowOpen
  }) => (
    <div className={styles.flex1}>
      <TableHeader tableHeader='Клієнти ЮО' onOpenFilterClick={openFilter} />

      <div className={styles.flex1}>
        <TableContainer className={styles.flex1} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 66 }} />

                <TableCell>Найменування </TableCell>

                <TableCell>ЄДРПОУ</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {clients?.map((client) => (
                <Row
                  key={client.id}
                  row={createLegalEntityData(
                    openedId,
                    onRowOpen,
                    () => onIconClick(client.id),
                    () => onOpenClick(client.id),
                    client?.edrpou,
                    client?.name,
                    client?.id,
                    names?.eddrCode,
                    names?.ownershipType,
                    names?.ugbId
                  )}
                />
              ))}
            </TableBody>
          </Table>

          {!clients.length && (
            <Grid
              container
              alignItems='center'
              justifyContent='center'
              flex={1}
            >
              <Typography variant='h5' textAlign='center'>
                Немає даних.
                <br /> Уточніть деталі пошуку!
              </Typography>
            </Grid>
          )}
        </TableContainer>
      </div>

      {isFilterOpen && <LegalClientsTableFilter closeFilter={closeFilter} />}
    </div>
  )
);

//TODO: create widget
function Row(props: { row: ReturnType<typeof createLegalEntityData> }) {
  const {
    row,
    row: { openedId, setOpenedId, id }
  } = props;

  return (
    <React.Fragment>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
        onClick={props.row.onClick}
      >
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={(event) => {
              event.stopPropagation();
              setOpenedId(openedId === id ? null : id);
              row.onIconClick();
            }}
          >
            {openedId === id ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {row.companyName}
        </TableCell>
        <TableCell>{row.edrpou}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openedId === id} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                Детальніше
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>Форма власності</TableCell>
                    <TableCell>Код UGB</TableCell>
                    <TableCell>ЄДДР код</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.ugbId}>
                    <TableCell component='th' scope='row'>
                      {row.ownershipType}
                    </TableCell>
                    <TableCell>{row.eddrCode}</TableCell>
                    <TableCell>{row.ugbId}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export { LegalEntityClientsList };
