import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { hoc } from '@core/utils';
import { useToastProps } from './toast.props';
import styles from './toast.module.scss';
import { Check, Close, Error, Info, Warning } from '@mui/icons-material';

/**
 * <Toast />
 */
const Toast = hoc(useToastProps, ({ toasts, onCloseClick }) => (
  <div className={styles.container}>
    <TransitionGroup className={styles.list}>
      {toasts.map(({ id, message, type }) => {
        const Icon = {
          success: Check,
          warning: Warning,
          error: Error,
          info: Info
        };

        const IconComponent = Icon[type];

        return (
          <CSSTransition
            key={id}
            timeout={300}
            classNames={{
              enter: styles.enter,
              exit: styles.exit,
              exitActive: styles.exitActive,
              enterActive: styles.enterActive
            }}
          >
            <div
              className={classNames(styles.toast, {
                [styles.toastSuccess]: type === 'success',
                [styles.toastWarning]: type === 'warning',
                [styles.toastError]: type === 'error',
                [styles.toastInfo]: type === 'info'
              })}
            >
              <div>
                <IconComponent
                  className={styles.icon}
                  onClick={() => onCloseClick(id)}
                />
              </div>

              <div className={styles.main}>
                <div className={styles.message}>{message}</div>
              </div>

              <div>
                <Close
                  className={styles.closer}
                  onClick={() => onCloseClick(id)}
                />
              </div>
            </div>
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  </div>
));

Toast.defaultProps = {
  type: 'info'
};

export { Toast };
