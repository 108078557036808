import React, { FC, ReactNode } from 'react';
import { $$role, Role, RoleAccess } from '@entities/role';
import { Grid, Typography } from '@mui/material';

type SectionLayout = {
  read?: Role;

  update?: Role;

  children: (access: { canUpdate: boolean }) => ReactNode;

  disableFlex?: boolean;
};

const SectionLayout: FC<SectionLayout> = ({
  read,
  update,
  disableFlex,
  children
}) => {
  const canUpdate = !!$$role.useHasRole(update);

  if (!read) {
    return (
      <Grid container flexWrap='wrap'>
        {children}
      </Grid>
    );
  }

  return (
    <RoleAccess
      role={read}
      fallback={
        <Grid container alignItems='center' justifyContent='center' flex={1}>
          <Typography variant='h4'>Недостатньо доступу</Typography>
        </Grid>
      }
    >
      <Grid container flexWrap='wrap' flex={disableFlex ? 0 : 1}>
        {children({ canUpdate })}
      </Grid>
    </RoleAccess>
  );
};

export { SectionLayout };
