function createClientData(
  openedId: string,
  setOpenedId: (id: string) => void,
  onIconClick: () => void,
  onClick: () => void,
  birthDate: string,
  lastName: string,
  firstName: string,
  middleName: string,
  gender: string,
  rnokpp: string,
  id: string,
  phone: string,
  ugbId: string,
  seria: string,
  number: string
) {
  return {
    openedId,
    setOpenedId,
    onIconClick,
    onClick,
    birthDate,
    lastName,
    firstName,
    middleName,
    gender,
    rnokpp,
    id,
    phone,
    ugbId,
    seria,
    number
  };
}

export { createClientData };
