import React from 'react';
import classNames from 'classnames';
import { hoc } from '@core/utils';
import { Searchbar } from '@app/components';

import { Navbar } from '../navbar';
import { LogoutPopup } from './logout-popup';
import { RecentlyAddedClientAlert } from './recently-added-client-alert';

import { useMainLayout } from './main-layout.props';
import styles from './main-layout.module.scss';

const MainLayout = hoc(
  useMainLayout,
  ({
    menu,
    children,
    isMobile,
    recentlyAddedClientId,
    onEditClick,
    onCancelClick,
    isMobileMenuOpened,
    withRecentlyAddedClientAlert,
    left,
    className,
    mainPartClassName
  }) => (
    <div
      className={classNames(styles.mainPage, {
        [styles.mainPageWithMenu]: menu
      })}
    >
      <Navbar />

      {!isMobileMenuOpened && (
        <div className={classNames(styles.mainPart, mainPartClassName)}>
          <Header>{left || (!isMobile && <Searchbar />)}</Header>

          <div className={classNames(styles.content, className)}>
            {children}
          </div>
        </div>
      )}

      {withRecentlyAddedClientAlert && recentlyAddedClientId && (
        <RecentlyAddedClientAlert
          onEditClick={onEditClick}
          onCancelClick={onCancelClick}
        />
      )}
    </div>
  )
);

const Header = ({ children }) => (
  <header className={styles.mainHeader}>
    {children}

    <LogoutPopup />
  </header>
);

export { MainLayout };
