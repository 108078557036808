import { User } from '@api';

class GeneralState {
  /**
   * Logged user
   */
  public user: User;

  /**
   * App ready
   */
  public ready: boolean = false;

  /**
   * Is tried to refresh token
   */
  public isTriedToRefresh: boolean = false;

  /**
   * Is authorized
   */
  public authorized: boolean = false;

  /**
   * Menu opened
   */
  public menu: boolean = false;

  /**
   * New client group
   */
  public newClientGroup: string = '';

  /**
   * Notification count
   */
  public notificationCount: number;

  /**
   * Loading
   */
  public loading: number = 0;
}

export { GeneralState };
