import React from 'react';
import { components } from 'react-select';
import classNames from 'classnames';
import { SelectContainerProps } from './select-container.props';
import styles from './select-container.module.scss';

const SelectContainer: React.FC<SelectContainerProps> = ({
  children,
  ...rest
}) => (
  <components.SelectContainer
    {...rest}
    className={classNames(rest.className, styles.container, {
      [styles.open]: rest.selectProps.menuIsOpen
    })}
  >
    {children}
  </components.SelectContainer>
);

export { SelectContainer };
