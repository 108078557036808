enum Role {
  PhysicalsAccess = 'physicals-access',
  LegalEntityAccess = 'legal-entity-access',
  AdminDirectoriesAccess = 'admin-directories-access',
  PhysicalsRead = 'physicals-read-profile',
  PhysicalsFullRead = 'physicals-read-profile-full',
  PhysicalsUpdate = 'physicals-update-profile',
  ContactsRead = 'contacts-read',
  ContactsUpdate = 'contacts-update',
  DocumentRead = 'document-read',
  DocumentUpdate = 'document-update',
  AddressesRead = 'addresses-read',
  AddressesUpdate = 'addresses-update',
  FatcaRead = 'fatca-read',
  FatcaUpdate = 'fatca-update',
  LegalEntityRead = 'legal-entity-read',
  LegalEntityFullRead = 'legal-entity-read-full',
  LegalEntityUpdate = 'legal-entity-update',
  IncomesRead = 'incomes-read',
  IncomesUpdate = 'incomes-update',
  OwnershipRead = 'ownership-read',
  OwnershipUpdate = 'ownership-update',
  RestrictionsRead = 'restrictions-read',
  RelationshipsRead = 'relationships-read',
  RelationshipsUpdate = 'relationships-update',
  IdentificationHistoryRead = 'indentification-history-read',
  IdentificationHistoryUpdate = 'indentification-history-update',
  IndividualEntrepreneursRead = 'individual-entrepreneurs-read',
  IndividualEntrepreneursUpdate = 'individual-entrepreneurs-update',
  AdditionalAttributesRead = 'additional-attributes-read',
  AdditionalAttributesUpdate = 'additional-attributes-update'
}

export { Role };
