import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  getCurrency,
  getOwnershipInfo,
  getVehicle,
  getVehiclesBrands,
  getVehiclesGroups,
  getVehiclesTypes,
  setEditing,
  updateVehicle
} from '@clients/store';
import { useFormik } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import { useClientId } from '@clients/pages/edit-client/hooks';
import { useLocation } from 'react-router';
import { toSelectOption } from '../../../lib';
import { useTypedSelector } from '@shared/lib/use-typed-selector';
import moment from 'moment';

const useVehiclesProps = (_: { canUpdate: boolean }) => {
  const dispatch = useDispatch();
  const clientId = useClientId();
  const { pathname } = useLocation();

  const {
    vehiclesBrands,
    currency,
    vehiclesGroups,
    vehiclesTypes,
    selectedVehicle,
    ownership
  } = useTypedSelector((state) => state.clients);

  const propertyInfo = useFormik({
    initialValues: {
      typeId: selectedVehicle?.type?.id,
      cost: selectedVehicle?.cost,
      currencyId: selectedVehicle?.currency?.id,
      model: selectedVehicle?.model,
      brandId: selectedVehicle?.brand?.id,
      groupId: selectedVehicle?.group?.id,
      yearProduction: selectedVehicle?.yearProduction,
      purchaseDate: selectedVehicle?.purchaseDate
        ? moment(selectedVehicle?.purchaseDate?.slice(0, 10)).format(
            'DD-MM-YYYY'
          )
        : null,
      comment: selectedVehicle?.comment
    },
    onSubmit: (values, { setSubmitting }) => {
      const updateValues = {
        typeId: values?.typeId,
        cost: parseInt(values?.cost),
        currencyId: values?.currencyId,
        model: values?.model,
        brandId: values?.brandId,
        groupId: values?.groupId,
        // @ts-expect-error
        yearProduction: parseInt(values?.yearProduction),
        purchaseDate: moment(values?.purchaseDate).format('YYYY-MM-DD'),
        comment: values?.comment
      };
      dispatch(updateVehicle({ ...updateValues, id: selectedVehicle.id }));

      setSubmitting(false);
      dispatch(setEditing(false));
      setTimeout(() => {
        dispatch(getOwnershipInfo(clientId));
      }, 1000);
    },
    enableReinitialize: true
  });

  const onOpen = ({ id }: { id: string }) => dispatch(getVehicle(id));

  useEffect(() => {
    dispatch(getOwnershipInfo(clientId));
    dispatch(getVehiclesTypes(''));
    dispatch(getCurrency(''));
    dispatch(getVehiclesGroups(''));
    dispatch(getVehiclesBrands(''));
  }, [pathname]);

  useEffect(() => {
    dispatch(setEditing(propertyInfo.dirty));
  }, [propertyInfo.dirty]);

  return {
    brands: vehiclesBrands?.map(toSelectOption),
    types: vehiclesTypes?.map(toSelectOption),
    groups: vehiclesGroups?.map(toSelectOption),
    currencyNames: currency?.map(toSelectOption),
    identification: (ownership?.vehicles ?? []).map((value) => ({
      id: value?.id,
      groupId: value?.group?.title,
      cost: value?.cost,
      currencyId: value?.currency?.title,
      purchaseDate: value?.purchaseDate
        ? moment(value?.purchaseDate).format('DD-MM-YYYY')
        : '',
      comment: value?.comment
    })),
    propertyInfo,
    onOpen
  };
};
const columns: GridColDef[] = [
  {
    field: 'groupId',
    headerName: 'Група',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'cost',
    headerName: 'Ціна',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'currencyId',
    headerName: 'Валюта',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'purchaseDate',
    headerName: 'Дата Покупки',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'comment',
    headerName: 'Коментар',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  }
];

export { useVehiclesProps, columns };
