import { useDispatch } from 'react-redux';
import {
  getAttribute,
  getAttributeGroupInfo,
  getAttributes,
  getAttributesGroups,
  getRandDirectory,
  saveClient,
  setEditing,
  setGroupName,
  updateAttribute
} from '@clients/store';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import { useClientId } from '@clients/pages/edit-client/hooks';
import { isEqual } from 'lodash';
import { useTypedSelector } from '@shared/lib/use-typed-selector';

const useAttributesProps = () => {
  const dispatch = useDispatch();
  const clientId = useClientId();

  const { groupName, random, attribute, attributes, attributeGroups } =
    useTypedSelector((state) => state.clients);

  const isDirectory = attribute?.attribute?.isDirectory;

  const attributesInfo = useFormik({
    initialValues: {
      attributesGroupId: groupName?.attributesGroupName,
      attributeName: attribute?.attribute?.title,
      attributeDescription: attribute?.attribute?.comment,
      attributeValue: attribute?.value?.data
    },
    onSubmit: (values, { setSubmitting }) => {
      const item = { data: values?.attributeValue };
      const updateValues = { value: item };
      dispatch(saveClient(values));
      dispatch(updateAttribute({ ...updateValues, id: attribute.id }));
      const groupId = groupName?.attributesGroupId;
      setSubmitting(false);
      setTimeout(() => {
        dispatch(getAttributes({ groupId, clientId }));
      }, 1000);
    },
    enableReinitialize: true
  });

  const onOpen = (event: { id: string; row: unknown }) => {
    dispatch(setGroupName(event?.row));
    const groupId = event?.id;

    dispatch(getAttributes({ groupId, clientId }));
  };
  const onAttributeOpen = (event) => {
    dispatch(getAttribute(event.id));
  };

  const randomValues = random?.map((cont) => ({
    id: cont?.id,
    name: cont?.title
  }));

  useEffect(() => {
    dispatch(getAttributeGroupInfo());
    dispatch(getAttributesGroups(''));

    if (!isDirectory) return;

    dispatch(getRandDirectory(attribute?.attribute?.directoryId));
  }, []);

  useEffect(() => {
    dispatch(
      setEditing(!isEqual(attributesInfo.values, attributesInfo.initialValues))
    );
  }, [attributesInfo.values, attributesInfo.initialValues]);

  useEffect(() => {
    if (!isDirectory) return;

    dispatch(getRandDirectory(attribute?.attribute?.directoryId));
  }, []);

  return {
    isDirectory,
    randomValues,
    identification: (attributeGroups ?? []).map((value) => ({
      id: value?.id,
      attributesGroupName: value?.title,
      attributesGroupId: value?.id,
      attributeGroupDescription: value?.description
    })),
    attributesInfo,
    attributesNames: (attributes ?? []).map((value) => ({
      id: value?.id,
      attributeName: value?.attribute?.title,
      attributeValueLabel: value?.attribute?.isDirectory
        ? value?.directoryValueList?.[0]?.label
        : value?.value?.data
    })),
    onOpen,
    onAttributeOpen
  };
};

const groupColumns: GridColDef[] = [
  {
    field: 'attributesGroupName',
    headerName: 'Назва групи атрибута',
    width: 180,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'attributesGroupId',
    headerName: 'ID групи',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'attributeGroupDescription',
    headerName: 'Опис групи',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  }
];
const columns: GridColDef[] = [
  {
    field: 'attributeName',
    headerName: 'Назва атрибута',
    width: 180,
    sortable: false,
    disableColumnMenu: true
  },

  {
    field: 'attributeValueLabel',
    headerName: 'Значення атрибута',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  }
];

export { useAttributesProps, columns, groupColumns };
