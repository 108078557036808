enum StorageKey {
  token = 'accessToken',
  refreshToken = 'refreshToken'
}

class Storage {
  public set<T>(name: StorageKey, value: T) {
    return sessionStorage.setItem(name, value.toString());
  }

  public get(name: StorageKey) {
    return sessionStorage.getItem(name);
  }
}

class PersistStorage {
  public setItem = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  public getItem = (key: string) => {
    const item = localStorage.getItem(key);

    if (!item) {
      return null;
    }

    return JSON.parse(item);
  };
}

export { Storage, StorageKey, PersistStorage };
