import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { FilterHeaderBadge } from '@core';
import {
  clientsFilter,
  getResponsibleManagers,
  legalClientsFilter
} from '@clients/store';
import { createBadge } from './clients-table-filter.lib';
import { useEffect } from 'react';
import { useTypedSelector } from '@shared/lib/use-typed-selector';
import { object, string } from 'yup';

type ClientsTableFilterProps = {
  closeFilter: () => void;
};

const useClientsTableFilter = ({ closeFilter }: ClientsTableFilterProps) => {
  const dispatch = useDispatch();

  const { responsibleManagers } = useTypedSelector((state) => state.clients);
  // responsibleManagers = [
  //   { label: 'test 1', value: '1' },
  //   { label: 'rofl 2', value: '2' },
  //   { label: 'asd 3', value: '3' },
  //   { label: 'far 4', value: '4' }
  // ];

  const form = useFormik<{
    responsibleManagerId?: { label: string; value: string } | null;
  }>({
    initialValues: {},

    validationSchema: object({
      innCode: string().min(5, 'Мін. 5 символів'),
      passportSeries: string().min(2, 'Мін. 2 символів'),
      passportNumber: string().min(5, 'Мін. 5 символів'),
      ugbCode: string().min(5, 'Мін. 5 символів'),
      firstName: string().min(2, 'Мін. 2 символів'),
      lastName: string().min(2, 'Мін. 2 символів'),
      middleName: string().min(2, 'Мін. 2 символів'),
      responsibleManagerId: object().nullable(),
      // .test(
      //   'notSingle',
      //   'Фільтр працює лише з будь-яким додатковим',
      //   function test(value) {
      //     if (!value) return true;

      //     const others = Object.entries(this.parent).filter(
      //       ([key]) => key !== 'responsibleManagerId'
      //     );

      //     const isSomeOtherHasValues =
      //       others.length > 0 && others.some(([, newValue]) => !!newValue);

      //     return isSomeOtherHasValues;
      //   }
      // ),
      __: object()
        .nullable()
        .test('_', '_', function () {
          const values = Object.values(this.parent);

          return !!values.length && values.some(Boolean);
        })
    }),

    validateOnMount: true,
    onSubmit: (newFilter) => {
      if (newFilter.responsibleManagerId) {
        //@ts-expect-error
        newFilter.responsibleManagerId = newFilter.responsibleManagerId.value;
      }

      window.location.href.includes('legal-entity')
        ? dispatch(legalClientsFilter(newFilter))
        : dispatch(clientsFilter(newFilter));
      closeFilter();
    }
  });

  const onBadgeClose = (name: string) => {
    form.setFieldValue(name, name === 'responsibleManagerId' ? null : '');
  };

  const onClearFilters = () => {
    form.setValues({ responsibleManagerId: null });
  };

  const badges = Object.entries(form.values)
    .filter(([_, value]) => !!value)
    .reduce(
      (entries, [key, value]) => [...entries, createBadge(key, value)],
      [] as FilterHeaderBadge[]
    );

  useEffect(() => {
    dispatch(getResponsibleManagers());
  }, []);

  return {
    form,
    responsibleManagers,
    badges,
    onBadgeClose,
    onResetClick: closeFilter,
    onClearFilters
  };
};

export { useClientsTableFilter, ClientsTableFilterProps };
