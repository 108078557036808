import { make } from 'redux-chill';
import { SearchResult } from '@app';

/**
 * Set search query
 */
const setSearchQuery = make('[search] set query').stage((query: string) => ({
  query
}));

/**
 * Ser search results
 */
const setSearchResults = make('[search] set results').stage(
  (results: SearchResult[]) => ({ results })
);

/**
 * Set search result status
 */
const setSearchResultStatus = make('[search] set result status').stage(
  (status: boolean) => ({ status })
);

/**
 * Clear results
 */
const clearSearchResults = make('[search] clear results');

export {
  setSearchQuery,
  setSearchResults,
  setSearchResultStatus,
  clearSearchResults
};
