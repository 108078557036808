import { ClientContactStatus } from '@api';
import { ReactNode, useMemo, useRef, useState } from 'react';
import { get } from 'object-path';
import { useClickOutside } from '@core/hooks';
import { ControlProps } from '@core/form';
import { useDispatch } from 'react-redux';
import {
  showClientStatusBlocks,
  showPhoneStatusBlocks
} from '@clients/store/actions';

export enum SelectThemes {
  default = 'default',
  compact = 'compact',
  moreCompact = 'moreCompact',
  promotion = 'promotion'
}

/**
 * Field selector
 */
type Selector = string | ((option: unknown) => any);

/**
 * Props
 */
type SelectProps = ControlProps<any> & {
  defaultValue?: any;
  /**
   * Options list
   */
  options: any[];

  /**
   * Control theme
   */
  theme?: SelectThemes;

  /**
   * Options shape
   */
  shape?: {
    id?: Selector;
    name?: Selector;
  };

  /**
   * Placeholder
   */
  placeholder?: string;

  /**
   * After select block
   */
  after?: ReactNode;

  searchable?: boolean;

  name?: string;

  hooks?: {
    change?: (value: any) => any;
  };

  notFoundText?: string;

  loading?: boolean;
  loadingText?: string;

  /**
   * custom classnames to all elements
   */
  classnames?: {
    error?: string;
  };
};

/**
 * Default selectors
 */
const defaultSelectors = {
  id: 'id',
  name: 'name'
};

/**
 * Use select
 */
const useSelectProps = ({
  defaultValue,
  shape,
  value,
  options,
  disabled,
  onChange,
  placeholder,
  onEdit,
  hooks,
  name
}: SelectProps) => {
  const dispatch = useDispatch();

  const reference = useRef<HTMLDivElement>(null);
  const inputRef = useRef();

  const [query, setQuery] = useState<string>('');
  const [focused, setFocused] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);

  const selectors = useMemo(
    () => ({
      ...defaultSelectors,
      ...shape
    }),
    [shape]
  );

  const hasValue = Boolean(value) || value == 0;

  const select = (selector, option) =>
    typeof selector == 'string' ? get(option, selector) : selector(option);

  const selected = options?.find((one) => select(selectors.id, one) == value);

  const caption = selected ? select(selectors.name, selected) : '';

  const filtered = useMemo(() => {
    if (!options) return [];
    if (!query) return options;

    return options.filter((item) => {
      const name: string = select(selectors.name, item)
        .toString()
        .toLowerCase();

      return name?.includes(query?.toLowerCase());
    });
  }, [options, query]);

  const onClick = () => {
    if (disabled) return;

    setFocused(!focused);
  };

  const onOptionClick = (option: SelectProps['options'][number]) => {
    switch (name) {
      case 'phoneStatus': {
        dispatch(
          showPhoneStatusBlocks(option.id == ClientContactStatus.blocked)
        );
        break;
      }
      case 'status': {
        dispatch(
          showClientStatusBlocks(option.id == ClientContactStatus.blocked)
        );
        break;
      }
    }

    const value = select(selectors.id, option);

    onChange(value);

    onEdit?.();

    setFocused(false);

    if (hooks?.change) {
      hooks?.change(value);
    }
  };

  const onSearchBlur = () => {
    setInputFocused(false);
    setQuery('');
  };

  useClickOutside(
    () => {
      if (!focused) return;

      setFocused(false);
    },
    reference.current,
    [focused]
  );

  return {
    defaultValue,
    query,
    select,
    filtered,
    onSearchBlur,
    inputFocused,
    setInputFocused,
    caption,
    inputRef,
    focused,
    onClick,
    selected,
    hasValue,
    setQuery,
    // filtered,
    reference,
    selectors,
    placeholder,
    onOptionClick
  };
};

export { SelectProps, useSelectProps };
