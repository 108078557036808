import type { Filters, TableFilter } from '@api';

import { PersistStorage } from '@store/storage';
import {
  hiddenColumnsInitialValues,
  hiddenColumnStorageKeys,
  hiddenColumnTypes
} from './hidden-columns.initial-values';
import {
  HiddenColumnInitialValues,
  HiddenColumnType
} from './hidden-columns.types';

class HiddenColumnFeature {
  constructor() {
    hiddenColumnTypes.forEach((type) => {
      if (this.getHiddenColumns(type)) {
        return;
      }

      this.saveHiddenColumns(type, hiddenColumnsInitialValues[type]);
    });
  }

  private storage = new PersistStorage();

  public getHiddenColumns = <T extends HiddenColumnType>(
    type: T
  ): HiddenColumnInitialValues[T] => {
    const columns = this.storage.getItem(hiddenColumnStorageKeys[type]);

    if (!columns) {
      const columns = hiddenColumnsInitialValues[type];

      this.storage.setItem(hiddenColumnStorageKeys[type], columns);

      return columns;
    }

    return columns;
  };

  public saveHiddenColumns = <T extends HiddenColumnType>(
    type: T,
    hiddenColumns: HiddenColumnInitialValues[T]
  ) => {
    this.storage.setItem(hiddenColumnStorageKeys[type], hiddenColumns);
  };
}

export { Filters, HiddenColumnFeature, PersistStorage, TableFilter };
