import { createOption } from './components/new-select/new-select.utils';
import { Formatter } from './utils/formatter';

const timeOptions = new Array<string>(48).fill('00:00').map((time, index) => {
  const start = Formatter.toHour(Math.floor(index / 2));
  const option = start + (index % 2 ? ':30' : time.substring(2));

  return createOption(option, option);
});

export { timeOptions };
