//TODO: add types
class LastClientsShown {
  constructor() {}

  public saveClientsID = (clientId) => {
    const clientsArray = JSON.parse(localStorage.getItem('id')) || [];

    if (clientsArray.length == 10) {
      clientsArray.pop();
    }

    if (!clientsArray.includes(clientId[0])) {
      clientsArray.unshift(clientId[0]);
    }

    localStorage.setItem('id', JSON.stringify(clientsArray));

    return clientId;
  };
  public getClientsID = () => {
    const clientId = JSON.parse(localStorage.getItem('id'));

    return (clientId ?? []) as string[];
  };
}

export { LastClientsShown };
