import {
  Directories,
  DirectoryVariant,
  ProductOwnerDto,
  SearchDirectoryContract,
  SearchDirectoryItemsContract
} from '@shared/api';

class DirectoryState {
  public isConstant: boolean = null;
  public directoryVariant: DirectoryVariant | null = null;
  public searchedDirectories: Directories[] = [];
  public searchedProductDirectories: Directories[] = [];
  public searchedBusinessDirectories: Directories[] = [];
  public directory = null;
  public updatedDirectory: Directories = null;
  public directories: SearchDirectoryContract[] = [];
  public isSearching = false;
  public query: string;
  public type: string;

  public selectedDirectory: SearchDirectoryContract | null = null;
  public selected: { label: string; id: string };
  public directoryItems: SearchDirectoryItemsContract[] = [];
  public isSearchingDirectoryItems = false;
  public createdDirectory = null;
  public createdProduct = null;
  public createdOwner = null;

  public productOwners: ProductOwnerDto[] = [];
  public isProductOwnersLoading = false;
}

export { DirectoryState };
