import { useFormikContext } from 'formik';
import React, { ComponentType, ReactNode, useCallback } from 'react';
import { get } from 'object-path';
import { FormikStatus } from './status';
import { ControlProps } from '@core/form/control';
import { FieldPreviewProps } from '@core/components/field-preview';
import { DateUtils } from '@core/utils';

/**
 * Field props
 */
type Props = {
  value?: any;
  error?: any;
  isError?: any;
  onTouch?: any;
  onChange?: any;
  touched?: any;
  disabled?: any;
} & PreviewProps;

type PreviewProps = {
  preview?: boolean;
  previewType?: 'select' | 'default' | 'date';
  label?: ReactNode;
  previewProps?: FieldPreviewProps;
};

type ReplaceWithFieldProps<T> = Omit<T, keyof ControlProps> & ControlProps;

/**
 * Use field props
 */
const useFieldProps = (name: string, props: any) => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    ...formik
  } = useFormikContext<any>();

  const status: FormikStatus = formik.status;
  const statusError = get(status, 'errors' + '.' + name);
  const error = get(errors, name) || statusError;
  const isError = error != undefined && error != null;
  const value = get(values, name);
  const { hooks } = props as any;

  const onChange = useCallback(
    (value) => {
      setFieldValue(name, value);

      if (!hooks?.change) return;

      hooks?.change(value);
    },
    [setFieldValue, name, value, hooks?.change]
  );

  const onTouch = useCallback(
    (touched = true) => {
      setFieldTouched(name, touched);
    },
    [name, setFieldTouched]
  );

  return {
    value,
    error,
    status,
    isError,
    onTouch,
    touched,
    onChange,
    isSubmitting
  };
};

/**
 * Wrap component with field data provided
 */
function withField<P extends Props>(source: ComponentType<P>) {
  const Result: any = source;

  const result: React.FC<
    PreviewProps & {
      name: string;
    }
  > = ({ preview, previewType = 'default', ...props }) => {
    const {
      value,
      error,
      status,
      isError,
      onTouch,
      touched,
      onChange,
      isSubmitting
    } = useFieldProps(props.name, props);

    if (preview) {
      const addSuffix = (value) =>
        previewType === 'select' ? value?.label : value;

      const format = (value) =>
        previewType === 'date' ? DateUtils.formatDotsYear(value) : value;

      const formatted = [value].map(addSuffix).map(format);

      return (
        <Result
          value={
            formatted.filter(Boolean).length ? formatted : 'Відсутня інформація'
          }
          error={error}
          isError={isError}
          onTouch={onTouch}
          onBlur={onTouch}
          onChange={onChange}
          touched={get(touched, props.name)}
          disabled={isSubmitting || get(status, 'disabled')}
          {...props}
        />
      );
    }

    return (
      <Result
        value={value}
        error={error}
        isError={isError}
        onTouch={onTouch}
        onBlur={onTouch}
        onChange={onChange}
        touched={get(touched, props.name)}
        disabled={isSubmitting || get(status, 'disabled')}
        {...props}
      />
    );
  };

  return result as any as ComponentType<
    {
      name: string;
      hooks?: {
        change?: (value: any) => any;
      };
    } & Omit<P, keyof Props> &
      Partial<Pick<P, keyof Props>>
  >;
}

export { withField, useFieldProps, ReplaceWithFieldProps };
