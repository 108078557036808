import React from 'react';
import { useNewMultipleSelectProps } from './new-multiple-select.props';
import { hoc } from '@shared/lib/hoc';
import { Autocomplete, Chip, FormControl, TextField } from '@mui/material';

const NewMultipleSelect = hoc(
  useNewMultipleSelectProps,
  ({ value, handleChange, names, inputLabel, ...props }) => (
    <>
      {/** FIXME */}
      <FormControl sx={{ m: 1, width: 500 }}>
        <Autocomplete
          {...props}
          multiple
          disableCloseOnSelect
          onChange={handleChange}
          renderOption={(option: any) => (
            <div
              style={{
                backgroundColor:
                  value?.find((item) => item?.title === option?.key) &&
                  '#e2dfdf'
              }}
              {...option}
            >
              {option?.key}
            </div>
          )}
          value={value}
          getOptionLabel={(option: {
            title: string;
            name?: string;
            id: string;
          }) => option?.title ?? option?.name}
          renderTags={(value) =>
            value.map(({ title, name }) => (
              <Chip
                key={title}
                style={{ fontSize: '12px', overflow: 'hidden' }}
                variant='outlined'
                label={title || name}
              />
            ))
          }
          renderInput={(params) => <TextField {...params} label={inputLabel} />}
          options={names}
        />
      </FormControl>
    </>
  )
);

export { NewMultipleSelect };
