import { string } from 'yup';
import { DateUtils } from '@core/utils';

const datePickerSchema = string()
  .matches(
    /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/,
    'Введіть корректну дату'
  )
  .typeError("Поле обов'язкове")
  .test('overflow', 'Неправильний формат дати', (value: string) => {
    if (!value) return true;

    return DateUtils.isValid(value);
  })
  .nullable();

export { datePickerSchema };
