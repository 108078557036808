import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  getActivityCodesDictionary,
  getChannels,
  getClientGoals,
  getExtendedInfo,
  getFullProfileCustomer,
  getGoalsInfo,
  getOwnershipTypes,
  getSegments,
  getSubTypes
} from '@clients/store';
import { useClientId } from '@clients/pages/edit-client/hooks';
import { useTypedSelector } from '@shared/lib/use-typed-selector';
import { toSelectOption } from '../../../lib';

const useLegalExtendedInformationProps = () => {
  const dispatch = useDispatch();
  const clientId = useClientId();
  const {
    clientGoals,
    subTypes,
    ownershipTypes,
    segments,
    channels,
    activCodes
  } = useTypedSelector((state) => state.clients);

  useEffect(() => {
    dispatch(getExtendedInfo());
    dispatch(getGoalsInfo());
    dispatch(getFullProfileCustomer(clientId));
    dispatch(getSegments(''));
    dispatch(getChannels(''));
    dispatch(getOwnershipTypes());
    dispatch(getSubTypes());
    dispatch(getClientGoals(''));
    dispatch(getActivityCodesDictionary(''));
  }, []);

  return {
    names: clientGoals,
    clientSubTypes: subTypes.map(toSelectOption),
    types: ownershipTypes.map(toSelectOption),
    customerSegments: segments.map(toSelectOption),
    customerChannels: channels.map(toSelectOption),
    activityCodes: activCodes.map(toSelectOption)
  };
};

export { useLegalExtendedInformationProps };
