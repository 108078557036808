import { router } from './router';
import { general } from './general';
import { search } from './search';
import { toast } from './toast';
import { clients } from '@clients/store';

import { $$role } from '@entities/role';

import { directoryReducer } from '@entities/directory';

const app = {
  toast,
  router,
  general,
  clients,
  search,
  directory: directoryReducer,
  role: $$role.reducer
};

export { app };
