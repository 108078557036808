import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { NewTextField } from './new-text-field.component';
import { NewInputProps } from './new-text-field.props';
import styles from './new-text-field.module.scss';

const NewInput = forwardRef<HTMLInputElement, NewInputProps>(
  ({ error, ...props }, ref) => (
    <NewTextField {...props} error={error}>
      <input
        className={classNames(styles.input, props.classnames?.input)}
        ref={ref}
        {...props}
      />
    </NewTextField>
  )
);

export { NewInput };
