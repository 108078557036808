import {
  HiddenColumnInitialValues,
  HiddenColumnType
} from './hidden-columns.types';

const hiddenColumnStorageKeys: Record<HiddenColumnType, string> = {
  client: 'client-hidden-column'
};

const hiddenColumnTypes = ['client'] as const;

const hiddenColumnsInitialValues: HiddenColumnInitialValues = {
  client: {
    // id: true,
    firstName: true,
    lastName: true,
    middleName: true,
    mainPhone: true,
    additionalPhone: true,
    clientSegmentRFM: true,
    email: true,
    registrationDate: true
  }
};

export {
  hiddenColumnStorageKeys,
  hiddenColumnsInitialValues,
  hiddenColumnTypes
};
