import React from 'react';
import { Form } from '@core/form';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { hoc } from '@core';
import { useEditDataGridProps } from './EditDataGrid.props';
import { alpha, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent } from '@mui/material';

const EditDataGrid = hoc(
  useEditDataGridProps,
  ({
    withoutButtons,
    onReset,
    onButtonClick,
    children,
    rows,
    columns,
    form,
    handleClose,
    handleOpen,
    open,
    editing,
    canUpdate,
    dialogMaxWidth = 'sm',
    styles = {}
  }) => (
    <Form
      use={form}
      style={{
        ...styles,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 400,
        width: '100%'
      }}
    >
      <StripedDataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        onCellClick={handleOpen}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
      />

      <Dialog
        open={!!open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        fullWidth
        maxWidth={dialogMaxWidth}
      >
        <DialogContent
          sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}
        >
          {children}
        </DialogContent>

        {canUpdate && !withoutButtons && (
          <DialogActions>
            <Button
              color='error'
              variant='contained'
              type='reset'
              onClick={onReset}
              disabled={!editing}
            >
              Відмінити
            </Button>

            <Button
              color='success'
              variant='contained'
              type='submit'
              disabled={!editing}
              onClick={onButtonClick}
            >
              Зберегти
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Form>
  )
);

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          )
        }
      }
    }
  }
}));

export { EditDataGrid };
