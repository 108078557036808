import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import { isEqual } from 'lodash';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

import {
  getContact,
  getContactInfo,
  getContactStatus,
  getContactTypes,
  setEditing,
  updateContact
} from '@clients/store';
import { useClientId } from '../../hooks';

const useContactsProps = () => {
  const dispatch = useDispatch();
  const clientId = useClientId();
  const { pathname } = useLocation();

  const { contact, selectedContact } = useTypedSelector(
    (state) => state.clients
  );

  const contactInfo = useFormik({
    initialValues: {
      value: selectedContact?.value,
      phoneType: selectedContact?.type?.title,
      comment: selectedContact?.comment,
      isService: selectedContact?.isService,
      isFinancial: selectedContact?.isFinancial,
      statusId: selectedContact?.status?.title
    },
    onSubmit: (values, { setSubmitting }) => {
      let updateValues = {
        comment: values?.comment,
        isService: values?.isService,
        isFinancial: values?.isFinancial
      };

      //TODO: check
      if (values?.value != contactInfo.initialValues?.value) {
        // @ts-expect-error
        updateValues.value = values?.value;
      }

      dispatch(updateContact({ ...updateValues, id: selectedContact.id }));

      setTimeout(() => {
        dispatch(dispatch(getContactInfo(clientId)));
      }, 1000);

      setSubmitting(false);
      dispatch(setEditing(false));
    },
    enableReinitialize: true
  });

  const onOpen = ({ id }: { id: string }) => {
    dispatch(getContact(id));
  };

  useEffect(() => {
    dispatch(
      setEditing(!isEqual(contactInfo.values, contactInfo.initialValues))
    );
  }, [contactInfo.values, contactInfo.initialValues]);

  useEffect(() => {
    dispatch(getContactTypes(''));
    dispatch(getContactStatus(''));

    dispatch(getContactInfo(clientId));
  }, [pathname]);

  return {
    identification: (contact ?? []).map((cont) => ({
      id: cont?.id,
      value: cont?.value,
      phoneStatus: cont?.status?.title,
      phoneType: cont?.type?.title,
      comment: cont?.comment,
      isFinancial: cont?.isFinancial ? 'Так' : 'Ні'
    })),
    contactInfo,
    onOpen
  };
};
const columns: GridColDef[] = [
  {
    field: 'phoneType',
    headerName: 'Тип',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'value',
    headerName: 'Значення',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'phoneStatus',
    headerName: 'Статус',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'isFinancial',
    headerName: 'Фінансовий',
    type: 'number',
    width: 100,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'comment',
    headerName: 'Коментар',
    type: 'number',
    width: 130,
    sortable: false,
    disableColumnMenu: true
  }
];
export { useContactsProps, columns };
