import { Saga, Payload } from 'redux-chill';
import { put, delay, takeEvery } from 'redux-saga/effects';
import { show } from './actions';

class ToastSaga {
  /**
   * Toast
   */
  @Saga(takeEvery, show)
  public *show({
    message,
    type = 'success',
    duration = 5000
  }: Payload<typeof show>) {
    const id = Number(new Date()) + Math.random().toString();

    const options = {
      id,
      type,
      message,
      duration
    };
    yield put(show.start(options));
    yield delay(duration);
    yield put(show.end(id));
  }
}

export { ToastSaga };
