import * as React from 'react';
import { hoc } from '@core';
import { Field } from '@core/components';
import { columns, useVehiclesProps } from './vehicles.props';
import styles from './vehicles.module.scss';
import { EditDataGrid } from '@shared/ui/edit-data-grid';

const Vehicles = hoc(
  useVehiclesProps,
  ({
    brands,
    types,
    groups,
    currencyNames,
    identification,
    propertyInfo,
    onOpen,
    canUpdate
  }) => (
    <>
      <h1>Транспорт</h1>

      <EditDataGrid
        form={propertyInfo}
        rows={identification}
        columns={columns}
        styles={{ height: 400, width: '100%' }}
        onOpen={onOpen}
        onSubmit={propertyInfo.handleSubmit}
        onReset={propertyInfo.handleReset}
        canUpdate={canUpdate}
      >
        <Field.Input
          className={styles.input}
          theme='primary'
          name='cost'
          label='Ціна'
          editable={canUpdate}
        />

        <Field.Select
          className={styles.inputMargin}
          name='brandId'
          options={brands}
          disabled={!canUpdate}
          label={'Бренд'}
        />

        <Field.Input
          className={styles.input}
          theme='primary'
          name='model'
          label='Модель'
          editable={canUpdate}
        />

        <Field.Input
          className={styles.input}
          theme='primary'
          name='yearProduction'
          label='Рік випуску'
          editable={canUpdate}
        />

        <Field.Select
          className={styles.inputMargin}
          name='groupId'
          options={groups}
          label={'Група'}
          disabled={!canUpdate}
        />

        <Field.Select
          className={styles.inputMargin}
          name='typeId'
          options={types}
          label={'Тип'}
          disabled={!canUpdate}
        />

        <Field.Select
          className={styles.inputMargin}
          name='currencyId'
          options={currencyNames}
          label={'Валюта'}
          disabled={!canUpdate}
        />

        <Field.Date
          className={styles.input}
          theme='primary'
          name='purchaseDate'
          label='Дата покупки'
          editable={canUpdate}
          disabled={!canUpdate}
        />
        <Field.Input
          className={styles.input}
          theme='primary'
          name='comment'
          label='Коментар'
          editable={canUpdate}
        />
      </EditDataGrid>
    </>
  )
);

export { Vehicles };
