import { createTheme, responsiveFontSizes } from '@mui/material';
import { ukUA as dgUkUa } from '@mui/x-data-grid/locales';
import { ukUA as coreUkUa } from '@mui/material/locale';

dgUkUa.components.MuiDataGrid.defaultProps.localeText.filterOperatorIsAnyOf =
  'будь-який з';

const theme = responsiveFontSizes(
  createTheme(
    {
      palette: {
        mode: 'light'
      },
      typography: { htmlFontSize: 10 },

      components: {
        MuiButton: {
          defaultProps: {
            variant: 'contained'
          }
        }
      }
    },
    dgUkUa,
    coreUkUa
  )
);

export { theme };
