import { useClickOutside } from '@core/hooks';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { InputProps, InputRef } from '../input';

type Option = { id: any; name: string };

type AutocompleteProps = InputProps<Option> & {
  options: Option[];
  noResultsPlaceholder?: ReactNode;
  onSearch: (query: string) => void;
  shape?: {
    id?: string;
    name?: string | ((value) => string);
  };
};

const defaultShape: AutocompleteProps['shape'] = {
  id: 'id',
  name: 'name'
};

const useAutocompleteProps = ({
  value,
  options,
  onChange,
  onEdit,
  onSearch,
  shape = defaultShape
}: AutocompleteProps) => {
  const [query, setQuery] = useState('');
  const input = useRef<InputRef>();
  const [visible, setVisible] = useState(false);
  const box = useRef<HTMLDivElement>();

  const selectName = (newValue) =>
    typeof shape.name == 'string' ? newValue[shape.name] : shape.name(newValue);

  useEffect(() => {
    if (!options) {
      setQuery('');
      onChange(null);
    }
  }, [options]);

  const isError = true;

  const isBoxVisible = visible && options?.length > 0;

  const onQueryChange = (newValue: string) => {
    if (!visible) {
      setVisible(true);
    }

    setQuery(newValue);
    onSearch(newValue);
  };

  const onOptionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    option: (typeof options)[number]
  ) => {
    event.preventDefault();
    event.stopPropagation();

    onChange(option);

    setQuery(selectName(option));

    onEdit();

    setTimeout(() => {
      setVisible(false);
    }, 0);
  };

  const onFocus = () => {};

  const onBlur = () => {
    setQuery(value ? selectName(value) : '');
  };

  useEffect(() => {
    setQuery(value ? selectName(value) : '');
  }, [value]);

  useClickOutside(
    () => {
      if (!visible) return;

      setVisible(false);
    },
    box.current,
    [visible]
  );

  return {
    box,
    input,
    query,
    onBlur,
    onFocus,
    selectName,
    isBoxVisible,
    onQueryChange,
    onOptionClick,
    isError
  };
};

export { AutocompleteProps, useAutocompleteProps };
