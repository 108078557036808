import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import { Form } from '@core/form';
import { Button } from '../button';
import { PortalWrapper } from '../portal-wrapper';
import { FilterHeader } from './filter-header';
import { TableFilterWrapperProps } from './table-filter-wrapper.props';
import styles from './table-filter-wrapper.module.scss';

const TableFilterWrapper: React.FC<TableFilterWrapperProps> = ({
  use,
  onBackdropClick,
  children,
  className,
  onResetClick,
  onClick,
  badges,
  onBadgeClose,
  onClearFilter,
  ...formProps
}) => {
  const onFormClick = (event: MouseEvent<HTMLFormElement>) => {
    event.stopPropagation();
    onClick?.(event);
  };

  return (
    <PortalWrapper>
      <div className={styles.wrapper} onClick={onBackdropClick}>
        <Form
          use={use}
          className={classNames(styles.filter, className)}
          onClick={onFormClick}
          {...formProps}
        >
          <FilterHeader
            badges={badges}
            onResetClick={onResetClick}
            onBadgeClose={onBadgeClose}
            onClearFilter={onClearFilter}
          />

          {children}

          <div className={styles.submit}>
            <Button type='submit' disabled={!use.isValid}>
              Застосувати
            </Button>
          </div>
        </Form>
      </div>
    </PortalWrapper>
  );
};

export { TableFilterWrapper };
