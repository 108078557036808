import React, { FC } from 'react';
import { Field } from '@core/components';
import styles from './legal-main-information.module.scss';
import { SectionLayout } from '../../atoms';
import { Role } from '@entities/role';
import classNames from 'classnames';

const LegalMainInformation: FC = () => (
  <SectionLayout
    read={Role.LegalEntityRead}
    update={Role.LegalEntityUpdate}
    disableFlex
  >
    {() => (
      <>
        <Field.Input
          className={styles.input}
          name='edrpou'
          label='ЄДРПОУ'
          theme='primary'
          editable={false}
        />

        <Field.Input
          className={styles.input}
          name='ugbCode'
          label='UGB Код'
          theme='primary'
          editable={false}
        />

        <Field.Input
          className={styles.input}
          name='eddrCode'
          label='ЄДДР код'
          theme='primary'
          editable={false}
        />

        <Field.Input
          className={classNames(styles.input, styles.fullwidth)}
          name='ownershipType'
          label='Форма власності'
          theme='primary'
          editable={false}
        />

        <Field.Input
          className={classNames(styles.input, styles.fullwidth)}
          name='name'
          label='Назва ЮО'
          theme='primary'
          editable={false}
        />
        <Field.Input
          className={classNames(styles.input, styles.fullwidth)}
          name='responsibleManager'
          label='Відповідальний менеджер'
          theme='primary'
          editable={false}
        />
      </>
    )}
  </SectionLayout>
);

export { LegalMainInformation };
