import { HttpService, MockHttp } from '@shared/lib/http';

import { Response, User } from '../models';

class UserService extends MockHttp {
  public constructor(private http: HttpService) {
    super();
  }

  /**
   * Get general user info which used across the app
   */
  public current = () =>
    this.http.request<User>({
      url: '/v1/authentication/user-info'
    });

  /**
   * Create client
   */
  public create = (user: Partial<User>) =>
    this.http.request<Response<User>>({
      data: user,
      method: 'POST',
      url: 'api/v1/clients'
    });
}

export { UserService };
