import React from 'react';
import { Theme } from '@api';
import { Button } from '../../button';
import { BadgeList } from '../../badge';
import { FilterHeaderProps } from './filter-header.props';
import styles from './filter-header.module.scss';
import { ChevronLeft } from '@mui/icons-material';

const FilterHeader: React.FC<FilterHeaderProps> = ({
  badges,
  onResetClick,
  onBadgeClose,
  onClearFilter
}) => (
  <div>
    <button className={styles.back} type='reset' onClick={onResetClick}>
      <ChevronLeft className={styles.icon} name='chevron-left' />

      <span>Назад</span>
    </button>

    <div className={styles.headerWrapper}>
      <div className={styles.header}>
        <h3 className={styles.title}>Інші критерії пошуку</h3>

        <div className={styles.buttons}>
          {!!badges.length && (
            <Button
              className={styles.clear}
              onClick={onClearFilter}
              theme={Theme.secondary}
            >
              &#10005;{'     '}Очистити всі
            </Button>
          )}

          <Button
            theme={Theme.secondary}
            className={styles.close}
            type='reset'
            onClick={onResetClick}
          >
            &#10005;
          </Button>
        </div>
      </div>

      <BadgeList badges={badges} onBadgeClose={onBadgeClose} />
    </div>
  </div>
);

FilterHeader.defaultProps = {
  badges: []
};

export { FilterHeader };
