import { Saga } from 'redux-chill';
import { call, put } from 'redux-saga/effects';
import { Payload } from 'redux-chill/dist';
import { Called } from '@core';
import { show } from '@store/toast';
import type { StoreContext } from '@store/context';

import {
  applyChanges,
  createDirectory,
  getBusinessDirectories,
  getDirectories,
  getDirectory,
  getProductDirectories,
  getProductOwners,
  postBusinessOwner,
  postDirectory,
  postProduct,
  searchDirectories,
  searchDirectoryItems,
  selectDirectory,
  updateDirectory
} from './actions';
import { navigate } from '@store/router';
import { routes } from '@shared/config';

class DirectorySaga {
  @Saga(searchDirectories)
  public *searchDictionary(
    { query, variant }: Payload<typeof searchDirectories>,
    { api }: StoreContext
  ) {
    try {
      const {
        data: dictionaries
      }: Called<typeof api.dictionary.searchDirectories> = yield call(
        api.dictionary.searchDirectories,
        query,
        variant
      );

      yield put(searchDirectories.done(dictionaries));
    } catch (error: unknown) {
      yield put(searchDirectories.fail());
    }
  }

  @Saga(selectDirectory)
  public *searchDictionaryItems(
    { directory }: Payload<typeof selectDirectory>,
    { api }: StoreContext
  ) {
    try {
      const {
        data: dictionaries
      }: Called<typeof api.dictionary.searchDirectoryItems> = yield call(
        api.dictionary.searchDirectoryItems,
        directory.id
      );

      yield put(searchDirectoryItems.done(dictionaries));
    } catch (error: unknown) {
      yield put(searchDirectoryItems.fail());
    }
  }

  @Saga(applyChanges)
  public *applyChanges(
    { id, dto, onResult }: Payload<typeof applyChanges>,
    { api }: StoreContext
  ) {
    try {
      const {
        data: dictionaries
      }: Called<typeof api.dictionary.applyChangesToDirectory> = yield call(
        api.dictionary.applyChangesToDirectory,
        id,
        dto
      );
      yield put(applyChanges.done(dictionaries));
      yield call(onResult, true);
    } catch (error: unknown) {
      yield put(applyChanges.fail());
      yield call(onResult, false);
    }
  }

  @Saga(getProductOwners)
  public *getProductOwners(
    _: Payload<typeof getProductOwners>,
    { api }: StoreContext
  ) {
    try {
      const { data: owners }: Called<typeof api.dictionary.getProductOwners> =
        yield call(api.dictionary.getProductOwners);

      yield put(getProductOwners.done(owners));
    } catch (error: unknown) {
      yield put(getProductOwners.fail());
    }
  }
  @Saga(getDirectories)
  public *getDirectories(
    _: Payload<typeof getDirectories>,
    { api }: StoreContext
  ) {
    try {
      const { data }: Called<typeof api.dictionary.getDirectories> = yield call(
        api.dictionary.getDirectories
      );

      yield put(getDirectories.success(data));
    } catch (error: unknown) {
      yield put(getDirectories.fail());
    }
  }
  @Saga(getProductDirectories)
  public *getProductDirectories(
    _: Payload<typeof getProductDirectories>,
    { api }: StoreContext
  ) {
    try {
      const { data }: Called<typeof api.dictionary.getProductDirectories> =
        yield call(api.dictionary.getProductDirectories);

      yield put(getProductDirectories.success(data));
    } catch (error: unknown) {
      yield put(getProductDirectories.fail());
    }
  }
  @Saga(getBusinessDirectories)
  public *getBusinessDirectories(
    _: Payload<typeof getBusinessDirectories>,
    { api }: StoreContext
  ) {
    try {
      const { data }: Called<typeof api.dictionary.getBusinessDirectories> =
        yield call(api.dictionary.getBusinessDirectories);

      yield put(getBusinessDirectories.success(data));
    } catch (error: unknown) {
      yield put(getBusinessDirectories.fail());
    }
  }
  @Saga(getDirectory)
  public *getDirectory(
    directoryId: Payload<typeof getDirectory>,
    { api }: StoreContext
  ) {
    try {
      const { data }: Called<typeof api.dictionary.getDirectory> = yield call(
        api.dictionary.getDirectory,
        directoryId
      );

      yield put(getDirectory.success(data));
    } catch (error: unknown) {
      yield put(getDirectory.fail());
    }
  }

  @Saga(postDirectory)
  public *postDirectory(
    payload: Payload<typeof postDirectory>,
    { api, history }: StoreContext
  ) {
    try {
      const { data }: Called<typeof api.dictionary.postDirectory> = yield call(
        api.dictionary.postDirectory,
        payload
      );
      yield put(show('Довідник створено', 'success', 3000));
      yield put(navigate(`${routes.directories()}${history.location.search}`));
      yield put(postDirectory.success(data));
    } catch (error: unknown) {
      yield put(postDirectory.fail());
    }
  }
  @Saga(updateDirectory)
  public *updateDirectory(
    payload: Payload<typeof updateDirectory>,
    { api }: StoreContext
  ) {
    try {
      const { data }: Called<typeof api.dictionary.updateDirectory> =
        yield call(api.dictionary.updateDirectory, payload);

      yield put(show('Поля оновлено', 'success', 3000));
      yield put(updateDirectory.success(data));
    } catch (error: unknown) {}
  }
  @Saga(postProduct)
  public *postProduct(
    payload: Payload<typeof postProduct>,
    { api }: StoreContext
  ) {
    try {
      const { data }: Called<typeof api.dictionary.postProduct> = yield call(
        api.dictionary.postProduct,
        payload
      );

      yield put(show('Поля оновлено', 'success', 3000));
      yield put(postProduct.success(data));
    } catch (error: unknown) {}
  }

  @Saga(postBusinessOwner)
  public *postBusinessOwner(
    payload: Payload<typeof postBusinessOwner>,
    { api }: StoreContext
  ) {
    try {
      const { data }: Called<typeof api.dictionary.postBusinessOwner> =
        yield call(api.dictionary.postBusinessOwner, payload);
      yield put(show('Продукт додано', 'success', 3000));

      yield put(postBusinessOwner.success(data));
    } catch (error: unknown) {}
  }

  @Saga(createDirectory)
  public *createDirectory(
    { variant, dto, onResult }: Payload<typeof createDirectory>,
    { api }: StoreContext
  ) {
    try {
      yield call(api.dictionary.createDirectory, variant, dto);

      yield call(onResult, true);
    } catch (error: unknown) {
      yield call(onResult, false);
    }
  }
}

export { DirectorySaga };
