import { matchWhiteSpaces } from './regex';

const parseFullName = (...items: string[] | number[]) => {
  let parsedString = '';

  items.forEach((item, index) => {
    if (!item) return;

    parsedString =
      parsedString +
      String(item) +
      (items.length - 1 !== index && String(item).length ? ' ' : '');
  });

  return parsedString;
};

const removeWhitespaces = (value = '') => value.replace(matchWhiteSpaces, '');

export { removeWhitespaces, parseFullName };
