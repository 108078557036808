import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { hoc } from '@shared/lib/hoc';
import { useNewSwitchProps } from './new-switch.props';

const NewSwitch = hoc(
  useNewSwitchProps,
  ({ value, label, onSwitchChanged, haveFATCA, activeTab, disabled }) => (
    <>
      {activeTab == 'fatca' &&
      (haveFATCA == false || window.location.href.includes('legal')) ? (
        <FormControlLabel
          control={<Switch />}
          label={label}
          disabled
          checked={false}
          defaultChecked={false}
        />
      ) : (
        <FormControlLabel
          control={<Switch />}
          label={label}
          checked={Boolean(value)}
          defaultChecked={Boolean(value)}
          onChange={onSwitchChanged}
          disabled={disabled}
        />
      )}
    </>
  )
);

export { NewSwitch };
