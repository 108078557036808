import { reducer } from 'redux-chill';
import { GeneralState } from './state';
import {
  start,
  authorize,
  setMenu,
  loading,
  getAppData,
  changeTriedToRefresh,
  getTriedRefresh,
  setCurrentClientGroup
} from './actions';

/**
 * General state
 */

const general = reducer(new GeneralState())
  .on(start, (state, { authorized }) => {
    state.ready = true;
    state.authorized = authorized;
  })
  .on(changeTriedToRefresh, (state, value) => {
    state.isTriedToRefresh = value;
  })
  .on(getTriedRefresh, (state) => state.isTriedToRefresh)
  .on(authorize.success, (state) => {
    state.authorized = true;
  })
  .on(authorize.failed, (state) => {
    if (state.isTriedToRefresh) state.authorized = false;
  })
  .on(getAppData.success, (state, user) => {
    state.user = user;
  })
  .on(setCurrentClientGroup, (state, groupName) => {
    state.newClientGroup = groupName;
  })

  .on(setMenu, (state, opened) => {
    state.menu = opened;
  })
  .on(loading.start, (state) => {
    state.loading++;
  })
  .on(loading.finish, (state) => {
    state.loading--;
  });

export { general };
