import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const usePagePath = () => {
  const { pathname } = useLocation();

  return useMemo(() => pathname.split('/')[1], [pathname]);
};

export { usePagePath };
