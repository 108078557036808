//TODO: add types
//TODO: Refactor to extract same logic
class LastLegalClientsShown {
  constructor() {}

  public saveLegalClientsID = (clientId) => {
    const legalClientsArray =
      JSON.parse(localStorage.getItem('legalClientsId')) || [];

    if (legalClientsArray.length == 10) {
      legalClientsArray.pop();
    }

    if (!legalClientsArray.includes(clientId[0])) {
      legalClientsArray.unshift(clientId[0]);
    }

    localStorage.setItem('legalClientsId', JSON.stringify(legalClientsArray));

    return clientId;
  };
  public getLegalClientsID = () => {
    const clientsId = JSON.parse(localStorage.getItem('legalClientsId'));
    return clientsId;
  };
}

export { LastLegalClientsShown };
