import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import {
  getCurrency,
  getEstate,
  getEstatesTypes,
  getOwnershipInfo,
  setEditing,
  updateEstate
} from '@clients/store';
import { useClientId } from '../../../hooks';
import { toSelectOption } from '../../../lib';
import { useTypedSelector } from '@shared/lib/use-typed-selector';
import moment from 'moment';

const useEstatesProps = (_: { canUpdate: boolean }) => {
  const dispatch = useDispatch();
  const clientId = useClientId();
  const { pathname } = useLocation();

  const { currency, estatesTypes, selectedEstate, ownership } =
    useTypedSelector((state) => state.clients);

  const estatesInfo = useFormik({
    initialValues: {
      typeId: selectedEstate?.type?.id,
      currencyId: selectedEstate?.currency?.id,
      cost: selectedEstate?.cost,
      addressId: selectedEstate?.addressId,
      purchaseDate: moment(selectedEstate?.purchaseDate).format('DD-MM-YYYY'),
      comment: selectedEstate?.comment
    },
    onSubmit: (values, { setSubmitting }) => {
      const updateValues = {
        typeId: values?.typeId,
        currencyId: values?.currencyId,
        cost: parseInt(values?.cost),
        addressId: values?.addressId,
        purchaseDate: values?.purchaseDate,
        comment: values?.comment
      };
      dispatch(updateEstate({ ...updateValues, id: selectedEstate.id }));
      setSubmitting(false);
      dispatch(setEditing(false));

      //TODO: check
      setTimeout(() => {
        dispatch(getOwnershipInfo(clientId));
      }, 1000);
    },
    enableReinitialize: true
  });

  const onOpen = ({ id }: { id: string }) => dispatch(getEstate(id));

  useEffect(() => {
    dispatch(getOwnershipInfo(clientId));
    dispatch(getEstatesTypes(''));
    dispatch(getCurrency(''));
  }, [pathname]);

  useEffect(() => {
    dispatch(setEditing(estatesInfo.dirty));
  }, [estatesInfo.dirty]);

  return {
    identification: (ownership?.estates ?? []).map((value) => ({
      id: value?.id,
      typeId: value?.type?.title,
      cost: value?.cost,
      currencyId: value?.currency?.title,
      comment: value?.comment
    })),
    estatesInfo,
    onOpen,
    currencyNames: currency?.map(toSelectOption),
    types: estatesTypes?.map(toSelectOption)
  };
};
const columns: GridColDef[] = [
  {
    field: 'typeId',
    headerName: 'Тип',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'cost',
    headerName: 'Ціна',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'currencyId',
    headerName: 'Валюта',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'comment',
    headerName: 'Коментар',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  }
];

export { useEstatesProps, columns };
