import React, { ForwardedRef, forwardRef } from 'react';
import styles from './loader.module.scss';

const Loader = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => (
  <div
    ref={ref}
    tabIndex={-1}
    className={styles.overlay}
    onMouseDown={(event) => {
      event.stopPropagation();
      event.preventDefault();
    }}
  >
    <div className={styles.overlayInner}>
      <div className={styles.overlayContent}>
        <span className={styles.spinner} />
      </div>
    </div>
  </div>
));

export { Loader };
