import React, { memo } from 'react';
import classNames from 'classnames';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

import { EditPopupProps } from './edit-popup.props';
import styles from './edit-popup.module.scss';
import { Button } from '@mui/material';

const EditPopup: React.FC<EditPopupProps> = memo(
  ({
    resetButtonText,
    submitButtonText,
    className,
    onReset,
    onSubmit,
    resetButtonProps,
    submitButtonProps,
    classnames
  }) => {
    const { editing } = useTypedSelector((state) => state.clients);

    return (
      <div className={classNames(styles.popup, classnames?.root, className)}>
        <div className={styles.buttons}>
          <Button
            className={classnames?.resetButton}
            color='error'
            variant='contained'
            type='reset'
            onClick={onReset}
            disabled={!editing}
            {...resetButtonProps}
          >
            {resetButtonText}
          </Button>

          <Button
            className={classnames?.submitButton}
            color='success'
            variant='contained'
            disabled={!editing}
            onClick={onSubmit}
            {...submitButtonProps}
          >
            {submitButtonText}
          </Button>
        </div>
      </div>
    );
  }
);

EditPopup.defaultProps = {
  resetButtonText: 'Відмінити',
  submitButtonText: 'Зберегти'
};

export { EditPopup };
