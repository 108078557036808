import React, { FC, useEffect } from 'react';
import { Prompt } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ChevronLeft, ContentCopy } from '@mui/icons-material';

import { EditPopup, hoc } from '@core';
import { Form } from '@core/form';

import { ClientInfo } from './client-info';

import { useEditClientProps } from './edit-client.props';
import styles from './edit-client.module.scss';
import classNames from 'classnames';
import { UserStatus } from '@shared/ui/atoms';
import { useDispatch } from 'react-redux';
import {
  getFullProfileFoCustomer,
  getFullProfileCustomer
} from '@clients/store';

const EditClient = hoc(
  useEditClientProps,
  ({
    tab,
    activeTab,
    client,
    editing,
    fatcaInfo,
    clientInfo,
    activityCodesInfo,

    onBackClick,

    onPressEnter,
    commitEdit,
    onCancelClick,
    onSubmitForm,
    onFatcaSubmitForm,
    onFatcaCancelClick,
    onActivityCancelClick,
    onActivitySubmitForm
  }) => {
    if (!client) return null;

    const dispatch = useDispatch();

    useEffect(() => {
      if (client.inn) {
        dispatch(getFullProfileFoCustomer(client.id));
      }

      if (client.edrpou) {
        dispatch(getFullProfileCustomer(client.id));
      }
    }, [client]);

    return (
      <div className={styles.editClient}>
        <BackButton onBackClick={onBackClick} />

        <EditClientHeader />

        <Prompt
          when={editing}
          message='У вас є незбережені зміни, ви впевнені, що хочете вийти?'
        />

        <div className={styles.mainContent}>
          <ul className={styles.fieldsList}>
            {/** FIXME */}
            {activeTab === 'personalInfo' ? (
              <Form
                style={{ display: 'flex', flexDirection: 'row', flex: 1 }}
                use={clientInfo}
                onBlur={() => commitEdit(false)}
                onKeyUp={onPressEnter}
              >
                <ClientInfo />
              </Form>
            ) : activeTab === 'fatca' ? (
              <Form
                style={{ display: 'flex', flexDirection: 'row', flex: 1 }}
                use={fatcaInfo}
                onBlur={() => commitEdit(false)}
                onKeyUp={onPressEnter}
              >
                <ClientInfo>
                  <EditPopup
                    onReset={onFatcaCancelClick}
                    onSubmit={onFatcaSubmitForm}
                  >
                    Ви внесли зміни
                  </EditPopup>
                </ClientInfo>
              </Form>
            ) : activeTab === 'activityCodes' ? (
              <Form
                style={{ display: 'flex', flexDirection: 'row', flex: 1 }}
                use={activityCodesInfo}
                onBlur={() => commitEdit(false)}
                onKeyUp={onPressEnter}
              >
                <ClientInfo>
                  <EditPopup
                    onReset={onActivityCancelClick}
                    onSubmit={onActivitySubmitForm}
                  >
                    Ви внесли зміни
                  </EditPopup>
                </ClientInfo>
              </Form>
            ) : activeTab === 'moreInfo' ? (
              <Form
                style={{ display: 'flex', flexDirection: 'row', flex: 1 }}
                use={clientInfo}
                onBlur={() => commitEdit(false)}
                onKeyUp={onPressEnter}
              >
                <ClientInfo>
                  <EditPopup onReset={onCancelClick} onSubmit={onSubmitForm}>
                    Ви внесли зміни
                  </EditPopup>
                </ClientInfo>
              </Form>
            ) : (
              <Form
                style={{ display: 'flex', flexDirection: 'row', flex: 1 }}
                use={tab}
                onBlur={() => commitEdit(false)}
                onKeyUp={onPressEnter}
              >
                <ClientInfo />
              </Form>
            )}
          </ul>
        </div>
      </div>
    );
  }
);

const EditClientHeader = hoc(
  useEditClientProps,
  ({ showMessage, client, fullClient }) => (
    <div className={classNames(styles.header, styles.title)}>
      {window.location.href.includes('legal-entity') ? (
        <>
          {client?.name || ''} {' | '}
          {client?.status.title}
        </>
      ) : (
        <>
          {client?.lastName || ''} {client?.firstName || ''}{' '}
          {client?.middleName || ''}
          {' | '}
          {client?.status.title}
        </>
      )}

      <div
        style={{
          color: 'grey',
          fontSize: '0.67em',
          display: 'flex',
          alignItems: 'center',
          gap: '15px'
        }}
      >
        <h6>
          <>ID Клієнта: {client.id}</>

          <CopyToClipboard text={client?.id?.toString() ?? ''}>
            <ContentCopy
              style={{ color: '#005395' }}
              sx={{ cursor: 'pointer', verticalAlign: 'middle', ml: 1 }}
              name='copy'
              onClick={() => {
                showMessage(`Поле ID Клієнта було скопійовано`);
              }}
            />
          </CopyToClipboard>
        </h6>
        <UserStatus
          systemTitle={fullClient?.identStatus?.systemTitle}
          title={fullClient?.identStatus?.title}
        />
      </div>
    </div>
  )
);

const BackButton: FC<{ onBackClick: () => void }> = ({ onBackClick }) => (
  <div className={styles.buttonBack}>
    <ChevronLeft className={styles.buttonIcon} onClick={onBackClick} />

    <div className={styles.buttonText} onClick={onBackClick}>
      Назад <span className={styles.desktopOnly}>до списку клієнтів</span>
    </div>
  </div>
);

export { EditClient };
