import { RouteComponentProps } from 'react-router';
import { useScrollToTop } from '@shared/lib/hooks';

const useClients = ({ match }: RouteComponentProps) => {
  useScrollToTop();

  return { match };
};

export { useClients };
