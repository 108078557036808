import React from 'react';
import classNames from 'classnames';
import { FC } from 'react';
import styles from './ui.module.scss';
import { StatusSystemTitle } from '@shared/api/models';

type UserStatusProps = {
  title: string;
  systemTitle: StatusSystemTitle;
};

const UserStatus: FC<UserStatusProps> = ({ systemTitle, title }) => (
  <div
    className={classNames(styles.status, styles[systemTitle?.toLowerCase()])}
  >
    <div className={classNames(styles.title)}>{title}</div>
  </div>
);

export { UserStatus };
