import { filterMap } from '../clients-table.constants';

const createBadge = (
  name: string,
  value: any,
  map: Record<string, string> = filterMap
) => ({
  label: map[name],
  name,
  value: value && typeof value === 'object' ? value.label : value
});

export { createBadge };
