import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { NewTextField } from './new-text-field.component';
import { NewTextAreaProps } from './new-text-field.props';
import styles from './new-text-field.module.scss';

const NewTextArea = forwardRef<HTMLTextAreaElement, NewTextAreaProps>(
  ({ error, ...props }, ref) => (
    <NewTextField {...props} error={error}>
      <textarea
        {...props}
        ref={ref}
        className={classNames(styles.input, props.classnames?.input)}
      />
    </NewTextField>
  )
);

export { NewTextArea };
