import { reducer } from 'redux-chill';

import { DirectoryState } from './state';
import {
  postBusinessOwner,
  postProduct,
  getBusinessDirectories,
  getProductDirectories,
  setButton,
  updateDirectory,
  postDirectory,
  setSelected,
  getDirectories,
  applyChanges,
  createDirectory,
  getProductOwners,
  searchDirectories,
  searchDirectoryItems,
  selectDirectory,
  selectDirectoryVariant,
  setQuery,
  getDirectory,
  setType
} from './actions';

const directoryReducer = reducer(new DirectoryState())
  .on(selectDirectoryVariant, (state, { variant }) => {
    Object.entries(new DirectoryState()).forEach(([key, value]) => {
      state[key] = value;
    });

    state.directoryVariant = variant;
  })

  .on(setButton, (state, isConstant) => {
    state.isConstant = isConstant;
  })

  .on(setQuery, (state, query) => {
    state.query = query;
  })
  .on(setType, (state, type) => {
    state.type = type;
  })
  .on(setSelected, (state, selected) => {
    state.selected = selected;
  })
  .on(searchDirectories, (state) => {
    state.isSearching = true;
  })
  .on(searchDirectories.done, (state, { results }) => {
    state.directories = results;
  })
  .on(getDirectories.success, (state, searchedDirectories) => {
    state.searchedDirectories = searchedDirectories;
  })

  .on(getProductDirectories.success, (state, searchedProductDirectories) => {
    state.searchedProductDirectories = searchedProductDirectories;
  })
  .on(getBusinessDirectories.success, (state, searchedBusinessDirectories) => {
    state.searchedBusinessDirectories = searchedBusinessDirectories;
  })
  .on(getDirectory.success, (state, directory) => {
    state.directory = directory;
  })
  .on(postDirectory.success, (state, createdDirectory) => {
    state.createdDirectory = createdDirectory;
  })
  .on(postProduct.success, (state, createdProduct) => {
    state.createdProduct = createdProduct;
  })
  .on(postBusinessOwner.success, (state, createdOwner) => {
    state.createdOwner = createdOwner;
  })
  .on(updateDirectory.success, (state, selectedDirectory) => {
    state.selectedDirectory = selectedDirectory;
  })
  .on(searchDirectories.fail, (state) => {
    state.directories = [];
  })
  .on([searchDirectories.done, searchDirectories.fail], (state) => {
    state.isSearching = false;
  })

  .on([selectDirectory, createDirectory.done], (state, { directory }) => {
    state.selectedDirectory = directory;

    state.directoryItems = [];
    state.isSearchingDirectoryItems = true;
  })

  .on([searchDirectoryItems.done, applyChanges.done], (state, { results }) => {
    state.directoryItems = results;
  })
  .on([searchDirectoryItems.fail, applyChanges.fail], (state) => {
    state.directoryItems = [];
  })

  .on([searchDirectoryItems.done, searchDirectoryItems.fail], (state) => {
    state.isSearchingDirectoryItems = false;
  })

  .on(getProductOwners, (state) => {
    state.isProductOwnersLoading = true;
  })
  .on(getProductOwners.done, (state, { results }) => {
    state.productOwners = results;
  })
  .on(getProductOwners.fail, (state) => {
    state.productOwners = [];
  })
  .on([getProductOwners.done, getProductOwners.fail], (state) => {
    state.isProductOwnersLoading = false;
  });

export { directoryReducer };
