import { ReactNode } from 'react';
import { useWindowSize } from '@core/hooks';

type TableHeaderProps = {
  tableHeader: string;
  onOpenFilterClick?: () => void;

  additionalMenu?: ReactNode;
};

const useTableHeader = (_: TableHeaderProps) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768;

  return {
    isFilterTextHidden: isMobile && windowSize.width > 370
  };
};

export { useTableHeader, TableHeaderProps };
