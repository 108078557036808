import React from 'react';
import { hoc } from '@core';
import { Menu } from './menu';
import { useNavbar } from './navbar.props';
import styles from './navbar.module.scss';
import { Close, Menu as MenuIcon } from '@mui/icons-material';

const Navbar = hoc(
  useNavbar,
  ({ onMenuClick, isMobileMenuOpened, isTabletUpOrEqual }) => (
    <aside className={styles.sidebar}>
      <div className={styles.logo}>
        <p className={styles.logoText} />
      </div>

      <div className={styles.mobileMenuBtn} onClick={onMenuClick}>
        {isMobileMenuOpened ? <Close /> : <MenuIcon />}
      </div>

      {(isMobileMenuOpened || isTabletUpOrEqual) && <Menu />}
    </aside>
  )
);

export { Navbar };
