import React from 'react';
import classNames from 'classnames';
import { DateFormat, hoc } from '@core/utils';
import { Input } from '../input';
import { Calendar } from '../calendar';
import { useDatePickerProps } from './date-picker.props';
import styles from './date-picker.module.scss';
import { CalendarMonth } from '@mui/icons-material';

/**
 * <DatePicker />
 */
const DatePicker = React.memo(
  hoc(
    useDatePickerProps,
    ({
      after: ___,
      value,
      visible,
      container,
      formatted,
      onInputChange,
      onCalendarChange,
      onCalendarIconClick,
      disabled,
      editable: __,
      // onChange is destructured here to be excluded from the rest of props
      // which go to <Input> field. Instead we provide custom onInputChange
      onChange: _,
      calendarContainerClassName,
      errorMessageClassName,
      className,
      error,
      isError,
      touched,
      isShowError,
      ...props
    }) => (
      <div className={classNames(styles.datePicker, className)}>
        <Input
          className={classNames({
            [styles.input]: !isShowError,
            [styles.inputWithError]: isShowError
          })}
          datepicker
          value={formatted as string}
          onChange={onInputChange}
          after={
            <div ref={container}>
              <CalendarMonth
                className={classNames(styles.icon, {
                  [styles.iconDisabled]: disabled,
                  [styles.iconError]: isShowError
                })}
                onClick={() => {
                  if (disabled) return;

                  onCalendarIconClick();
                }}
              />

              {visible && (
                <div className={styles.calendarContainer}>
                  <Calendar
                    className={classNames(
                      styles.calendar,
                      calendarContainerClassName
                    )}
                    value={value as string}
                    format={DateFormat.dateOnly}
                    onChange={onCalendarChange}
                  />
                </div>
              )}
            </div>
          }
          mask='99.99.9999'
          disabled={disabled}
          touched={touched}
          isError={isError && touched}
          {...props}
        />

        {isShowError && (
          <span className={classNames(styles.error, errorMessageClassName)}>
            {error}
          </span>
        )}
      </div>
    )
  )
);

export { DatePicker };
