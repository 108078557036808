import { useDispatch } from 'react-redux';
import {
  getCurrency,
  getOwnershipInfo,
  getSecurity,
  getSecurityTypes,
  setEditing,
  updateSecurity
} from '@clients/store';
import { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import { useClientId } from '@clients/pages/edit-client/hooks';
import { useLocation } from 'react-router';
import { toSelectOption } from '../../../lib';
import { useTypedSelector } from '@shared/lib/use-typed-selector';
import moment from 'moment';

const useSecuritiesProps = (_: { canUpdate: boolean }) => {
  const dispatch = useDispatch();
  const clientId = useClientId();
  const { pathname } = useLocation();

  const { currency, securityTypes, selectedSecurity, ownership } =
    useTypedSelector((state) => state.clients);

  const securitiesInfo = useFormik({
    initialValues: {
      typeId: selectedSecurity?.type?.id,
      title: selectedSecurity?.title,
      cost: selectedSecurity?.cost,
      currencyId: selectedSecurity?.currency?.id,

      issuerName: selectedSecurity?.issuerName,
      issuerEDRPOU: selectedSecurity?.issuerEDRPOU,

      createdAt: moment(selectedSecurity?.createdAt?.slice(0, 10)).format(
        'DD-MM-YYYY'
      ),
      updatedAt: moment(selectedSecurity?.updatedAt?.slice(0, 10)).format(
        'DD-MM-YYYY'
      )
    },
    onSubmit: (values, { setSubmitting }) => {
      const updateValues = {
        typeId: values?.typeId,
        title: values?.title,
        //@ts-expect-error
        cost: parseInt(values?.cost),
        currencyId: values?.currencyId,

        issuerName: values?.issuerName,
        issuerEDRPOU: values?.issuerEDRPOU
      };
      dispatch(updateSecurity({ ...updateValues, id: selectedSecurity.id }));
      setSubmitting(false);
      dispatch(setEditing(false));

      //TODO: check
      setTimeout(() => {
        dispatch(getOwnershipInfo(clientId));
      }, 1000);
    },
    enableReinitialize: true
  });

  useEffect(() => {
    dispatch(setEditing(securitiesInfo.dirty));
  }, [securitiesInfo.dirty]);

  const onOpen = ({ id }: { id: string }) => dispatch(getSecurity(id));

  useEffect(() => {
    dispatch(getOwnershipInfo(clientId));
    dispatch(getSecurityTypes(''));
    dispatch(getCurrency(''));
  }, [pathname]);

  return {
    types: securityTypes.map(toSelectOption),
    currencyNames: currency.map(toSelectOption),
    identification: useMemo(
      () =>
        (ownership?.securities ?? []).map((value) => ({
          id: value?.id,
          title: value?.title,
          type: value?.type?.title,
          nominalValue: value?.cost,
          currencyId: value?.currency?.title
        })),
      [ownership?.securities]
    ),
    info,
    securitiesInfo,
    onOpen
  };
};

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Назва',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'type',
    headerName: 'Тип',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'nominalValue',
    headerName: 'Номінальна вартість',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'currencyId',
    headerName: 'Валюта',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  }
];

const info = [
  {
    name: 'title',
    label: 'Назва'
  },

  {
    name: 'issuerName',
    label: 'Назва емітента'
  },
  {
    name: 'issuerEDRPOU',
    label: 'ЄДРПОУ емітента'
  },
  {
    name: 'cost',
    label: 'Номінальна вартість'
  }
];

export { useSecuritiesProps, columns };
