import { useHistory } from 'react-router';
import { useEffect } from 'react';

const useManageQuery = <Value extends string>(
  key: string,
  options: {
    defaultValue: Value;
    isValid?: (value: string) => boolean;
    clearOnUmount?: boolean;
  }
): [Value, (value: string) => void] => {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);

  const value = query.get(key);

  const isValid = options.isValid ? options.isValid(value) : true;

  const updateHistory = () => {
    history.replace({ search: query.toString() });
  };

  const change = (newValue: Value | string) => {
    query.set(key, newValue);
    updateHistory();
  };

  useEffect(() => {
    if (isValid) return;

    query.set(key, options.defaultValue);
    updateHistory();
  }, [isValid]);

  return [(isValid ? value : options.defaultValue) as Value, change];
};

export { useManageQuery };
