import React from 'react';
import { Field, FilterSectionWrapper, hoc, TableFilterWrapper } from '@core';
import { useLegalClientsTableFilter } from './legal-clients-filter.props';
import { removeWhitespaces } from '@shared/utils/string';

const LegalClientsTableFilter = hoc(
  useLegalClientsTableFilter,
  ({
    form,
    badges,
    responsibleManagers,
    closeFilter,
    onBadgeClose,
    onResetClick,
    onClearFilters
  }) => (
    <TableFilterWrapper
      use={form}
      onBackdropClick={closeFilter}
      onResetClick={onResetClick}
      badges={badges}
      onBadgeClose={onBadgeClose}
      onClearFilter={onClearFilters}
    >
      <FilterSectionWrapper header='ЄДРПОУ'>
        <div>
          <Field.Input
            name='edrpou'
            label=''
            formatValue={(value) => removeWhitespaces(value)}
            showPlaceholder={false}
          />
        </div>
      </FilterSectionWrapper>

      <FilterSectionWrapper header='Найменування'>
        <div>
          <Field.Input name='companyName' showPlaceholder={false} />
        </div>
      </FilterSectionWrapper>

      <FilterSectionWrapper header='Код UGB'>
        <div>
          <Field.Input
            name='ugbCode'
            formatValue={(value) => removeWhitespaces(value)}
            showPlaceholder={false}
          />
        </div>
      </FilterSectionWrapper>

      <FilterSectionWrapper header='Відповідальний менеджер'>
        <div>
          <Field.NewSelect
            options={responsibleManagers}
            name='responsibleManagerId'
            showPlaceholder={false}
          />
        </div>
      </FilterSectionWrapper>
    </TableFilterWrapper>
  )
);

export { LegalClientsTableFilter };
