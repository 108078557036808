import React from 'react';
import { SectionLayout } from '../atoms';
import { Role } from '@entities/role';

import { Estates } from './estates';
import { Securities } from './securities';
import { Vehicles } from './vehicles';

const Property = () => (
  <SectionLayout read={Role.OwnershipRead} update={Role.OwnershipUpdate}>
    {({ canUpdate }) => (
      <>
        <Estates canUpdate={canUpdate} />
        <Vehicles canUpdate={canUpdate} />
        <Securities canUpdate={canUpdate} />
      </>
    )}
  </SectionLayout>
);

export { Property };
