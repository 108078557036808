function createLegalEntityData(
  openedId: string,
  setOpenedId: (id: string) => void,
  onIconClick: () => void,
  onClick: () => void,
  edrpou: string,
  companyName: string,
  id: string,
  ugbId: string,
  ownershipType,
  eddrCode
) {
  return {
    openedId,
    setOpenedId,
    onIconClick,
    onClick,
    edrpou,
    companyName,
    id,
    ownershipType,
    eddrCode,

    ugbId
  };
}

export { createLegalEntityData };
