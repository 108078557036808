import React from 'react';
import classNames from 'classnames';
import { hoc } from '@core/utils';
import { Input } from '../input';
import { useAutocompleteProps } from './autocomplete.props';
import styles from './autocomplete.module.scss';

const Autocomplete = hoc(
  useAutocompleteProps,
  ({
    box,
    input,
    query,
    onBlur,
    onFocus,
    options,
    selectName,
    isBoxVisible,
    onOptionClick,
    onQueryChange,
    noResultsPlaceholder,
    label,
    disabled,
    isError,
    // the next 3 props are destructured here just to exclude them
    // from the rest of props which go to below <Input> field
    // because of generics types incompatibility between <Input> and <Autocomplete>
    value: __,
    formatValue: _,
    onChange: ___,
    ...props
  }) => (
    <div>
      <Input
        reference={input}
        value={query}
        onChange={(value) => onQueryChange(value.toString())}
        onFocus={onFocus}
        onBlur={onBlur}
        label={label}
        isError={isError}
        disabled={disabled}
        select
        editable
        autocomplete
        {...props}
      >
        <div ref={box}>
          {isBoxVisible && (
            <div className={styles.box} ref={box}>
              <div className={styles.options}>
                {options?.length > 0 ? (
                  options.map((option, index) => (
                    <div
                      className={styles.option}
                      key={index}
                      onMouseDown={(event) => onOptionClick(event, option)}
                    >
                      {selectName(option)}
                    </div>
                  ))
                ) : (
                  <div className={classNames(styles.option, styles.empty)}>
                    {noResultsPlaceholder}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Input>
    </div>
  )
);

Autocomplete.defaultProps = {
  options: [],
  noResultsPlaceholder: null
};

export { Autocomplete };
