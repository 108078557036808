enum UserException {
  wrongCredentialsException = 'WrongCredentialsException',
  unauthorizedException = 'UnauthorizedException',
  validationException = 'CdpApiException',
  argumentInvalidException = 'ArgumentInvalidException',
  databaseException = 'DatabaseException',
  internalServerException = 'InternalServerException',
  rabbitMqModuleException = 'RabbitMqModuleException',
  rabbitMQPublisherException = 'RabbitMQPublisherException',
  customerAlreadyExistsException = 'CustomerAlreadyExistsException',
  legalEntityAlreadyExistsException = 'LegalEntityAlreadyExistsException',
  searchIntervalTooLargeException = 'SearchIntervalTooLargeException',
  notFoundException = 'NotFoundException',
  wrongRequestException = 'WrongRequestException',
  badRequestException = 'BadRequestException',
  csvBadRowException = 'CsvBadRowException',
  cdpApiException = 'CdpApiException'
}

class Role {
  public groupName: string;
  public roles: string[];
  public systemGroupName: string;
  public shortSystemGroupName: string;
}

class User {
  public accessGroup: Role;
}

export { User, UserException };
