import React from 'react';
import classNames from 'classnames';
import { hoc, PopupWrapper } from '@core';
import { useLogoutPopupProps } from './logout-popup.props';
import styles from './logout-popup.module.scss';
import { Logout } from '@mui/icons-material';

import personIcon from '@shared/ui/assets/images/person-32.png';

const LogoutPopup = hoc(
  useLogoutPopupProps,
  ({
    visible,
    onShowPopupClick,
    onLogoutClick
    // userDisplayRole,
    // userDisplayRoleShort
  }) => (
    <div className={styles.profileMenu}>
      <div
        className={classNames(styles.avatarWrapper, styles.placeholder)}
        onClick={onShowPopupClick}
      >
        {/* {userDisplayRoleShort && <span>{userDisplayRoleShort}</span>} */}
        <img src={personIcon} className={styles.personIcon} />
      </div>

      <PopupWrapper value={visible} onClickFunction={onShowPopupClick}>
        <div className={styles.burgerWrapper} onClick={onShowPopupClick}>
          <div className={styles.burger} />
          <div className={styles.burger} />
          <div className={styles.burger} />
        </div>

        <div className={visible ? styles.logoutMenu : styles.hidden}>
          <ul className={styles.list}>
            {/* {userDisplayRole && (
              <li className={styles.logoutMenuItem}>
                <span className={styles.logoutMenuText}>{userDisplayRole}</span>
              </li>
            )} */}

            <li className={styles.logoutMenuItem} onClick={onLogoutClick}>
              <Logout className={styles.icon} name='log-out' />
              <span className={styles.logoutMenuText}>Вийти</span>
            </li>
          </ul>
        </div>
      </PopupWrapper>
    </div>
  )
);

export { LogoutPopup };
