import React from 'react';
import { hoc } from '@core';
import { Field } from '@core/components';
import { columns, useSecuritiesProps } from './securities.props';
import styles from './securities.module.scss';
import { EditDataGrid } from '@shared/ui/edit-data-grid';

const Securities = hoc(
  useSecuritiesProps,
  ({
    types,
    currencyNames,
    identification,
    info,
    securitiesInfo,
    onOpen,
    canUpdate
  }) => (
    <>
      <h1>Цінні папери</h1>

      <EditDataGrid
        form={securitiesInfo}
        rows={identification}
        columns={columns}
        styles={{ height: 400, width: '100%' }}
        onOpen={onOpen}
        onSubmit={securitiesInfo.handleSubmit}
        onReset={securitiesInfo.handleReset}
        canUpdate={canUpdate}
      >
        <Field.Select
          className={styles.inputMargin}
          name='typeId'
          options={types}
          label={'Тип'}
          disabled={!canUpdate}
        />

        <Field.Select
          className={styles.inputMargin}
          name='currencyId'
          options={currencyNames}
          label={'Валюта'}
          disabled={!canUpdate}
        />

        {info.map((field) => (
          <Field.Input
            className={styles.input}
            theme='primary'
            name={field.name}
            label={field.label}
            key={field.name + field.label}
            editable={canUpdate}
          />
        ))}

        <Field.Input
          className={styles.input}
          name='createdAt'
          theme='primary'
          label='Дата створення'
          editable={false}
        />

        <Field.Input
          className={styles.input}
          name='updatedAt'
          theme='primary'
          label='Дата оновлення'
          editable={false}
        />
      </EditDataGrid>
    </>
  )
);

export { Securities };
