import { AxiosResponse } from 'axios';

import { delay } from '@shared/lib/async-utils';

class MockHttp {
  private mockResponseConfig: Omit<AxiosResponse, 'data'> = {
    status: 200,
    config: {},
    request: {},
    statusText: 'Ok',
    headers: {}
  };

  protected getMockResponse = async <T>(data: T): Promise<AxiosResponse<T>> => {
    await delay();

    return Object.assign({}, this.mockResponseConfig, { data });
  };
}

export { MockHttp };
