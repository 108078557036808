import type { FC, MemoExoticComponent } from 'react';
import React, { memo } from 'react';

import type { PartialFields } from '@shared/lib/utility-types';

const hoc = <
  SourceProps extends object,
  HookProps,
  DefaultProps extends Partial<SourceProps>
>(
  hook: (props: SourceProps) => HookProps,
  Source: FC<HookProps & SourceProps>,
  defaultProps?: DefaultProps
) => {
  type Props = PartialFields<
    SourceProps,
    Exclude<keyof SourceProps, keyof DefaultProps>
  >;
  type ComponentType = FC<Props>;

  type ResultType = ComponentType & {
    Original: typeof Source;
    hook: typeof hook;
    Memo: MemoExoticComponent<ComponentType>;
  };

  const Component = (props: SourceProps) => (
    <Source {...hook(props)} {...props} />
  );

  const Result = Component as ResultType;

  Result.defaultProps = defaultProps ?? {};

  Result.Original = Source;
  Result.hook = hook;

  // @ts-expect-error
  Result.Memo = memo(Component);

  return Result;
};

export { hoc };
