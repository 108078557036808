/**
 * Build enviroment
 */

const api = (process.env.api as any as {
  url: string;
}) || { url: process.env.REACT_APP_API_URL };

const enviroment = {
  api: api,
  stage: process.env.stage,
  development: process.env.NODE_ENV != 'production',

  logoutUrl: `${api.url}/v1/authentication/logout`
};

export { enviroment };
