import { SearchResult } from '@app';

class SearchState {
  /**
   * Search query
   */
  public query: string;

  /**
   * Results
   */
  public results: SearchResult[];

  /**
   * Search result status
   */
  public searchResultStatus: boolean = true;

  /**
   * Latest search results
   */
  public latestSearchResults: any[];
}

export { SearchState };
