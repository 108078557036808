import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { memo } from 'react';
import { Tooltip } from '@mui/material';
import { Warning } from './identification.types';

function renderCellWithTooltip(
  params: GridRenderCellParams<string, Warning, string>
) {
  return <GridCellWithTooltip text={getFieldValue(params)} />;
}

function getFieldValue(params: { formattedValue?: string; value?: string }) {
  return params.formattedValue ?? params.value ?? '';
}

const GridCellWithTooltip = memo(({ text }: { text: string }) => (
  <Tooltip title={text} placement='bottom-start'>
    <span>{text}</span>
  </Tooltip>
));

export { renderCellWithTooltip };
