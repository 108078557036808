import React, { FC, memo } from 'react';
import { Grid } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';

import { hoc } from '@core';
import { Field } from '@core/components';

import { Role } from '@entities/role';

import { EditDataGrid } from '@shared/ui/edit-data-grid';

import { SectionLayout } from '../atoms';
import {
  columns,
  useIdentificationWarningsProps,
  useIdentProps,
  Warning,
  warningsColumns
} from './identification.props';

const Identification = hoc(
  useIdentProps,
  ({ identification, identificationInfo, onOpen }) => (
    <SectionLayout
      read={Role.IdentificationHistoryRead}
      update={Role.IdentificationHistoryUpdate}
    >
      {({ canUpdate }) => (
        <EditDataGrid
          form={identificationInfo}
          rows={identification}
          columns={columns}
          onOpen={onOpen}
          onSubmit={identificationInfo?.handleSubmit}
          onReset={identificationInfo.handleReset}
          canUpdate={canUpdate}
        >
          <Grid display='grid' gridTemplateColumns='repeat(2, 1fr)' gap={1}>
            <Field.Date
              name='nextIdentDate'
              label='Дата наступної ідентифікації'
              theme='primary'
              editable={false}
              disabled
            />

            <Field.Date
              name='identDate'
              label='Дата ідентифікації'
              theme='primary'
              editable={false}
              disabled
            />
          </Grid>

          <Grid display='grid' gridTemplateColumns='repeat(2, 1fr)' gap={1}>
            <Field.NewSwitch
              name='fllIdent'
              label='Ідентифікація інформації'
              disabled={!canUpdate}
            />

            <Field.NewSwitch
              name='docsIdent'
              label='Ідентифікація документів'
              disabled={!canUpdate}
            />

            <Field.NewSwitch
              name='questIdent'
              label='Ідентифікація питання'
              disabled={!canUpdate}
            />

            <Field.NewSwitch
              name='innIdent'
              label='Ідентифікація ІНН'
              disabled={!canUpdate}
            />

            <Field.NewSwitch
              name='photoIdent'
              label='Ідентифікація Фото'
              disabled={!canUpdate}
            />

            <Field.NewSwitch
              name='isSuccess'
              label='Успішна'
              disabled={!canUpdate}
            />
          </Grid>

          <Grid container mt={2}>
            <IdentificationWarnings />
          </Grid>
        </EditDataGrid>
      )}
    </SectionLayout>
  )
);

const IdentificationWarnings: FC = memo(
  hoc(useIdentificationWarningsProps, ({ warnings, onCopyActionClick }) => (
    <DataGrid<Warning>
      columns={[
        ...warningsColumns,
        {
          field: 'Дії',
          type: 'actions',
          maxWidth: 50,
          getActions: (params) => [
            //TODO: check problem
            // @ts-ignore
            <GridActionsCellItem
              key='copy'
              label='copy'
              icon={<ContentCopyIcon />}
              onClick={onCopyActionClick(params)}
            />
          ]
        }
      ]}
      rows={warnings}
      sx={{ height: 400 }}
      hideFooter
    />
  ))
);

export { Identification };
