import { make } from 'redux-chill';
import { User } from '@api';

/**
 * Authorize
 */
const authorize = make('[general] authorize')
  .stage((token: string, refreshToken: string) => ({ token, refreshToken }))
  .stage('success')
  .stage('failed');

/**
 * Set curent group name
 */
const setCurrentClientGroup = make('[client] set current client group').stage(
  (groupName: string) => groupName
);

/**
 * Get authorized data
 */
const getAppData = make('[general] get authorized data')
  .stage('success', (user: User) => user)
  .stage('finish');

/**
 * Get if tried to refresh token param
 */
const getTriedRefresh = make('[general] get tried to refresh').stage(() => {});

/**
 * Change try to refresh
 */
const changeTriedToRefresh = make('[general] tried to refresh token').stage(
  (value: boolean) => value
);

/**
 * Start app
 */
const start = make('[general] start').stage(
  (payload: { authorized: boolean }) => payload
);

/**
 * Set menu
 */
const setMenu = make('[general] set menu').stage((opened: boolean) => opened);

/**
 * Logout
 */
const logout = make('[general] logout');

/**
 * Loading
 */
const loading = make('[loading] get').stage('start').stage('finish');

/**
 *  Fetch error
 */

const fetchError = make('[general] fetch error').stage(
  <T extends { data: { code: string }; status: number }>(error: T) => ({
    error
  })
);

export {
  start,
  logout,
  setMenu,
  getAppData,
  authorize,
  loading,
  changeTriedToRefresh,
  getTriedRefresh,
  setCurrentClientGroup,
  fetchError
};
