import React from 'react';
import { hoc } from '@core';
import { Field } from '@core/components';
import { useActivityCodesProps } from './activity-codes.props';
import styles from './activity-codes.module.scss';
import { FormGroup } from '@mui/material';
import { SectionLayout } from '../atoms';
import { Role } from '@entities/role';

const ActivityCodes = hoc(useActivityCodesProps, ({ names }) => (
  <SectionLayout
    read={Role.IndividualEntrepreneursRead}
    update={Role.IndividualEntrepreneursUpdate}
    disableFlex
  >
    {({ canUpdate }) => (
      <>
        <FormGroup>
          <Field.NewSwitch
            className={styles.input}
            name='employeesExistence'
            label='Є наймані працівники'
            disabled={!canUpdate}
          />

          <Field.NewSwitch
            className={styles.input}
            name='licenseExistence'
            label='Є ліцензії'
            disabled={!canUpdate}
          />

          <Field.NewSwitch
            className={styles.input}
            name='otherBankAccountsExistence'
            label='Є рахунки в інших банках'
            disabled={!canUpdate}
          />

          <Field.NewSwitch
            className={styles.input}
            name='otherBankRejection'
            label='Відмови у ділових відносинах в інших банках'
            disabled={!canUpdate}
          />
        </FormGroup>

        <Field.Input
          className={styles.input}
          name='expectedIncome'
          label='Очікувана сума заробітку'
          theme='primary'
          editable={canUpdate}
        />

        <Field.NewMultipleSelect
          name='codes'
          names={names}
          inputLabel='КВЕД'
          disabled={!canUpdate}
        />
      </>
    )}
  </SectionLayout>
));

export { ActivityCodes };
