import React, { memo } from 'react';
import classNames from 'classnames';
import { BadgeListProps, BadgeProps } from './badge.props';
import styles from './badge.module.scss';
import { Close } from '@mui/icons-material';

const Badge: React.FC<BadgeProps> = memo(
  ({ children: _, label, value, name, className, onBadgeClose, ...props }) => (
    <div className={classNames(styles.badge, className)} {...props}>
      <span>
        {label}: <b>{value}</b>
      </span>

      {onBadgeClose && (
        <span
          className={styles.close}
          onClick={() => onBadgeClose(name, value)}
        >
          <Close />
        </span>
      )}
    </div>
  )
);

const BadgeList: React.VFC<BadgeListProps> = memo(
  ({ badges, onBadgeClose }) => {
    if (!badges.length) {
      return <div className={styles.placeholder} />;
    }

    return (
      <ul className={styles.badges}>
        {badges.map((badge) => (
          <li key={badge.value + badge.name + badge.label}>
            <Badge {...badge} onBadgeClose={onBadgeClose} />
          </li>
        ))}
      </ul>
    );
  }
);

export { Badge, BadgeList };
