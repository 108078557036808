import React from 'react';
import _Mask, * as InputMask from 'react-input-mask';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from 'classnames';
import { Theme } from '@api';
import { hoc } from '@core/utils';
import { Label } from '../label';
import { useInputProps } from './input.props';
import styles from './input.module.scss';
import { ContentCopy, Edit } from '@mui/icons-material';

/**
 * !Typings retard alert!
 */
const Mask: typeof _Mask = InputMask as any;

/**
 * Renders Input
 */
const Input = hoc(
  useInputProps,
  ({
    inputType,
    mask,
    // type,
    after,
    input,
    value,
    units,
    label,
    error,
    isError,
    focused,
    _onBlur,
    disabled,
    editable,
    _onFocus,
    maskChar,
    children,
    hasValue,
    className,
    onKeyDown,
    onInputChange,
    theme,
    visibleValue,
    group,
    datepicker,
    select,
    autocomplete,
    showMessage,
    iconName,
    showPlaceholder,
    isShowCustomError,
    errorMessage,

    touched
  }) => (
    <div
      className={classNames(styles.input, className, {
        [styles.inputAfter]: after,
        [styles.inputFocused]: focused && editable,
        [styles.inputHasError]: (isError || isShowCustomError) && touched,
        [styles.inputDisabled]: disabled,
        [styles.inputHasValue]: hasValue,
        [styles.inputHasLabel]: Boolean(label),
        [styles.inputPrimary]: theme == Theme.primary,
        [styles.inputGroup]: theme == Theme.group,
        [styles.inputEditable]: editable,
        [styles.inputDatepicker]: datepicker,
        [styles.inputSelect]: select,
        [styles.inputAutocomplete]: autocomplete,
        [styles.visibleValue]: visibleValue
      })}
    >
      {label && (
        <Label className={classNames({ [styles.groupInputLabel]: group })}>
          {label}
        </Label>
      )}

      {editable ? (
        <div className={styles.forTooltip}>
          <Mask
            className={classNames(styles.field, {
              [styles.inputNumber]: inputType === 'number'
            })}
            type={inputType}
            mask={mask}
            onBlur={_onBlur}
            onKeyDown={onKeyDown}
            inputRef={(ref) => (input.current = ref)}
            onFocus={_onFocus}
            disabled={disabled}
            value={value?.toString() ?? ''}
            maskChar={maskChar}
            onChange={onInputChange}
            {...(showPlaceholder ? { placeholder: 'Відсутня інформація' } : {})}
          />
          {label == 'E-mail' && showPlaceholder && (
            <span className={styles.tooltiptext}>{value?.toString()}</span>
          )}

          {!select && !group && iconName != 'eye-off' && !disabled && (
            <Edit
              className={classNames(styles.icon, styles.iconEdit, {
                [styles.datepicker]: datepicker
              })}
              name='pencil'
              onClick={() => {
                input.current.focus();
              }}
            />
          )}
        </div>
      ) : (
        <div className={styles.forTooltip}>
          <Mask
            className={classNames(styles.field, {
              [styles.inputNumber]: inputType === 'number'
            })}
            type={inputType}
            mask={mask}
            onBlur={_onBlur}
            onKeyDown={onKeyDown}
            inputRef={(ref) => (input.current = ref)}
            onFocus={_onFocus}
            disabled={disabled}
            value={value?.toString() ?? ''}
            maskChar={maskChar}
            onChange={onInputChange}
            {...(showPlaceholder ? { placeholder: 'Відсутня інформація' } : {})}
          />
        </div>
      )}
      {value &&
      value !== 'null' &&
      (theme == Theme.primary || theme == Theme.group) &&
      !select ? (
        <div className={styles.copyBox}>
          <span className={styles.inputValue}>
            {value} {units}
          </span>

          <CopyToClipboard text={value?.toString() ?? ''}>
            <ContentCopy
              className={styles.iconCopy}
              name='copy'
              onClick={() => {
                showMessage(`Поле ${label} було скопійовано`);
              }}
            />
          </CopyToClipboard>
        </div>
      ) : (
        <></>
      )}

      {after}

      {children}

      {isError && touched && <div className={styles.error}>{error}</div>}

      {isShowCustomError && touched && (
        <div className={styles.error}>{errorMessage}</div>
      )}
    </div>
  )
);

Input.defaultProps = {
  value: '',
  mask: null,
  type: 'text',
  maskChar: '_',
  editable: true,
  onBlur: () => {},
  onFocus: () => {},
  showPlaceholder: true
};

export { Input };
