import { reducer } from 'redux-chill';
import { SearchState } from './state';
import {
  clearSearchResults,
  setSearchQuery,
  setSearchResults,
  setSearchResultStatus
} from './actions';

/**
 * Search state
 */

const search = reducer(new SearchState())
  .on(setSearchQuery, (state, { query }) => {
    state.query = query;
    state.results = [];
  })
  .on(setSearchResults, (state, { results }) => {
    state.results = results;

    if (!results?.length) return;

    state.latestSearchResults = results;
  })
  .on(setSearchResultStatus, (state, { status }) => {
    state.searchResultStatus = status;
  })
  .on(clearSearchResults, (state) => {
    state.latestSearchResults = [];
  });

export { search };
