import moment, { MomentInput } from 'moment';
import { timeOptions } from '@core/constants';
import { Formatter } from '@core/utils/formatter';

enum DateFormat {
  dateOnly = 'YYYY-MM-DD',
  eurHypen = 'DD-MM-YYYY',
  eur = 'DD.MM.YYYY',
  hourMinSec = 'HH:mm:ss',
  hourMin = 'HH:mm'
}

class DateUtils {
  static get currentTimeFormatted30Min() {
    const [hour, minutes] = DateUtils.formatHourMin(DateUtils.today).split(':');

    if (minutes >= '30') {
      return [Formatter.toHour(hour === '23' ? 0 : 1 + Number(hour)), '00'];
    }

    return [hour, '30'];
  }

  static get currentTimeStart() {
    const [hour, min] = DateUtils.currentTimeFormatted30Min;

    return timeOptions.find(({ label }) => label === `${hour}:${min}`);
  }

  static get currentDateWithCurrentTimeOffset() {
    const [hour, min] = DateUtils.currentTimeFormatted30Min;

    if (hour === '00' && min === '00') {
      return DateUtils.formatDateOnly(DateUtils.from(new Date()).add(1, 'day'));
    }

    return DateUtils.todayDate;
  }

  static timeStart = timeOptions.find(({ label }) => label === '09:00');
  static timeEnd = timeOptions.find(({ label }) => label === '18:00');

  static get todayDate() {
    return DateUtils.formatDateOnly(new Date());
  }

  static get today() {
    return DateUtils.from(new Date());
  }

  static from = (value: MomentInput) => moment(value);

  static formatDefault = (value: MomentInput) => DateUtils.from(value).format();

  static formatDateOnly = (value: MomentInput) =>
    DateUtils.from(value).format(DateFormat.dateOnly);

  static formatDotsYear = (value: MomentInput) =>
    DateUtils.from(value).format(DateFormat.eur);

  static formatDateEurHypen = (value: MomentInput) =>
    DateUtils.from(value).format(DateFormat.eurHypen);

  static formatHourMinSec = (value: MomentInput) =>
    DateUtils.from(value).format(DateFormat.hourMinSec);

  static formatHourMin = (value: MomentInput) =>
    DateUtils.from(value).format(DateFormat.hourMin);

  static toISOString = (value: string) => DateUtils.from(value).toISOString();

  static toISO = (dateRaw: string, timeRaw: string) => {
    const [day, month, year] = DateUtils.formatDotsYear(dateRaw).split('.');
    const [hour, time] = timeRaw.split(':');

    const date = new Date(+year, +month - 1, +day, +hour, +time);

    return DateUtils.from(date).toISOString();
  };

  static formatDotsYearHour = (value: string) =>
    `${DateUtils.formatDotsYear(value)} o ${DateUtils.formatHourMinSec(value)}`;

  static isValid = (value: MomentInput) => DateUtils.from(value).isValid();

  static isAfterFromToday = (value: MomentInput) =>
    DateUtils.from(value).isSameOrAfter(DateUtils.today, 'days');

  static isToday = (value: MomentInput) =>
    DateUtils.from(value).isSame(DateUtils.today, 'days');
}

export { DateFormat, DateUtils };
