import { SVGAttributes, useEffect, useState, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Folder, Person } from '@mui/icons-material';

import { useMediaPoints } from '@core';
import { State } from '@store';
import { logout } from '@store/general';
import { setIsMobileMenuOpened, setQueryValue } from '@clients/store';

import { Role } from '@entities/role';
import { routes } from '@shared/config';

const useMenuProps = () => {
  const dispatch = useDispatch();
  const { isTabletUp } = useMediaPoints();

  const {
    general: { user }
  } = useSelector((state: State) => state);

  const [activeLinkIndex, setActiveLinkIndex] = useState(0);

  const userDisplayRole = user?.accessGroup?.groupName;

  const onLinkClick = () => {
    dispatch(setQueryValue(''));
    if (isTabletUp) return;

    dispatch(setIsMobileMenuOpened());
  };

  const onLogoutClick = () => {
    dispatch(logout());
  };

  useEffect(() => {
    links.forEach((link, index) => {
      if (
        !(
          window.location.pathname.includes(link.to) &&
          activeLinkIndex !== index
        )
      ) {
        return;
      }

      setActiveLinkIndex(index);
    });
  }, [window.location.pathname, activeLinkIndex]);

  return {
    userDisplayRole,
    links,
    activeLinkIndex,
    onLogoutClick,
    onLinkClick
  };
};

const links: {
  to: string;
  iconName?: string;
  text: string;
  icon?: VFC<SVGAttributes<HTMLOrSVGElement>>;
  role: Role;
}[] = [
  {
    to: '/clients/fo',
    icon: Person,
    text: 'ФО і ФОП',
    role: Role.PhysicalsAccess
  },
  {
    to: '/clients/legal-entity',
    icon: Person,
    text: 'ЮО',
    role: Role.LegalEntityAccess
  },
  {
    to: routes.directories(),
    icon: Folder,
    text: 'Довідники',
    role: Role.AdminDirectoriesAccess
  }
];

export { useMenuProps };
