import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { Router as DOMRouter } from 'react-router-dom';
import { Router } from 'react-router';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '@shared/config';

import { ProviderProps } from './provider.props';

/**
 * Renders Provider
 */
const Provider: React.FC<ProviderProps> = ({ children, store, history }) => (
  <StoreProvider store={store}>
    <DOMRouter history={history}>
      <CssBaseline />

      <ThemeProvider theme={theme}>
        <Router history={history}>{children}</Router>
      </ThemeProvider>
    </DOMRouter>
  </StoreProvider>
);

export { Provider };
