import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Role } from '../../types';
import { useHasRole } from '../../model';

type RoleAccessProps = PropsWithChildren<{
  role: Role;
  fallback: ReactNode;
}>;

const RoleAccess: FC<RoleAccessProps> = ({ role, fallback, children }) => {
  const canAccess = useHasRole(role);

  return <>{canAccess ? children : fallback}</>;
};

export { RoleAccess };

export type { RoleAccessProps };
