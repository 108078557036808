enum FilterNames {
  id = 'id',
  lastName = 'lastName',
  firstName = 'firstName',
  middleName = 'middleName',
  birthDate = 'birthDate',
  phone = 'phone',
  status = 'status',
  systemId = 'systemId',
  accountNumber = 'accountNumber',
  accountBalance = 'accountBalance',
  clientGroup = 'clientGroup'
}

export { FilterNames };
