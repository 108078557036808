import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { hoc, register } from '@core';
import { Loader } from '@core/components';

import { routes } from '@shared/config';

import { Toast } from './components';

import { LegalEntityClients } from '@clients/legal-entity-clients.component';

import { useAppProps } from './app.props';
import styles from './app.module.scss';
import { Modal } from '@mui/material';

const Clients = register('clients', () => import('@clients'));

const Dictionaries = lazy(() => import('@pages/directory'));

const App = hoc(
  useAppProps,
  ({ ready, loading, authorized, nearestAccessiblePath }) => {
    if (!authorized || !ready) return <Loader />;

    //TODO: routes for edit

    return (
      <div className={styles.app}>
        <React.Suspense fallback={<Loader />}>
          <Switch>
            <Route path='/clients/fo' component={Clients} />

            <Route
              path='/clients/legal-entity'
              component={LegalEntityClients}
            />

            <Route path={routes.directories()} component={Dictionaries} />

            <Redirect to={nearestAccessiblePath} />
          </Switch>

          <Toast />

          <Modal open={loading > 0} hideBackdrop disableAutoFocus>
            <Loader />
          </Modal>
        </React.Suspense>
      </div>
    );
  }
);

export { App };
