import React from 'react';
import { hoc } from '@core';
import { Field } from '@core/components';
import {
  columns,
  detailColumns,
  useRelationshipsProps
} from './relationships.props';
import styles from './relationships.module.scss';
import { EditDataGrid } from '@shared/ui/edit-data-grid';
import { SectionLayout } from '../atoms';
import { Role } from '@entities/role';

const Relationships = hoc(
  useRelationshipsProps,
  ({
    identification,
    relationshipsInfo,
    onOpen,
    roles,
    selfIdentification
  }) => (
    <SectionLayout
      read={Role.RelationshipsRead}
      update={Role.RelationshipsUpdate}
    >
      {({ canUpdate }) => (
        <>
          {window.location.href.includes('/fo') && (
            <>
              <h1>Особисті зв'язки</h1>

              <EditDataGrid
                form={relationshipsInfo}
                rows={selfIdentification}
                columns={detailColumns}
                styles={{ height: 400, width: '100%' }}
                onOpen={onOpen}
                onSubmit={relationshipsInfo.handleSubmit}
                onReset={relationshipsInfo.handleReset}
                canUpdate={canUpdate}
              >
                <Field.Select
                  className={styles.input}
                  options={roles}
                  name='client1Role'
                  label='Назва ролі іншої ФО'
                  disabled={!canUpdate}
                />

                <Field.Select
                  className={styles.input}
                  options={roles}
                  name='client2Role'
                  label='Назва моєї ролі '
                  disabled={!canUpdate}
                />

                <Field.Input
                  className={styles.input}
                  name='lastName'
                  label='Прізвище'
                  theme='primary'
                  editable={false}
                />

                <Field.Input
                  className={styles.input}
                  name='firstName'
                  label="Ім'я"
                  theme='primary'
                  editable={false}
                />

                <Field.Input
                  className={styles.input}
                  name='middleName'
                  label='По батькові'
                  theme='primary'
                  editable={false}
                />

                <Field.Input
                  className={styles.input}
                  theme='primary'
                  name='createdAt'
                  label='Дата створення'
                  editable={false}
                />

                <Field.Input
                  className={styles.input}
                  theme='primary'
                  name='documentId'
                  label='Документ'
                  editable={canUpdate}
                />
              </EditDataGrid>
            </>
          )}

          <h1>Корпоративні зв'язки</h1>

          <EditDataGrid
            form={relationshipsInfo}
            rows={identification}
            columns={columns}
            styles={{ height: 400, width: '100%' }}
            onOpen={onOpen}
            onSubmit={relationshipsInfo.handleSubmit}
            onReset={relationshipsInfo.handleReset}
            canUpdate={canUpdate}
          >
            <Field.Select
              className={styles.input}
              options={roles}
              name='client1Role'
              label='Назва моєї ролі'
              disabled={!canUpdate}
            />

            <Field.Input
              className={styles.input}
              theme='primary'
              name='companyName'
              label='Назва компанії / ПІБ ФО'
              editable={canUpdate}
            />
            <Field.Input
              className={styles.input}
              theme='primary'
              name='createdAt'
              label='Дата створення'
              editable={false}
            />

            <Field.Input
              className={styles.input}
              theme='primary'
              name='documentId'
              label='Документ'
              editable={canUpdate}
            />
          </EditDataGrid>
        </>
      )}
    </SectionLayout>
  )
);

export { Relationships };
