import { reducer } from 'redux-chill';
import { ClientsState } from './state';
import {
  addClient,
  changeActiveTab,
  changeBlock,
  changeFilter,
  changeNav,
  clientsFilter,
  deleteRecentlyAddedId,
  getActivityCodesDictionary,
  getAddress,
  getAddressTypes,
  getAdressInfo,
  getAttribute,
  getAttributeGroupInfo,
  getAttributes,
  getAttributesGroups,
  getChannels,
  getClientGoals,
  getClientInfo,
  getClients,
  getCodes,
  getCodesInfo,
  getContact,
  getContactInfo,
  getContactStatus,
  getContactTypes,
  getCountryNames,
  getCurrency,
  getCustomer,
  getCustomerFullName,
  getCustomers,
  getCustomersById,
  getDocument,
  getDocumentInfo,
  getDocumentStatus,
  getDocumentTypes,
  getEstate,
  getEstatesTypes,
  getExtendedInfo,
  getFatcaInfo,
  getFullProfileCustomer,
  getFullProfileFoCustomer,
  getGenders,
  getGoalsInfo,
  getHouseTypes,
  getIdent,
  getIdentInfo,
  getIncomeInfo,
  getLegalCustomer,
  getLegalCustomers,
  getLegalCustomersById,
  getMonthlyIncome,
  getOwnershipInfo,
  getOwnershipTypes,
  getProvenanceSource,
  getQuarterlyIncome,
  getRandDirectory,
  getRegions,
  getRelationship,
  getRelationshipRoles,
  getRelationshipsInfo,
  getResponsibleManagers,
  getRestrCriteria,
  getRestriction,
  getRestrictionInfo,
  getRestrSources,
  getRestrTypes,
  getRoomTypes,
  getSecurity,
  getSecurityTypes,
  getSegments,
  getSelectedIncomeInfo,
  getSettlements,
  getSettlementTypes,
  getSources,
  getStreetTypes,
  getSubTypes,
  getVehicle,
  getVehiclesBrands,
  getVehiclesGroups,
  getVehiclesTypes,
  legalClientsFilter,
  resetBlocks,
  saveClient,
  setClientId,
  setEditing,
  setGroupName,
  setIsMobileMenuOpened,
  setLegalClientId,
  setOpenModal,
  setQueryValue,
  setStatus,
  showClientStatusBlocks,
  showPhoneStatusBlocks,
  updateActivityCode,
  updateAddress,
  updateAttribute,
  updateContact,
  updateCustomer,
  updateDocument,
  updateEstate,
  updateFatca,
  updateIdentification,
  updateIncome,
  updateLegalCustomer,
  updateRelationship,
  updateSecurity,
  updateVehicle
} from './actions';

const clients = reducer(new ClientsState())
  .on(setClientId, (state, clientsID) => {
    state.clientsID = clientsID;
  })

  .on(setLegalClientId, (state, legalClientsID) => {
    state.legalClientsID = legalClientsID;
  })

  .on(getCustomers.success, (state, clients) => {
    state.clients = clients;
  })

  .on(getRandDirectory.success, (state, random) => {
    state.random = random;
  })
  .on(getOwnershipTypes.success, (state, ownershipTypes) => {
    state.ownershipTypes = ownershipTypes;
  })
  .on(getCustomersById.success, (state, clients) => {
    state.clients = clients;
  })
  .on(getProvenanceSource.success, (state, provenanceSource) => {
    state.provenanceSource = provenanceSource;
  })
  .on(getMonthlyIncome.success, (state, monthlyIncome) => {
    state.monthlyIncome = monthlyIncome;
  })
  .on(getQuarterlyIncome.success, (state, quarterlyIncome) => {
    state.quarterlyIncome = quarterlyIncome;
  })
  .on(getLegalCustomersById.success, (state, legalEntityClients) => {
    state.legalEntityClients = legalEntityClients;
  })
  .on(getLegalCustomers.success, (state, legalEntityClients) => {
    state.legalEntityClients = legalEntityClients;
  })
  .on(getClientGoals.success, (state, clientGoals) => {
    state.clientGoals = clientGoals;
  })
  .on(getCustomerFullName.success, (state, fullName) => {
    state.fullName = fullName;
  })
  .on(getRestrSources.success, (state, restrSources) => {
    state.restrSources = restrSources;
  })
  .on(getCustomer.success, (state, client) => {
    state.client = client;
  })
  .on(updateCustomer.success, (state, client) => {
    state.client = client;
  })
  .on(getRelationshipRoles.success, (state, relationshipRoles) => {
    state.relationshipRoles = relationshipRoles;
  })
  .on(getGenders.success, (state, genders) => {
    state.genders = genders;
  })
  .on(updateLegalCustomer.success, (state, client) => {
    state.client = client;
  })
  .on(getActivityCodesDictionary.success, (state, activCodes) => {
    state.activCodes = activCodes;
  })
  .on(getLegalCustomer.success, (state, client) => {
    state.client = client;
  })
  .on(getFullProfileCustomer.success, (state, fullClient) => {
    state.fullClient = fullClient;
  })
  .on(getFullProfileFoCustomer.success, (state, fullClient) => {
    state.fullClient = fullClient;
  })
  .on(getExtendedInfo.success, (state, extendedCustomer) => {
    state.extendedCustomer = extendedCustomer;
  })
  .on(getContactInfo.success, (state, contact) => {
    state.contact = contact;
  })
  .on(getContact.success, (state, selectedContact) => {
    state.selectedContact = selectedContact;
  })
  .on(updateContact.success, (state, selectedContact) => {
    state.selectedContact = selectedContact;
  })
  .on(getSubTypes.success, (state, subTypes) => {
    state.subTypes = subTypes;
  })
  .on(updateDocument.success, (state, selectedDocument) => {
    state.selectedDocument = selectedDocument;
  })

  .on(getDocument.success, (state, selectedDocument) => {
    state.selectedDocument = selectedDocument;
  })

  .on(getDocumentInfo.success, (state, document) => {
    state.document = document;
  })
  .on(getAdressInfo.success, (state, adress) => {
    state.adress = adress;
  })
  .on(getAddress.success, (state, selectedAddress) => {
    state.selectedAddress = selectedAddress;
  })
  .on(getAttributesGroups.success, (state, attributesGroups) => {
    state.attributesGroups = attributesGroups;
  })
  .on(updateAddress.success, (state, selectedAddress) => {
    state.selectedAddress = selectedAddress;
  })

  .on(getIdentInfo.success, (state, ident) => {
    state.ident = ident;
  })
  .on(getIdent.success, (state, selectedIdent) => {
    state.selectedIdent = selectedIdent;
  })
  .on(updateIdentification.success, (state, selectedIdent) => {
    state.selectedIdent = selectedIdent;
  })
  .on(getSecurityTypes.success, (state, securityTypes) => {
    state.securityTypes = securityTypes;
  })
  .on(getRestrTypes.success, (state, restrTypes) => {
    state.restrTypes = restrTypes;
  })

  .on(getFatcaInfo.success, (state, fatca) => {
    state.fatca = fatca;
  })
  .on(updateFatca.success, (state, fatca) => {
    state.fatca = fatca;
  })
  .on(getOwnershipInfo.success, (state, ownership) => {
    state.ownership = ownership;
  })
  .on(getRestrCriteria.success, (state, restrCriteria) => {
    state.restrCriteria = restrCriteria;
  })
  .on(getVehicle.success, (state, selectedVehicle) => {
    state.selectedVehicle = selectedVehicle;
  })
  .on(updateVehicle.success, (state, selectedVehicle) => {
    state.selectedVehicle = selectedVehicle;
  })
  .on(getEstate.success, (state, selectedEstate) => {
    state.selectedEstate = selectedEstate;
  })
  .on(updateEstate.success, (state, selectedEstate) => {
    state.selectedEstate = selectedEstate;
  })
  .on(getSecurity.success, (state, selectedSecurity) => {
    state.selectedSecurity = selectedSecurity;
  })
  .on(updateSecurity.success, (state, selectedSecurity) => {
    state.selectedSecurity = selectedSecurity;
  })

  .on(getIncomeInfo.success, (state, sources) => {
    state.sources = sources;
  })
  .on(getSelectedIncomeInfo.success, (state, selectedSource) => {
    state.selectedSource = selectedSource;
  })
  .on(updateIncome.success, (state, selectedSource) => {
    state.selectedSource = selectedSource;
  })
  .on(getRelationshipsInfo.success, (state, relationships) => {
    state.relationships = relationships;
  })
  .on(getRelationship.success, (state, selectedRelationship) => {
    state.selectedRelationship = selectedRelationship;
  })
  .on(updateRelationship.success, (state, selectedRelationship) => {
    state.selectedRelationship = selectedRelationship;
  })
  .on(getAttributeGroupInfo.success, (state, attributeGroups) => {
    state.attributeGroups = attributeGroups;
  })
  .on(getAttributes.success, (state, attributes) => {
    state.attributes = attributes;
  })
  .on(getAttribute.success, (state, attribute) => {
    state.attribute = attribute;
  })
  .on(updateAttribute.success, (state, attribute) => {
    state.attribute = attribute;
  })
  .on(getCodesInfo.success, (state, activityCode) => {
    state.activityCode = activityCode;
  })
  .on(updateActivityCode.success, (state, activityCode) => {
    state.activityCode = activityCode;
  })
  .on(getGoalsInfo.success, (state, goals) => {
    state.goals = goals;
  })
  .on(getRestrictionInfo.success, (state, restrictions) => {
    state.restrictions = restrictions;
  })
  .on(getRoomTypes.success, (state, roomTypes) => {
    state.roomTypes = roomTypes;
  })
  .on(getRestriction.success, (state, selectedRestriction) => {
    state.selectedRestriction = selectedRestriction;
  })

  .on(getClients.success, (state, clients) => {
    state.clients = clients;
  })
  // .on(getClient.success, (state, client) => {
  //   state.client = client;
  // })
  .on(saveClient.success, (state, client) => {
    state.client = client;
    state.editing = false;
  })
  .on(
    getClientInfo.success,
    (state, { clientGroups, channels, blockReasons }) => {
      state.clientGroups = clientGroups;
      state.registrationChannels = channels;
      state.blockReasons = blockReasons;
    }
  )
  .on(addClient.success, (state, clientId) => {
    state.recentlyAddedClientId = clientId;
  })
  .on(deleteRecentlyAddedId, (state) => {
    state.recentlyAddedClientId = null;
  })
  .on(showPhoneStatusBlocks, (state, isShow) => {
    state.isShowPhoneBlocks = isShow;
  })
  .on(showClientStatusBlocks, (state, isShow) => {
    state.isShowClientBlocks = isShow;
  })
  .on(changeFilter.save, (state, { filters }) => {
    state.filters = filters;
  })
  .on(changeBlock, ({ sections }, index) => {
    sections[index] = {
      ...sections[index],
      isOpened: !sections[index].isOpened
    };
  })
  .on(resetBlocks, (state) => {
    state.sections.forEach((block) => {
      block.isOpened = false;
    });
  })
  .on(setIsMobileMenuOpened, (state) => {
    state.isMobileMenuOpened = !state.isMobileMenuOpened;
  })
  .on(changeNav, (state, navbar) => {
    state.navList = navbar;
  })
  .on(setEditing, (state, editing) => {
    state.editing = editing;
  })
  .on(setStatus, (state, contStatus) => {
    state.contStatus = contStatus;
  })
  .on(clientsFilter.success, (state, clients) => {
    state.clients = clients;
  })
  .on(legalClientsFilter.success, (state, legalEntityClients) => {
    state.legalEntityClients = legalEntityClients;
  })
  .on(changeActiveTab, (state, activeTab) => {
    state.activeTab = activeTab;
  })
  .on(setOpenModal, (state, isOpenModal) => {
    state.isOpenModal = isOpenModal;
  })
  .on(getContactTypes.success, (state, contactTypes) => {
    state.contactTypes = contactTypes;
  })

  .on(getCountryNames, (state) => {
    state.countries = [];
    state.countriesPending = true;
  })
  .on(getCountryNames.success, (state, countries) => {
    state.countries = countries;
  })
  .on([getCountryNames.success, getCountryNames.fail], (state) => {
    state.countriesPending = false;
  })

  .on(getAddressTypes.success, (state, addressTypes) => {
    state.addressTypes = addressTypes;
  })
  .on(getVehiclesBrands.success, (state, vehiclesBrands) => {
    state.vehiclesBrands = vehiclesBrands;
  })

  .on(getStreetTypes.success, (state, streetTypes) => {
    state.streetTypes = streetTypes;
  })
  .on(getVehiclesTypes.success, (state, vehiclesTypes) => {
    state.vehiclesTypes = vehiclesTypes;
  })
  .on(getCurrency.success, (state, currency) => {
    state.currency = currency;
  })
  .on(getEstatesTypes.success, (state, estatesTypes) => {
    state.estatesTypes = estatesTypes;
  })
  .on(getHouseTypes.success, (state, houseTypes) => {
    state.houseTypes = houseTypes;
  })

  .on(getRegions, (state) => {
    state.regions = [];
    state.regionsPending = true;
  })
  .on(getRegions.success, (state, regions) => {
    state.regions = regions;
  })
  .on([getRegions.success, getRegions.fail], (state) => {
    state.regionsPending = false;
  })

  .on(getSegments.success, (state, segments) => {
    state.segments = segments;
  })
  .on(getDocumentTypes.success, (state, documentTypes) => {
    state.documentTypes = documentTypes;
  })
  .on(getDocumentStatus.success, (state, documentStatus) => {
    state.documentStatus = documentStatus;
  })
  .on(getVehiclesGroups.success, (state, vehiclesGroups) => {
    state.vehiclesGroups = vehiclesGroups;
  })
  .on(getCodes.success, (state, warningCodes) => {
    state.warningCodes = warningCodes;
  })
  .on(getSources.success, (state, incomesSources) => {
    state.incomesSources = incomesSources;
  })
  .on(getChannels.success, (state, channels) => {
    state.channels = channels;
  })
  .on(getSettlementTypes.success, (state, settlementTypes) => {
    state.settlementTypes = settlementTypes;
  })
  .on(getContactStatus.success, (state, contactStatus) => {
    state.contactStatus = contactStatus;
  })

  .on(getSettlements, (state) => {
    state.settlements = [];
    state.settlementsPending = true;
  })
  .on(getSettlements.success, (state, settlements) => {
    state.settlements = settlements;
  })
  .on([getSettlements.success, getSettlements.fail], (state) => {
    state.settlementsPending = false;
  })

  .on(setQueryValue, (state, queryValue) => {
    state.queryValue = queryValue;
  })
  .on(setGroupName, (state, groupName) => {
    state.groupName = groupName;
  })
  .on(getResponsibleManagers, (state) => {
    state.responsibleManagers = [];
  })
  .on(getResponsibleManagers.success, (state, { managers }) => {
    state.responsibleManagers = managers;
  });

export { clients };
