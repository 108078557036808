import { reducer } from 'redux-chill';
import { ToastState } from './state';
import { show } from './actions';

const toast = reducer(new ToastState())
  .on(show.start, (state, options) => {
    if (state.toasts.length == 0) state.toasts.push(options);
  })
  .on(show.end, (state, id) => {
    state.toasts = state.toasts.filter((item) => item.id != id);
  });

export { toast };
