import { useMediaPoints } from '@core';
import { useDispatch } from 'react-redux';
import { setIsMobileMenuOpened } from '@clients/store';
import { useTypedSelector } from '@shared/lib/use-typed-selector';

const useNavbar = () => {
  const dispatch = useDispatch();

  const { isTabletUpOrEqual } = useMediaPoints();

  const { isMobileMenuOpened } = useTypedSelector((state) => state.clients);

  const onMenuClick = () => {
    dispatch(setIsMobileMenuOpened());
  };

  return {
    onMenuClick,
    isMobileMenuOpened,
    isTabletUpOrEqual
  };
};

export { useNavbar };
