import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

import { hoc } from '@core';
import { MainLayout } from '@app';

import { Role, RoleAccess } from '@entities/role';

import { EditClient, LegalEntityClientsList } from './pages';
import { useClients } from './clients.props';
import styles from './clients.module.scss';

const LegalEntityClients = hoc(useClients, ({ match }) => (
  <MainLayout
    mainPartClassName={styles.mainPartLayout}
    className={styles.layout}
    basePath={match.path}
    withRecentlyAddedClientAlert
  >
    <RoleAccess
      role={Role.LegalEntityAccess}
      fallback={
        <Grid container alignItems='center' justifyContent='center' flex={1}>
          <Typography variant='h4'>Недостатньо доступу</Typography>
        </Grid>
      }
    >
      <Switch>
        <Route exact path={match.path}>
          <LegalEntityClientsList />
        </Route>

        <Route
          exact
          path={`${match.path}/:clientId/edit`}
          component={EditClient}
        />
      </Switch>
    </RoleAccess>
  </MainLayout>
));

export { LegalEntityClients };
