import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const PortalWrapper: React.FC = ({ children }) => {
  const [element] = useState(() => document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(element);

    return () => {
      document.body.removeChild(element);
    };
  }, []);

  return ReactDOM.createPortal(children, element);
};

export { PortalWrapper };
