const hiddenPaths = ['edit', 'matrix'];

const placeholders = {
  clients: 'Пошук по номеру телефону'
};

const noResultsText = {
  clients: 'За таким номером клієнтів не знайдено'
};

export { hiddenPaths, placeholders, noResultsText };
