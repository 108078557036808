import { withField } from '@core/form';
import { Input } from '../input';
import { Select } from '../select';
import { DatePicker } from '../date-picker';
import { NewSelectForm } from '../new-select';
import { Autocomplete } from '../autocomplete';
import { NewInputForm, NewTextAreaForm } from '../new-text-field';
import { NewSwitch } from '../new-switch/new-switch.component';
import { NewMultipleSelect } from '../new-multiple-select/new-multiple-select.component';

/**
 * <Field.X />
 */
const Field = {
  Input: withField(Input),
  NewSwitch: withField(NewSwitch),
  NewInput: withField(NewInputForm),
  NewTextArea: withField(NewTextAreaForm),
  Select: withField(Select),
  Date: withField(DatePicker),
  Autocomplete: withField(Autocomplete),
  NewSelect: withField(NewSelectForm),
  NewMultipleSelect: withField(NewMultipleSelect)
};

export { Field };
