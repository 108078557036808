class ToastOptions {
  public id: string;
  public duration?: number;
  public message: string | JSX.Element;
  public type?: 'info' | 'success' | 'warning' | 'error';
}

class ToastState {
  /**
   * Toasts list
   */
  public toasts: ToastOptions[] = [];
}

export { ToastState, ToastOptions };
