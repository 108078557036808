import React from 'react';
import { FilterSectionWrapperProps } from './filter-section-wrapper.props';
import styles from './filter-section-wrapper.module.scss';

const FilterSectionWrapper: React.FC<FilterSectionWrapperProps> = ({
  children,
  header
}) => (
  <section className={styles.section}>
    <div className={styles.header}>{header}</div>

    {children}
  </section>
);

export { FilterSectionWrapper };
