import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getActivityCodesDictionary, getCodesInfo } from '@clients/store';
import { useClientId } from '@clients/pages/edit-client/hooks';
import { useLocation } from 'react-router';
import { useTypedSelector } from '@shared/lib/use-typed-selector';

const useActivityCodesProps = () => {
  const dispatch = useDispatch();
  const clientId = useClientId();
  const { pathname } = useLocation();
  const { client } = useTypedSelector((state) => state.clients);
  const { activCodes } = useTypedSelector((state) => state.clients);

  useEffect(() => {
    client.haveIndividualEntrepreneur && dispatch(getCodesInfo(clientId));
    dispatch(getActivityCodesDictionary(''));
  }, [pathname]);

  return {
    names: activCodes
  };
};

export { useActivityCodesProps };
