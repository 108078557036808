import React from 'react';
import { FormGroup } from '@mui/material';
import { hoc } from '@core';
import { Field } from '@core/components';

import { useFatcaProps } from './fatca-info.props';
import styles from './fatca.module.scss';

import { SectionLayout } from '../atoms';
import { Role } from '@entities/role';

const FatcaInformation = hoc(useFatcaProps, ({ disabled }) => (
  <SectionLayout read={Role.FatcaRead} update={Role.FatcaUpdate}>
    {({ canUpdate }) => (
      <>
        <FormGroup>
          <Field.NewSwitch
            name='isCitizenUsa'
            label='Має громадянство США'
            disabled={!canUpdate}
          />
          <Field.NewSwitch
            name='isBornUsa'
            label='Народжений в США'
            disabled={!canUpdate}
          />

          <Field.NewSwitch
            name='isTaxpayerUsa'
            label='Платник податків'
            disabled={!canUpdate}
          />

          <Field.NewSwitch
            name='isRegistrationUsa'
            label='Зареєстрований в  США'
            disabled={!canUpdate}
          />
        </FormGroup>

        <p>
          <Field.Input
            className={styles.input}
            theme='primary'
            name='commentNoCitizenshipUsa'
            label='Чому немає громадянства'
            disabled={!disabled}
            editable={canUpdate}
          />

          <Field.Input
            className={styles.input}
            theme='primary'
            name='commentNotTaxpayerUsa'
            label='Реєстрація в США'
            disabled={!disabled}
            editable={canUpdate}
          />
        </p>
      </>
    )}
  </SectionLayout>
));

export { FatcaInformation };
