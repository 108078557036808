import React from 'react';
import classNames from 'classnames';
import { useSimpleClickOutside } from '@core/hooks';
import styles from './popup-wrapper.module.scss';

type PopupWrapperProps = {
  value: boolean;
  onClickFunction: () => void;
  className?: string;
  withDefaultStyles?: boolean;
};

const PopupWrapper: React.FC<PopupWrapperProps> = ({
  children,
  value,
  className,
  onClickFunction,
  withDefaultStyles
}) => {
  const ref = React.useRef();

  useSimpleClickOutside(ref, () => {
    if (value) onClickFunction();
  });

  return (
    <div
      className={classNames(withDefaultStyles ? styles.wrapper : className)}
      ref={ref}
    >
      {children}
    </div>
  );
};

PopupWrapper.defaultProps = {
  withDefaultStyles: true
};

export { PopupWrapper };
