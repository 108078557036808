import { make } from 'redux-chill';
import {
  Client,
  CommonResponseType,
  Filters,
  NavElement,
  TableFilter
} from '@api';
import {
  Adapter,
  ClientAddressResponse,
  ClientContactsResponseDto,
  ClientDocumentResponse,
  ClientEstatesResponseDto,
  ClientIdentHistoryResponseDto,
  ClientSecuritiesResponseDto,
  ClientVehiclesResponseDto,
  ShortExtendedCustomerInfoResponse,
  ShortLegalExtendedCustomerInfoResponse
} from '@shared/api/models';
import {
  AttributeGroupsResponseDto,
  ClientAdditionalInfoRequestDto,
  ClientAttributesResponseDto,
  ClientIncomesResponseDto,
  ClientRelationshipResponseDto,
  ClientRestrictionResponseDto,
  contactTypes,
  Countries,
  CreateCustomerRequestDto,
  CreateIndividualEntepreneurDto,
  FatcaResponseDto,
  Segments
} from '@shared/api/data-contracts';

/**
 * Get Clients
 */
const handleError = make('[error] get').stage((error) => error);

const getCustomers = make('[clients] get')
  .stage((data) => data)
  .stage('success', (clients: ShortExtendedCustomerInfoResponse[]) => clients);
const getCustomersById = make('[clientsById] get')
  .stage((data) => data)
  .stage('success', (clients: ShortExtendedCustomerInfoResponse[]) => clients);
const getLegalCustomersById = make('[legalClientsById] get')
  .stage((data) => data)
  .stage(
    'success',
    (legalEntityClients: ShortLegalExtendedCustomerInfoResponse[]) =>
      legalEntityClients
  );

const getLegalCustomers = make('[legalEntityClients] get')
  .stage((data) => data)
  .stage(
    'success',
    (legalEntityClients: ShortLegalExtendedCustomerInfoResponse[]) =>
      legalEntityClients
  );

const getClients = make('[clients] get all').stage(
  'success',
  (clients) => clients
);
const getLegalClients = make('[legalClients] get').stage(
  'success',
  (legalEntityClients) => legalEntityClients
);

const clientsFilter = make('[clients] serch')
  .stage((data) => data)
  .stage('success', (clients: ShortExtendedCustomerInfoResponse[]) => clients);
const legalClientsFilter = make('[legalClientsFilter] search')
  .stage((data) => data)
  .stage(
    'success',
    (legalEntityClients: ShortLegalExtendedCustomerInfoResponse[]) =>
      legalEntityClients
  );

/**
 * Get Client
 */
const getClient = make('[client] get')
  .stage((clientId: Client['clientId']) => clientId)
  .stage('success', (client: Client) => client);
const getCustomer = make('[client] get')
  .stage((clientId: ShortExtendedCustomerInfoResponse['id']) => clientId)
  .stage('success', (client: ShortExtendedCustomerInfoResponse) => client);
const getCustomerFullName = make('[fullName] get')
  .stage((clientId: ShortExtendedCustomerInfoResponse['id']) => clientId)
  .stage('success', (fullName) => fullName);
const getRandDirectory = make('[randDirectory] get')
  .stage((clientId: ShortExtendedCustomerInfoResponse['id']) => clientId)
  .stage('success', (random: Segments[]) => random);

const updateCustomer = make('[customer] put')
  .stage((data) => data)
  .stage('success', (client: ShortExtendedCustomerInfoResponse) => client);
const updateLegalCustomer = make('[legalCustomer] put')
  .stage((data) => data)
  .stage('success', (client: ShortExtendedCustomerInfoResponse) => client);
const getFullProfileCustomer = make('[legalClientFull] get')
  .stage((clientId: ShortExtendedCustomerInfoResponse['id']) => clientId)
  .stage(
    'success',
    (fullClient: ShortExtendedCustomerInfoResponse) => fullClient
  );
const getFullProfileFoCustomer = make('[clientFull] get')
  .stage((clientId: ShortExtendedCustomerInfoResponse['id']) => clientId)
  .stage(
    'success',
    (fullClient: ShortExtendedCustomerInfoResponse) => fullClient
  );

const getLegalCustomer = make('[legalClient] get')
  .stage((clientId: ShortExtendedCustomerInfoResponse['id']) => clientId)
  .stage('success', (client: ShortExtendedCustomerInfoResponse) => client);

const getExtendedInfo = make('[client] getExtendedInfo')
  //.stage((clientId: UpdateCustomerRequestDto['inn']) => clientId)
  .stage(
    'success',
    (extendedCustomer: ClientAdditionalInfoRequestDto) => extendedCustomer
  );
const getContactInfo = make('[contact] get')
  .stage((clientId: ClientContactsResponseDto['id']) => clientId)
  .stage('success', (contact: ClientContactsResponseDto[]) => contact);

const getContact = make('[selectedContact] get')
  .stage((contactId: ClientContactsResponseDto['id']) => contactId)
  .stage(
    'success',
    (selectedContact: ClientContactsResponseDto) => selectedContact
  );

const updateContact = make('[selectedContact] put')
  .stage((data) => data)
  .stage(
    'success',
    (selectedContact: ClientContactsResponseDto) => selectedContact
  );

const getDocumentInfo = make('[document] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (document: ClientDocumentResponse[]) => document);
const getContactTypes = make('[types] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (contactTypes: contactTypes[]) => contactTypes);
const getGenders = make('[genders] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (genders) => genders);
const getContactStatus = make('[status] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (contactStatus: contactTypes[]) => contactStatus);
const getGoals = make('[goalsNames] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (goalsNames) => goalsNames);

const getCountryNames = make('[countries] get')
  .stage('success', (countries: Countries[]) => countries)
  .stage('fail');
const getSegments = make('[segments] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (segments: Segments[]) => segments);
const getChannels = make('[channels] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (channels: Segments[]) => channels);
const getDocumentTypes = make('[documentTypes] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (documentTypes: Segments[]) => documentTypes);
const getRestrTypes = make('[restrTypes] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (restrTypes: Segments[]) => restrTypes);

const getSettlements = make('[settlements] get')
  .stage((regionId: string) => regionId)
  .stage('success', (settlements: Segments[]) => settlements)
  .stage('fail');

const getSettlementTypes = make('[settlmentTypes] get').stage(
  'success',
  (settlementTypes: Segments[]) => settlementTypes
);
const getDocumentStatus = make('[documentStatus] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (documentStatus: Segments[]) => documentStatus);
const getAddressTypes = make('[addressTypes] get').stage(
  'success',
  (addressTypes: Segments[]) => addressTypes
);
const getCodes = make('[warningCodes] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (warningCodes: Segments[]) => warningCodes);
const getSources = make('[sourcesCodes] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (incomesSources: Segments[]) => incomesSources);

const getStreetTypes = make('[streetTypess] get').stage(
  'success',
  (streetTypes: Segments[]) => streetTypes
);
const getAttributesGroups = make('[atribGroups] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (attributesGroups: Segments[]) => attributesGroups);
const getActivityCodesDictionary = make('[activityCodesDictionary] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (activCodes: Segments[]) => activCodes);

const getHouseTypes = make('[houseTypes] get').stage(
  'success',
  (houseTypes: Segments[]) => houseTypes
);

const getRoomTypes = make('[roomTypes] get').stage(
  'success',
  (roomTypes: Segments[]) => roomTypes
);

const getEstatesTypes = make('[estatesTypes] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (estatesTypes: Segments[]) => estatesTypes);
const getCurrency = make('[currency] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (currency: Segments[]) => currency);
const getVehiclesTypes = make('[vehiclesTypes] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (vehiclesTypes: Segments[]) => vehiclesTypes);
const getVehiclesGroups = make('[vehiclesGroups] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (vehiclesGroups: Segments[]) => vehiclesGroups);
const getVehiclesBrands = make('[vehiclesBrands] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (vehiclesBrands: Segments[]) => vehiclesBrands);
const getSecurityTypes = make('[securityTypes] get')
  .stage((clientId: ClientDocumentResponse['id']) => clientId)
  .stage('success', (securityTypes: Segments[]) => securityTypes);

const getRegions = make('[regions] get')
  .stage((countryId: string) => countryId)
  .stage('success', (regions: Segments[]) => regions)
  .stage('fail');

const getDocument = make('[selectedDocument] get')
  .stage((documentId: ClientDocumentResponse['id']) => documentId)
  .stage(
    'success',
    (selectedDocument: ClientDocumentResponse) => selectedDocument
  );
const updateDocument = make('[selectedDocument] put')
  .stage((data) => data)
  .stage(
    'success',
    (selectedDocument: ClientDocumentResponse) => selectedDocument
  );
const getAdressInfo = make('[adress] get')
  .stage((clientId: ClientAddressResponse['id']) => clientId)
  .stage('success', (document: ClientAddressResponse[]) => document);
const getAddress = make('[selectedAddress] get')
  .stage((addressId: ClientAddressResponse['id']) => addressId)
  .stage(
    'success',
    (selectedAddress: ClientAddressResponse) => selectedAddress
  );
const updateAddress = make('[selectedAddress] put')
  .stage((data) => data)
  .stage(
    'success',
    (selectedAddress: ClientAddressResponse) => selectedAddress
  );

const getIdentInfo = make('[ident] get')
  .stage((clientId: ClientIdentHistoryResponseDto['id']) => clientId)
  .stage('success', (ident: ClientIdentHistoryResponseDto[]) => ident);

const getIdent = make('[selectedIdent] get')
  .stage((identId: ClientIdentHistoryResponseDto['id']) => identId)
  .stage(
    'success',
    (selectedIdent: ClientIdentHistoryResponseDto) => selectedIdent
  );

const getFatcaInfo = make('[fatca] get')
  .stage((clientId: ShortExtendedCustomerInfoResponse['id']) => clientId)
  .stage('success', (fatca: FatcaResponseDto) => fatca);

const getOwnershipInfo = make('[estates] get')
  .stage((clientId: ShortExtendedCustomerInfoResponse['id']) => clientId)
  .stage('success', (ownership: Adapter) => ownership);
const updateVehicle = make('[selectedVehicle] put')
  .stage((data) => data)
  .stage(
    'success',
    (selectedVehicle: ClientVehiclesResponseDto) => selectedVehicle
  );
const updateEstate = make('[selectedEstate] put')
  .stage((data) => data)
  .stage(
    'success',
    (selectedEstate: ClientEstatesResponseDto) => selectedEstate
  );
const updateSecurity = make('[selectedSecurity] put')
  .stage((data) => data)
  .stage(
    'success',
    (selectedSecurity: ClientSecuritiesResponseDto) => selectedSecurity
  );

const getVehicle = make('[selectedVehicle] get')
  .stage((vehicleId: ClientVehiclesResponseDto['id']) => vehicleId)
  .stage(
    'success',
    (selectedVehicle: ClientVehiclesResponseDto) => selectedVehicle
  );

const getSecurity = make('[selectedSecurity] get')
  .stage((securityId: ClientSecuritiesResponseDto['id']) => securityId)
  .stage(
    'success',
    (selectedSecurity: ClientSecuritiesResponseDto) => selectedSecurity
  );
const getEstate = make('[selectedEstate] get')
  .stage((estateId: ClientEstatesResponseDto['id']) => estateId)
  .stage(
    'success',
    (selectedEstate: ClientEstatesResponseDto) => selectedEstate
  );

const getIncomeInfo = make('[sources] get')
  .stage((clientId: ShortExtendedCustomerInfoResponse['id']) => clientId)
  .stage('success', (sources: ClientIncomesResponseDto) => sources);
const getSelectedIncomeInfo = make('[selectedSource] get')
  .stage((incomeId: ClientIncomesResponseDto['id']) => incomeId)
  .stage(
    'success',
    (selectedSource: ClientIncomesResponseDto) => selectedSource
  );

const getRelationshipsInfo = make('[relationships] get')
  .stage((clientId: ShortExtendedCustomerInfoResponse['id']) => clientId)
  .stage(
    'success',
    (relationships: ClientRelationshipResponseDto[]) => relationships
  )
  .stage('failure')
  .stage('finish');
const getRelationship = make('[selectedRelationship] get')
  .stage(
    (relationshipId: ShortExtendedCustomerInfoResponse['id']) => relationshipId
  )
  .stage(
    'success',
    (selectedRelationship: ClientRelationshipResponseDto) =>
      selectedRelationship
  );

const updateRelationship = make('[selectedRelationship] put')
  .stage((data) => data)
  .stage(
    'success',
    (selectedRelationship: ClientRelationshipResponseDto) =>
      selectedRelationship
  );

const getAttributeGroupInfo = make('[attributeGroup] get')
  //.stage((clientId: FATCA['commentNoCitizenshipUSA']) => clientId)
  .stage(
    'success',
    (attributeGroups: AttributeGroupsResponseDto[]) => attributeGroups
  );
const getAttributes = make('[attributes] get')
  .stage((data) => data)
  .stage('success', (attributes: ClientAttributesResponseDto[]) => attributes);
const getAttribute = make('[attribute] get')
  .stage((attibuteId: ClientAttributesResponseDto['id']) => attibuteId)
  .stage('success', (attribute: ClientAttributesResponseDto) => attribute);
const updateAttribute = make('[attribute] put')
  .stage((data) => data)
  .stage('success', (attribute: ClientAttributesResponseDto) => attribute);
const updateIdentification = make('[identification] put')
  .stage((data) => data)
  .stage(
    'success',
    (selectedIdent: ClientIdentHistoryResponseDto) => selectedIdent
  );
const updateIncome = make('[income] put')
  .stage((data) => data)
  .stage(
    'success',
    (selectedSource: ClientIncomesResponseDto) => selectedSource
  );
const updateFatca = make('[fatca] put')
  .stage((data) => data)
  .stage('success', (fatca: FatcaResponseDto) => fatca);
const updateActivityCode = make('[activity] put')
  .stage((data) => data)
  .stage(
    'success',
    (activityCode: CreateIndividualEntepreneurDto) => activityCode
  );
const getCodesInfo = make('[activityCode] get')
  .stage((clientId: ShortExtendedCustomerInfoResponse['id']) => clientId)
  .stage(
    'success',
    (activityCode: CreateIndividualEntepreneurDto) => activityCode
  );

const getClientGoals = make('[clientGoals] get').stage(
  'success',
  (clientGoals: Segments[]) => clientGoals
);

const getOwnershipTypes = make('[ownershipTypes] get').stage(
  'success',
  (ownershipTypes: Segments[]) => ownershipTypes
);
const getProvenanceSource = make('[provenanceSource] get').stage(
  'success',
  (provenanceSource: Segments[]) => provenanceSource
);
const getMonthlyIncome = make('[monthlyIncome] get').stage(
  'success',
  (monthlyIncome: Segments[]) => monthlyIncome
);
const getQuarterlyIncome = make('[quarterlyIncome] get').stage(
  'success',
  (quarterlyIncome: Segments[]) => quarterlyIncome
);

const getRestrSources = make('[restrSources] get')
  .stage((clientId: ShortExtendedCustomerInfoResponse['id']) => clientId)
  .stage('success', (restrSources: Segments[]) => restrSources);
const getRestrCriteria = make('[restrCriteria] get')
  .stage((clientId: ShortExtendedCustomerInfoResponse['id']) => clientId)
  .stage('success', (restrCriteria: Segments[]) => restrCriteria);
const getSubTypes = make('[subTypes] get').stage(
  'success',
  (subTypes: Segments[]) => subTypes
);

const getRelationshipRoles = make('[relationshipRoles] get')
  .stage((clientId: ShortExtendedCustomerInfoResponse['id']) => clientId)
  .stage('success', (relationshipRoles: Segments[]) => relationshipRoles);

const getGoalsInfo = make('[goals] get')
  //.stage((clientId: FATCA['commentNoCitizenshipUSA']) => clientId)
  .stage('success', (goals: CreateCustomerRequestDto) => goals);
const getRestrictionInfo = make('[restrictions] get')
  .stage((clientId: ShortExtendedCustomerInfoResponse['id']) => clientId)
  .stage(
    'success',
    (restrictions: ClientRestrictionResponseDto[]) => restrictions
  );
const getRestriction = make('[selectedRestriction] get')
  .stage((restrictionId: ClientRestrictionResponseDto['id']) => restrictionId)
  .stage(
    'success',
    (selectedRestriction: ClientRestrictionResponseDto) => selectedRestriction
  );
/**
 * Get client by phone
 */
const getClientsByPhone = make('[client] get by phone')
  .stage((phone: string) => phone)
  .stage('failure')
  .stage('finish');

/**
 * Get client groups
 */
const getClientInfo = make('[client] get client groups')
  .stage(
    'success',
    (
      clientGroups: CommonResponseType[],
      channels: CommonResponseType[],
      blockReasons: CommonResponseType[]
    ) => ({
      clientGroups,
      channels,
      blockReasons
    })
  )
  .stage('failure')
  .stage('finish');

/**
 * Set import file
 */
const setImportFile = make('[client] set import file').stage(
  (file: FormData) => file
);

/**
 * Get block
 */
const changeBlock = make('[general] find block').stage(
  (index: number) => index
);

/**
 * Change navbar
 */
const changeNav = make('[general] change nav').stage(
  (navbar: NavElement[]) => navbar
);

/**
 * Show phone status blocks
 */
const showPhoneStatusBlocks = make('[general] show phone status blocks').stage(
  (isShow: boolean) => isShow
);

/**
 * Show client status blocks
 */
const showClientStatusBlocks = make(
  '[general] show client status blocks'
).stage((isShow: boolean) => isShow);

/**
 * Set blocked status
 */
const setBlockedStatus = make('[general] set blocked status').stage(
  (phone: string) => phone
);

/**
 * Reset block
 */
const resetBlocks = make('[general] reset blocks');

/**
 * Save Client
 */
const saveClient = make('[client] save')
  .stage((client) => ({ client }))
  .stage('finish')
  .stage('success', (client) => client);

/**
 * Delete Client
 */
const deleteClient = make('[client] delete').stage(
  (id: Client['clientId']) => id
);

/**
 * Delete rrecently added id
 */
const deleteRecentlyAddedId = make('[client] delete recently id').stage(
  (id: Client['clientId']) => id
);

/**
 * Delete rrecently added id
 */
const setIsMobileMenuOpened = make('[client] open mobile menu');

/**
 * Add Client
 */
const addClient = make('[client] add')
  .stage((data) => data)
  .stage('success', (clientId: Client['clientId']) => clientId);

/**
 * Change Client Filter
 */
const changeFilter = make('[general] change filter')
  .stage((field: string) => ({ field }))
  .stage('save', (filters: TableFilter<Filters>) => ({ filters }));

/**
 * Set Editing
 */
const setEditing = make('[clients] set editing').stage(
  (editing: boolean) => editing
);
const setStatus = make('[contStatus] set ').stage(
  (contStatus: string) => contStatus
);

/**
 * Change active tab
 */
const changeActiveTab = make('[clients] change active tab').stage(
  (activeTab: string) => activeTab
);
const setOpenModal = make('[clients] change modal').stage(
  (isOpenModal: boolean) => isOpenModal
);
const setQueryValue = make('[queryValue]').stage(
  (queryValue: string) => queryValue
);
const setGroupName = make('[groupName]').stage((groupName) => groupName);

const setClientId = make('[clientsID]').stage((clientsID) => ({ clientsID }));
const setLegalClientId = make('[legalClientsID]').stage((legalClientsID) => ({
  legalClientsID
}));

const getResponsibleManagers = make('[clients] get responsible manager').stage(
  'success',
  (managers: { label: string; value: string }[]) => ({ managers })
);

export {
  getGoals,
  setGroupName,
  getRandDirectory,
  getCustomerFullName,
  getMonthlyIncome,
  getQuarterlyIncome,
  getProvenanceSource,
  getClientGoals,
  getGenders,
  getSubTypes,
  getOwnershipTypes,
  getRestrCriteria,
  getRestrSources,
  getRestrTypes,
  getRelationshipRoles,
  getActivityCodesDictionary,
  getAttributesGroups,
  getRoomTypes,
  getSecurityTypes,
  getVehiclesBrands,
  getVehiclesGroups,
  getVehiclesTypes,
  getCurrency,
  getEstatesTypes,
  getHouseTypes,
  getStreetTypes,
  getSources,
  getCodes,
  getSettlements,
  getSettlementTypes,
  getRegions,
  getAddressTypes,
  setStatus,
  getDocumentTypes,
  getChannels,
  getSegments,
  getCountryNames,
  getContactStatus,
  getContactTypes,
  updateLegalCustomer,
  updateCustomer,
  updateFatca,
  updateActivityCode,
  updateIncome,
  updateIdentification,
  updateAttribute,
  getAttribute,
  getAttributes,
  getFullProfileFoCustomer,
  getRestriction,
  updateRelationship,
  getRelationship,
  getFullProfileCustomer,
  getLegalCustomer,
  getSelectedIncomeInfo,
  setLegalClientId,
  getLegalCustomersById,
  getCustomersById,
  setClientId,
  legalClientsFilter,
  setQueryValue,
  getLegalClients,
  getLegalCustomers,
  updateEstate,
  updateSecurity,
  updateVehicle,
  updateAddress,
  updateDocument,
  updateContact,
  getEstate,
  getSecurity,
  getVehicle,
  getIdent,
  getAddress,
  getDocument,
  getContact,
  setOpenModal,
  getRestrictionInfo,
  getGoalsInfo,
  getCodesInfo,
  getAttributeGroupInfo,
  getRelationshipsInfo,
  getIncomeInfo,
  changeActiveTab,
  getOwnershipInfo,
  getExtendedInfo,
  handleError,
  getFatcaInfo,
  getIdentInfo,
  getCustomers,
  getCustomer,
  getContactInfo,
  getDocumentInfo,
  getAdressInfo,
  getClients,
  getClient,
  setEditing,
  changeFilter,
  changeBlock,
  resetBlocks,
  saveClient,
  changeNav,
  deleteClient,
  addClient,
  getClientsByPhone,
  deleteRecentlyAddedId,
  getClientInfo,
  setImportFile,
  setIsMobileMenuOpened,
  setBlockedStatus,
  showClientStatusBlocks,
  showPhoneStatusBlocks,
  clientsFilter,
  getDocumentStatus,
  getResponsibleManagers
};
