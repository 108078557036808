import { useSelector } from 'react-redux';
import { State } from '@store';
import { $$role, Role } from '@entities/role';
import { routes } from '@shared/config';

const useAppProps = () => {
  const { ready, authorized, loading } = useSelector(
    (state: State) => state.general
  );

  const nearestAccessiblePath = useNearestAccessPath();

  return {
    ready,
    authorized,
    loading,
    nearestAccessiblePath
  };
};

const useNearestAccessPath = () =>
  [
    { path: '/clients/fo', role: Role.PhysicalsAccess },
    { path: '/clients/legal-entity', role: Role.LegalEntityAccess },
    { path: routes.directories(), role: Role.AdminDirectoriesAccess }
  ]
    .map(({ role, path }) => {
      const canAccess = $$role.useHasRole(role);

      return { path, canAccess };
    })
    .find(({ canAccess }) => canAccess)?.path || '/clients/fo';

export { useAppProps };
