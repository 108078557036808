import React from 'react';
import { components, PlaceholderProps } from 'react-select';
import classNames from 'classnames';
import styles from './placeholder.module.scss';

const Placeholder: React.FC<PlaceholderProps<any>> = ({
  children,
  ...props
}) => (
  <components.Placeholder
    {...props}
    className={classNames(props.className, styles.placeholder)}
  >
    {children}
  </components.Placeholder>
);

export { Placeholder };
