const weekdaysShort = [
  'неділя',
  'понеділок',
  'вівторок',
  'середа',
  'четвер',
  'п’ятниця',
  'субота'
].map((day) => day.charAt(0));

const months = [
  'Січ.',
  'Лют.',
  'Бер.',
  'Кві.',
  'Тра.',
  'Чер.',
  'Лип.',
  'Сер.',
  'Вер.',
  'Жов.',
  'Лис.',
  'Гру.'
];

export { weekdaysShort, months };
