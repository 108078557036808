import { SingleSelectOption } from '@api';
import { fileOption } from './new-select.constants';

const isEqualFileOption = (option: SingleSelectOption) =>
  option.value === fileOption.value;

const isSingleSelectOption = <T>(value: any): value is SingleSelectOption<T> =>
  value?.value && value?.label;

const createOptionFromLabel = (label: string) => ({ label, value: label });

const createOption = <T>(label: string, value: T) => ({
  label,
  value
});

const createOptionFromEnum = <T>([value, label]: [T, string]) =>
  createOption<T>(label, value);

const createOptionsFromObject = <T extends string>(obj: Record<T, string>) =>
  Object.entries(obj).map((o: [T, string]) => createOptionFromEnum(o));

export {
  createOption,
  createOptionFromEnum,
  createOptionsFromObject,
  isEqualFileOption,
  isSingleSelectOption,
  createOptionFromLabel
};
