import { HttpService } from '@shared/lib/http';

class GeneralService {
  public constructor(private http: HttpService) {}

  public logout = () =>
    this.http.request<void>({
      url: 'api/v1/authentication/logout',
      method: 'POST'
    });
}

export { GeneralService };
