import { hoc } from '@core';
import { Field } from '@core/components';
import { columns, useContactsProps } from './contacts.props';
import styles from './contacts.module.scss';
import React from 'react';
import { FormGroup } from '@mui/material';
import { EditDataGrid } from '@shared/ui/edit-data-grid';
import { SectionLayout } from '../atoms';
import { Role } from '@entities/role';

const Contacts = hoc(
  useContactsProps,
  ({ identification, contactInfo, onOpen }) => (
    <SectionLayout read={Role.ContactsRead} update={Role.ContactsUpdate}>
      {({ canUpdate }) => (
        <EditDataGrid
          rows={identification}
          columns={columns}
          onReset={contactInfo.handleReset}
          form={contactInfo}
          onOpen={onOpen}
          onSubmit={contactInfo.handleSubmit}
          canUpdate={canUpdate}
        >
          <Field.Input
            className={styles.input}
            name='phoneType'
            theme='primary'
            label={'Тип'}
            editable={false}
          />

          <Field.Input
            name='value'
            label='Значення'
            className={styles.input}
            theme='primary'
            editable={canUpdate}
          />

          <Field.Input
            className={styles.input}
            name='statusId'
            theme='primary'
            label='Статус'
            editable={false}
          />

          <Field.Input
            className={styles.input}
            theme='primary'
            name='comment'
            label='Коментар'
            editable={canUpdate}
          />

          <FormGroup>
            <Field.NewSwitch
              name='isFinancial'
              label='Фінансовий'
              disabled={!canUpdate}
            />

            <Field.NewSwitch
              name='isService'
              label='Сервісний'
              disabled={!canUpdate}
            />
          </FormGroup>
        </EditDataGrid>
      )}
    </SectionLayout>
  )
);

export { Contacts };
