import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  getIncomeInfo,
  getMonthlyIncome,
  getProvenanceSource,
  getQuarterlyIncome,
  getSelectedIncomeInfo,
  getSources,
  saveClient,
  setEditing,
  updateIncome
} from '@clients/store';
import { useFormik } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import { useClientId } from '../../hooks';
import { useLocation } from 'react-router';
import { toSelectOption } from '../../lib';
import { useTypedSelector } from '@shared/lib/use-typed-selector';

const useIncomeProps = () => {
  const dispatch = useDispatch();

  const clientId = useClientId();
  const { pathname } = useLocation();

  const {
    quarterlyIncome,
    monthlyIncome,
    provenanceSource,
    incomesSources,
    sources
  } = useTypedSelector((state) => state.clients);

  useEffect(() => {
    dispatch(getIncomeInfo(clientId));
    dispatch(getSources(''));
    dispatch(getQuarterlyIncome(''));
    dispatch(getMonthlyIncome(''));
    dispatch(getProvenanceSource(''));
  }, [pathname]);

  const mergedIncomeSources = [
    ...(sources?.incomeSources ? sources.incomeSources : []),
    ...(sources?.incomeSourceAttribute
      ? sources?.incomeSourceAttribute.map((item) => ({
          id: item.id,
          title: item.value.data
        }))
      : [])
  ];

  const mergedProvenanceSources = [
    ...(sources?.provenanceSources ? sources.provenanceSources : []),
    ...(sources?.provenanceSourceAttribute
      ? sources?.provenanceSourceAttribute.map((item) => ({
          id: item.id,
          title: item.value.data
        }))
      : [])
  ];

  const incomesForm = useFormik({
    initialValues: {
      sourceIds:
        mergedIncomeSources?.map((item) => ({
          id: item.id,
          title: item.title
        })) ?? [],
      details: sources?.details,
      // documentId: sources?.documentId,
      quarterlyIncomeId: sources?.quarterlyIncome?.id,
      monthlyIncomeId: sources?.monthlyIncome?.id,
      provenanceSourceIds: mergedProvenanceSources?.map(toSelectOption) ?? [],
      createdAt: sources?.createdAt?.slice(0, 10)
    },
    onSubmit: (values, { setSubmitting }) => {
      const updateValues = {
        // documentId: values?.documentId,
        sourceIds: values?.sourceIds.map((item) => item.id),
        provenanceSourceIds: values?.provenanceSourceIds?.map(
          (item) => item?.id
        ),
        quarterlyIncomeId: values?.quarterlyIncomeId,
        monthlyIncomeId: values?.monthlyIncomeId,
        details: values?.details
      };

      if (!window.location.href.includes('fo')) {
        delete updateValues.monthlyIncomeId;
      }

      if (!values.details) {
        delete updateValues.details;
      }

      dispatch(updateIncome({ ...updateValues, id: sources?.id }));
      dispatch(saveClient(values));
      setSubmitting(false);
      dispatch(setEditing(false));
      setTimeout(() => {
        dispatch(dispatch(getIncomeInfo(clientId)));
      }, 1000);
    },
    enableReinitialize: true
  });

  const foSources = window.location.href.includes('fo')
    ? sources?.incomeSources?.filter(
        (item) =>
          item?.clientType == 'CUSTOMER' ||
          item?.clientType == 'INDIVIDUAL_ENTREPRENEUR'
      )
    : incomesSources?.filter((item) => item?.clientType == 'LEGAL_ENTITY');

  const foQuart = window.location.href.includes('fo')
    ? quarterlyIncome?.filter(
        (item) =>
          item?.clientType == 'CUSTOMER' ||
          item?.clientType == 'INDIVIDUAL_ENTREPRENEUR'
      )
    : quarterlyIncome?.filter((item) => item?.clientType == 'LEGAL_ENTITY');

  const onOpen = ({ id }: { id: string }) =>
    dispatch(getSelectedIncomeInfo(id));

  useEffect(() => {
    dispatch(setEditing(incomesForm.dirty));
  }, [incomesForm.dirty]);

  return {
    incomesForm,
    source: foSources?.map(toSelectOption),
    provenanceSources: provenanceSource?.map(toSelectOption),
    quarterlyIncome: sources?.quarterlyIncome,
    monthlyIncome: sources?.monthlyIncome,
    quart: foQuart?.map(toSelectOption),
    month: monthlyIncome?.map(toSelectOption),
    proven: provenanceSource?.map(toSelectOption),
    onOpen
  };
};

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Назва джерела',
    width: 130,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'description',
    headerName: 'Опис',
    width: 60,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'clientType',
    headerName: 'Тип клієнта',
    width: 100,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'isActive',
    headerName: 'Статус',
    width: 100,
    sortable: false,
    disableColumnMenu: true
  }
];

export { useIncomeProps, columns };
