import React from 'react';
import { components, OptionProps } from 'react-select';
import classNames from 'classnames';
import checkbox from '../assets/checkbox.svg';
import checkboxActive from '../assets/checkbox_active.svg';
import styles from './option.module.scss';

const Option: React.FC<OptionProps<any>> = ({ children, ...props }) => {
  const { onMouseMove: _, onMouseOver: __, ...rest } = props.innerProps;
  const newProps = Object.assign(props, { innerProps: rest });

  return (
    <components.Option
      {...newProps}
      className={classNames(newProps.className, styles.option, {
        [styles.multi]: newProps.isMulti,
        [styles.selected]: newProps.isSelected,
        [styles.focused]: newProps.isFocused
      })}
    >
      {props.isMulti && (
        <img src={props.isSelected ? checkboxActive : checkbox} />
      )}

      <p
        className={classNames(styles.optionText, {
          [styles.single]: !newProps.isMulti
        })}
      >
        {children}
      </p>
    </components.Option>
  );
};

export { Option };
