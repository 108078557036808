import { useDispatch } from 'react-redux';
import { getFatcaInfo } from '@clients/store';
import { useEffect } from 'react';
import { useClientId } from '@clients/pages/edit-client/hooks';
import { useLocation } from 'react-router';
import { useTypedSelector } from '@shared/lib/use-typed-selector';

const useFatcaProps = () => {
  const dispatch = useDispatch();
  const clientId = useClientId();
  const { pathname } = useLocation();

  const { client } = useTypedSelector((state) => state.clients);

  useEffect(() => {
    client?.haveFATCA && dispatch(getFatcaInfo(clientId));
  }, [pathname]);

  return {
    disabled: client?.haveFATCA
  };
};

export { useFatcaProps };
