import React from 'react';
import { hoc } from '@core';
import { Field } from '@core/components';
import { columns, groupColumns, useAttributesProps } from './attributes.props';
import styles from './attributes.module.scss';
import { EditDataGrid } from '@shared/ui/edit-data-grid';
import { SectionLayout } from '../atoms';
import { Role } from '@entities/role';

const Attributes = hoc(
  useAttributesProps,
  ({
    isDirectory,
    randomValues,
    identification,
    attributesInfo,
    attributesNames,
    onOpen,
    onAttributeOpen
  }) => (
    <SectionLayout
      read={Role.AdditionalAttributesRead}
      update={Role.AdditionalAttributesUpdate}
    >
      {({ canUpdate }) => (
        <EditDataGrid
          form={attributesInfo}
          rows={identification}
          columns={groupColumns}
          onOpen={onOpen}
          canUpdate={canUpdate}
          withoutButtons
        >
          <EditDataGrid
            form={attributesInfo}
            rows={attributesNames}
            columns={columns}
            onOpen={onAttributeOpen}
            onSubmit={attributesInfo?.handleSubmit}
            onReset={attributesInfo?.handleReset}
            canUpdate={canUpdate}
          >
            <Field.Input
              className={styles.input}
              editable={false}
              name='attributesGroupId'
              label='Назва групи атрибутів'
            />

            <Field.Input
              className={styles.input}
              theme='primary'
              name='attributeName'
              label='Назва атрибута'
              editable={false}
            />

            <Field.Input
              className={styles.input}
              theme='primary'
              name='attributeDescription'
              label='Опис атрибута'
              editable={false}
            />

            <>
              {isDirectory ? (
                <Field.Select
                  className={styles.inputMargin}
                  options={randomValues}
                  name='attributeValue'
                  label='Значення атрибута'
                  disabled={!canUpdate}
                />
              ) : (
                <Field.Input
                  className={styles.input}
                  theme='primary'
                  name='attributeValue'
                  label='Значення атрибута'
                  editable={canUpdate}
                />
              )}
            </>
          </EditDataGrid>
        </EditDataGrid>
      )}
    </SectionLayout>
  )
);

export { Attributes };
