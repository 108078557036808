import { DirectoryVariant } from '@shared/api';
import { config } from '../config';

const isDirectoryVariant = (value: string): value is DirectoryVariant =>
  (config.variants as string[]).includes(value);

const directoryVariantLabels = ['Клієнти', 'Продукти'];

const directoryVariantsWithLabels = config.variants.map((value, index) => ({
  value,
  label: directoryVariantLabels[index] ?? ''
}));

export { isDirectoryVariant, directoryVariantsWithLabels };
