import { UserException } from '@api';
import { Called } from '@core/utils';
import { State } from '@store';
import { HiddenColumnFeature } from '@features';
import { LastLegalClientsShown } from '@features/clientsID/legal-clients-id';
import { LastClientsShown } from '@features/clientsID/clients-id';
import { StoreContext } from '@store/context';
import {
  authorize,
  changeTriedToRefresh,
  loading,
  logout
} from '@store/general';

import { show } from '@store/toast';
import { Saga } from 'redux-chill';
import { Payload } from 'redux-chill/dist';
import { call, delay, put, select } from 'redux-saga/effects';
import {
  changeFilter,
  clientsFilter,
  getActivityCodesDictionary,
  getAddress,
  getAddressTypes,
  getAdressInfo,
  getAttribute,
  getAttributeGroupInfo,
  getAttributes,
  getAttributesGroups,
  getChannels,
  getClientGoals,
  getCodes,
  getCodesInfo,
  getContact,
  getContactInfo,
  getContactStatus,
  getContactTypes,
  getCountryNames,
  getCurrency,
  getCustomer,
  getCustomerFullName,
  getCustomers,
  getCustomersById,
  getDocument,
  getDocumentInfo,
  getDocumentStatus,
  getDocumentTypes,
  getEstate,
  getEstatesTypes,
  getFatcaInfo,
  getFullProfileCustomer,
  getFullProfileFoCustomer,
  getGenders,
  getHouseTypes,
  getIdent,
  getIdentInfo,
  getIncomeInfo,
  getLegalCustomer,
  getLegalCustomers,
  getLegalCustomersById,
  getMonthlyIncome,
  getOwnershipInfo,
  getOwnershipTypes,
  getProvenanceSource,
  getQuarterlyIncome,
  getRandDirectory,
  getRegions,
  getRelationship,
  getRelationshipRoles,
  getRelationshipsInfo,
  getResponsibleManagers,
  getRestrCriteria,
  getRestriction,
  getRestrictionInfo,
  getRestrSources,
  getRestrTypes,
  getRoomTypes,
  getSecurity,
  getSecurityTypes,
  getSegments,
  getSelectedIncomeInfo,
  getSettlements,
  getSettlementTypes,
  getSources,
  getStreetTypes,
  getSubTypes,
  getVehicle,
  getVehiclesBrands,
  getVehiclesGroups,
  getVehiclesTypes,
  handleError,
  legalClientsFilter,
  setClientId,
  setLegalClientId,
  updateActivityCode,
  updateAddress,
  updateAttribute,
  updateContact,
  updateCustomer,
  updateDocument,
  updateEstate,
  updateFatca,
  updateIdentification,
  updateIncome,
  updateLegalCustomer,
  updateRelationship,
  updateSecurity,
  updateVehicle
} from './actions';
import moment from 'moment';

class ClientsSaga {
  @Saga(changeFilter)
  public *changeFilter({ field }: Payload<typeof changeFilter>) {
    const hiddenColumnsStorage = new HiddenColumnFeature();

    const hiddenColumns = hiddenColumnsStorage.getHiddenColumns('client');

    hiddenColumns[field] = !hiddenColumns[field];

    yield put(changeFilter.save(hiddenColumns));
  }

  @Saga(changeFilter.save)
  public *changeFilterSave({ filters }: Payload<typeof changeFilter.save>) {
    new HiddenColumnFeature().saveHiddenColumns('client', filters);
  }
  @Saga(setClientId)
  public *setClientId({ clientsID }: Payload<typeof setClientId>) {
    new LastClientsShown().saveClientsID(clientsID);
  }
  @Saga(setLegalClientId)
  public *setLegalClientId({
    legalClientsID
  }: Payload<typeof setLegalClientId>) {
    new LastLegalClientsShown().saveLegalClientsID(legalClientsID);
  }

  /**
   * Get Customers
   */
  @Saga(handleError)
  public *handleError(error) {
    const { status, data } = error;

    const { code } = data || {};

    const {
      general: { isTriedToRefresh }
    }: State = yield select();

    if (code === UserException.validationException) {
      if (data?.data?.code === 'SearchIntervalTooLargeException') {
        yield put(
          show(
            'Кількість знайдених клієнтів надто велика! Уточніть критерії пошуку!',
            'error',
            3000
          )
        );
      } else {
        yield put(show('Помилка валідації даних', 'error', 3000));
      }
    }

    if (code === UserException.argumentInvalidException) {
      yield put(
        show(
          'Щось пішло не так, зверніться до системного адміністратора',
          'error',
          3000
        )
      );
    }
    if (code === UserException.databaseException) {
      yield put(
        show(
          'Щось пішло не так, зверніться до системного адміністратора',
          'error',
          3000
        )
      );
    }
    if (code === UserException.internalServerException) {
      yield put(
        show(
          'Щось пішло не так, зверніться до системного адміністратора',
          'error',
          3000
        )
      );
    }
    if (code === UserException.rabbitMqModuleException) {
      yield put(
        show(
          'Щось пішло не так, зверніться до системного адміністратора',
          'error',
          3000
        )
      );
    }
    if (code === UserException.rabbitMQPublisherException) {
      yield put(
        show(
          'Щось пішло не так, зверніться до системного адміністратора',
          'error',
          3000
        )
      );
    }
    if (code === UserException.customerAlreadyExistsException) {
      yield put(
        show(
          'Клієнт вже існує в системі. Перевірте дані повторно!',
          'error',
          3000
        )
      );
    }
    if (code === UserException.legalEntityAlreadyExistsException) {
      yield put(
        show(
          'Клієнт вже існує в системі. Перевірте дані повторно!',
          'error',
          3000
        )
      );
    }
    if (code === UserException.searchIntervalTooLargeException) {
      yield put(
        show(
          'Кількість знайдених клієнтів надто велика! Уточніть(розширте) критерії пошуку!',
          'error',
          3000
        )
      );
    }
    if (code === UserException.notFoundException) {
      yield put(show('Немає даних. Уточніть деталі пошуку!', 'error', 3000));
    }
    if (code === UserException.wrongRequestException) {
      yield put(show('Дані некоректні! Перевірте формат даних', 'error', 3000));
    }
    if (code === UserException.badRequestException) {
      yield put(show('Дані некоректні! Перевірте формат даних', 'error', 3000));
    }
    if (code === UserException.csvBadRowException) {
      yield put(
        show(
          'Структура файлу некоректна! Перевірте файл повторно!',
          'error',
          3000
        )
      );
    }
    if (code === UserException.unauthorizedException) {
      yield put(
        show(
          'Помилка авторизації! Перезавантажте сторінку або здійсніть повторний логін у систему',
          'error',
          3000
        )
      );
    }

    if (status != 404) return;

    if (isTriedToRefresh) {
      yield put(authorize.failed());
    }

    if (code === UserException.unauthorizedException) {
      yield put(show('Час дії сесії вичерпано', 'warning'));
      yield put(authorize.failed());
      yield delay(1000);
      yield put(logout());
    }
    yield put(changeTriedToRefresh(true));
  }
  @Saga(legalClientsFilter)
  public *legalClientsFilter(
    payload: Payload<typeof legalClientsFilter>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.legalClientsFilter> = yield call(
        api.client.legalClientsFilter,
        payload
      );
      data.forEach(
        (_) => (client) =>
          (client.dateOfBirth = client.dateOfBirth.slice(0, 10))
      );
      yield put(legalClientsFilter.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(clientsFilter)
  public *clientsFilter(
    payload: Payload<typeof clientsFilter>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.clientsFilter> = yield call(
        api.client.clientsFilter,
        payload
      );
      data.forEach(
        (client) => (client.dateOfBirth = client.dateOfBirth.slice(0, 10))
      );
      yield put(clientsFilter.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getCustomers)
  public *getCustomers(
    payload: Payload<typeof getCustomers>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getCustomers> = yield call(
        api.client.getCustomers,
        payload
      );
      data.forEach(
        (client) => (client.dateOfBirth = client.dateOfBirth.slice(0, 10))
      );
      yield put(getCustomers.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getCustomersById)
  public *getCustomersById(
    payload: Payload<typeof getCustomersById>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getCustomersById> = yield call(
        api.client.getCustomersById,
        payload
      );
      data.forEach(
        (client) => (client.dateOfBirth = client.dateOfBirth.slice(0, 10))
      );
      yield put(getCustomersById.success(data));
    } catch (error) {
      yield put(handleError(''));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getLegalCustomersById)
  public *getLegalCustomersById(
    payload: Payload<typeof getLegalCustomersById>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getLegalCustomersById> =
        yield call(api.client.getLegalCustomersById, payload);
      data.forEach(
        (client) => (client.dateOfBirth = moment().format('DD.MM.YYYY'))
      );
      yield put(getLegalCustomersById.success(data));
    } catch (error) {
      yield put(handleError(''));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getLegalCustomers)
  public *getLegalCustomers(
    payload: Payload<typeof getLegalCustomers>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getLegalCustomers> = yield call(
        api.client.getLegalCustomers,
        payload
      );
      data.forEach(
        (client) => (client.dateOfBirth = moment().format('DD.MM.YYYY'))
      );
      yield put(getLegalCustomers.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getCustomer)
  public *getCustomer(
    clientId: Payload<typeof getCustomer>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getCustomer> = yield call(
        api.client.getCustomer,
        clientId
      );

      yield put(getCustomer.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getFullProfileCustomer)
  public *getFullProfileCustomer(
    clientId: Payload<typeof getFullProfileCustomer>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getFullProfileCustomer> =
        yield call(api.client.getFullProfileCustomer, clientId);

      yield put(getFullProfileCustomer.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getFullProfileFoCustomer)
  public *getFullProfileFoCustomer(
    clientId: Payload<typeof getFullProfileFoCustomer>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getFullProfileFoCustomer> =
        yield call(api.client.getFullProfileFoCustomer, clientId);

      yield put(getFullProfileFoCustomer.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getLegalCustomer)
  public *getLegalCustomer(
    clientId: Payload<typeof getLegalCustomer>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getLegalCustomer> = yield call(
        api.client.getLegalCustomer,
        clientId
      );

      yield put(getLegalCustomer.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getContactInfo)
  public *getContactInfo(
    clientId: Payload<typeof getContactInfo>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getContactInfo> = yield call(
        api.client.getContactInfo,
        clientId
      );
      yield put(getContactInfo.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getContact)
  public *getContact(
    contactId: Payload<typeof getContact>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getContact> = yield call(
        api.client.getContact,
        contactId
      );
      yield put(getContact.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(updateContact)
  public *updateContact(
    payload: Payload<typeof updateContact>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      yield call(api.client.updateContact, payload);

      yield put(show('Дані оновлено', 'success', 3000));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  /**
   * Get Document Info
   */

  @Saga(getDocumentInfo)
  public *getDocumentInfo(
    clientId: Payload<typeof getDocumentInfo>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getDocumentInfo> = yield call(
        api.client.getDocumentInfo,
        clientId
      );

      yield put(getDocumentInfo.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getDocument)
  public *getDocument(
    documentId: Payload<typeof getDocument>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getDocument> = yield call(
        api.client.getDocument,
        documentId
      );

      yield put(getDocument.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(updateDocument)
  public *updateDocument(
    payload: Payload<typeof updateDocument>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      yield call(api.client.updateDocument, payload);

      yield put(show('Дані оновлено', 'success', 3000));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  /**
   * Get Adress Info
   */

  @Saga(getAdressInfo)
  public *getAdressInfo(
    clientId: Payload<typeof getAdressInfo>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getAdressInfo> = yield call(
        api.client.getAdressInfo,
        clientId
      );

      yield put(getAdressInfo.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getContactTypes)
  public *getContactTypes(
    clientId: Payload<typeof getContactTypes>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getContactTypes> = yield call(
        api.client.getContactTypes
      );

      yield put(getContactTypes.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getRandDirectory)
  public *getRandDirectory(
    clientId: Payload<typeof getRandDirectory>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getRandDirectory> = yield call(
        api.client.getRandDirectory,
        clientId
      );

      yield put(getRandDirectory.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getClientGoals)
  public *getClientGoals(
    clientId: Payload<typeof getClientGoals>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getClientGoals> = yield call(
        api.client.getClientGoals
      );

      yield put(getClientGoals.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getGenders)
  public *getGenders(
    clientId: Payload<typeof getGenders>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getGenders> = yield call(
        api.client.getGenders
      );
      yield put(getGenders.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getCodes)
  public *getCodes(clientId: Payload<typeof getCodes>, { api }: StoreContext) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getCodes> = yield call(
        api.client.getCodes
      );
      yield put(getCodes.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getSources)
  public *getSources(
    clientId: Payload<typeof getSources>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getSources> = yield call(
        api.client.getSources
      );
      yield put(getSources.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getStreetTypes)
  public *getStreetTypes(
    clientId: Payload<typeof getStreetTypes>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getStreetTypes> = yield call(
        api.client.getStreetTypes
      );

      yield put(getStreetTypes.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getHouseTypes)
  public *getHouseTypes(
    _: Payload<typeof getHouseTypes>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getHouseTypes> = yield call(
        api.client.getHouseTypes
      );
      yield put(getHouseTypes.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getRoomTypes)
  public *getRoomTypes(_: Payload<typeof getRoomTypes>, { api }: StoreContext) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getRoomTypes> = yield call(
        api.client.getRoomTypes
      );

      yield put(getRoomTypes.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getAttributesGroups)
  public *getAttributesGroups(
    clientId: Payload<typeof getAttributesGroups>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getAttributesGroups> =
        yield call(api.client.getAttributesGroups);

      yield put(getAttributesGroups.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getActivityCodesDictionary)
  public *getActivityCodesDictionary(
    clientId: Payload<typeof getActivityCodesDictionary>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getActivityCodesDictionary> =
        yield call(api.client.getActivityCodesDictionary);

      yield put(getActivityCodesDictionary.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getRelationshipRoles)
  public *getRelationshipRoles(
    clientId: Payload<typeof getRelationshipRoles>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getRelationshipRoles> =
        yield call(api.client.getRelationshipRoles);

      yield put(getRelationshipRoles.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getEstatesTypes)
  public *getEstatesTypes(
    clientId: Payload<typeof getEstatesTypes>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getEstatesTypes> = yield call(
        api.client.getEstatesTypes
      );

      yield put(getEstatesTypes.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getCurrency)
  public *getCurrency(
    clientId: Payload<typeof getCurrency>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getCurrency> = yield call(
        api.client.getCurrency
      );

      yield put(getCurrency.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getVehiclesTypes)
  public *getVehiclesTypes(
    clientId: Payload<typeof getVehiclesTypes>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getVehiclesTypes> = yield call(
        api.client.getVehiclesTypes
      );

      yield put(getVehiclesTypes.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getVehiclesGroups)
  public *getVehiclesGroups(
    clientId: Payload<typeof getVehiclesGroups>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getVehiclesGroups> = yield call(
        api.client.getVehiclesGroups
      );

      yield put(getVehiclesGroups.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getVehiclesBrands)
  public *getVehiclesBrands(
    clientId: Payload<typeof getVehiclesBrands>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getVehiclesBrands> = yield call(
        api.client.getVehiclesBrands
      );

      yield put(getVehiclesBrands.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getSecurityTypes)
  public *getSecurityTypes(
    clientId: Payload<typeof getSecurityTypes>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getSecurityTypes> = yield call(
        api.client.getSecurityTypes
      );

      yield put(getSecurityTypes.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getContactStatus)
  public *getContactStatus(
    clientId: Payload<typeof getContactStatus>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getContactStatus> = yield call(
        api.client.getContactStatus
      );

      yield put(getContactStatus.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getCountryNames)
  public *getCountryNames(
    _: Payload<typeof getCountryNames>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getCountryNames> = yield call(
        api.client.getCountryNames
      );

      yield put(getCountryNames.success(data));
    } catch (error) {
      yield put(handleError(error));
      yield put(getCountryNames.fail());
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getSegments)
  public *getSegments(
    clientId: Payload<typeof getSegments>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getSegments> = yield call(
        api.client.getSegments
      );

      yield put(getSegments.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getChannels)
  public *getChannels(
    clientId: Payload<typeof getChannels>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getChannels> = yield call(
        api.client.getChannels
      );

      yield put(getChannels.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getDocumentTypes)
  public *getDocumentTypes(
    clientId: Payload<typeof getDocumentTypes>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getDocumentTypes> = yield call(
        api.client.getDocumentTypes
      );

      yield put(getDocumentTypes.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getDocumentStatus)
  public *getDocumentStatus(
    clientId: Payload<typeof getDocumentStatus>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getDocumentStatus> = yield call(
        api.client.getDocumentStatus
      );

      yield put(getDocumentStatus.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getAddressTypes)
  public *getAddressTypes(
    _: Payload<typeof getAddressTypes>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getAddressTypes> = yield call(
        api.client.getAddressTypes
      );

      yield put(getAddressTypes.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getRegions)
  public *getRegions(
    countryId: Payload<typeof getRegions>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      if (!countryId) {
        yield put(getRegions.success([]));
        return;
      }

      const { data }: Called<typeof api.client.getRegions> = yield call(
        api.client.getRegions,
        countryId
      );

      yield put(getRegions.success(data));
    } catch (error) {
      yield put(handleError(error));
      yield put(getRegions.fail());
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getSettlementTypes)
  public *getSettlementTypes(
    _: Payload<typeof getSettlementTypes>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getSettlementTypes> = yield call(
        api.client.getSettlementTypes
      );

      yield put(getSettlementTypes.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getSettlements)
  public *getSettlements(
    regionId: Payload<typeof getSettlements>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      if (!regionId) {
        yield put(getSettlements.success([]));
        return;
      }

      const { data }: Called<typeof api.client.getSettlements> = yield call(
        api.client.getSettlements,
        regionId
      );
      yield put(getSettlements.success(data));
    } catch (error) {
      yield put(handleError(error));
      yield put(getSettlements.fail());
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getRestrTypes)
  public *getRestrTypes(
    clientId: Payload<typeof getRestrTypes>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getRestrTypes> = yield call(
        api.client.getRestrTypes
      );

      yield put(getRestrTypes.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getRestrSources)
  public *getRestrSources(
    clientId: Payload<typeof getRestrSources>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getRestrSources> = yield call(
        api.client.getRestrSources
      );

      yield put(getRestrSources.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getRestrCriteria)
  public *getRestrCriteria(
    clientId: Payload<typeof getRestrCriteria>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getRestrCriteria> = yield call(
        api.client.getRestrCriteria
      );
      yield put(getRestrCriteria.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getAddress)
  public *getAddress(
    addressId: Payload<typeof getAddress>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getAddress> = yield call(
        api.client.getAddress,
        addressId
      );

      yield put(getAddress.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(updateAddress)
  public *updateAddress(
    payload: Payload<typeof updateAddress>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      yield call(api.client.updateAddress, payload);
      yield put(show('Дані оновлено', 'success', 3000));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(updateEstate)
  public *updateEstate(
    payload: Payload<typeof updateEstate>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      yield call(api.client.updateEstate, payload);
      yield put(show('Дані оновлено', 'success', 3000));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(updateVehicle)
  public *updateVehicle(
    payload: Payload<typeof updateVehicle>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      yield call(api.client.updateVehicle, payload);
      yield put(show('Дані оновлено', 'success', 3000));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(updateSecurity)
  public *updateSecurity(
    payload: Payload<typeof updateSecurity>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      yield call(api.client.updateSecurity, payload);

      yield put(show('Дані оновлено', 'success', 3000));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getIdentInfo)
  public *getIdentInfo(
    clientId: Payload<typeof getIdentInfo>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getIdentInfo> = yield call(
        api.client.getIdentInfo,
        clientId
      );

      yield put(getIdentInfo.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getIdent)
  public *getIdent(identId: Payload<typeof getIdent>, { api }: StoreContext) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getIdent> = yield call(
        api.client.getIdent,
        identId
      );

      yield put(getIdent.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getFatcaInfo)
  public *getFatcaInfo(
    clientId: Payload<typeof getFatcaInfo>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getFatcaInfo> = yield call(
        api.client.getFatcaInfo,
        clientId
      );

      yield put(getFatcaInfo.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getOwnershipInfo)
  public *getOwnershipInfo(
    clientId: Payload<typeof getOwnershipInfo>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getOwnershipInfo> = yield call(
        api.client.getOwnershipInfo,
        clientId
      );

      yield put(getOwnershipInfo.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getVehicle)
  public *getVehicle(
    vehicleId: Payload<typeof getVehicle>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getVehicle> = yield call(
        api.client.getVehicle,
        vehicleId
      );

      yield put(getVehicle.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getEstate)
  public *getEstate(
    estateId: Payload<typeof getEstate>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getEstate> = yield call(
        api.client.getEstate,
        estateId
      );

      yield put(getEstate.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getSecurity)
  public *getSecurity(
    securityId: Payload<typeof getSecurity>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getSecurity> = yield call(
        api.client.getSecurity,
        securityId
      );

      yield put(getSecurity.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getIncomeInfo)
  public *getIncomeInfo(
    clientId: Payload<typeof getIncomeInfo>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getIncomeInfo> = yield call(
        api.client.getIncomeInfo,
        clientId
      );

      yield put(getIncomeInfo.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getSelectedIncomeInfo)
  public *getSelectedIncomeInfo(
    incomeId: Payload<typeof getSelectedIncomeInfo>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getSelectedIncomeInfo> =
        yield call(api.client.getSelectedIncomeInfo, incomeId);

      yield put(getSelectedIncomeInfo.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getRelationshipsInfo)
  public *getRelationshipsInfo(
    clientId: Payload<typeof getRelationshipsInfo>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getRelationshipsInfo> =
        yield call(api.client.getRelationshipsInfo, clientId);

      yield put(getRelationshipsInfo.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getRelationship)
  public *getRelationship(
    relationshipId: Payload<typeof getRelationship>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getRelationship> = yield call(
        api.client.getRelationship,
        relationshipId
      );

      yield put(getRelationship.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(updateRelationship)
  public *updateRelationship(
    payload: Payload<typeof updateRelationship>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      yield call(api.client.updateRelationship, payload);

      yield put(show('Дані оновлено', 'success', 3000));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getAttributes)
  public *getAttributes(
    payload: Payload<typeof getAttributes>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getAttributes> = yield call(
        api.client.getAttributes,
        payload
      );

      yield put(getAttributes.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getAttribute)
  public *getAttribute(
    attributeId: Payload<typeof getAttribute>,

    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getAttribute> = yield call(
        api.client.getAttribute,
        attributeId
      );
      yield put(getAttribute.success(data));

      const response: Called<typeof api.client.getRandDirectory> = yield call(
        api.client.getRandDirectory,
        data?.attribute?.directoryId
      );

      yield put(getRandDirectory.success(response?.data));
    } catch (error) {
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(updateAttribute)
  public *updateAttribute(
    payload: Payload<typeof updateAttribute>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.updateAttribute> = yield call(
        api.client.updateAttribute,
        payload
      );

      yield put(updateAttribute.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(updateIdentification)
  public *updateIdentification(
    payload: Payload<typeof updateIdentification>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      yield call(api.client.updateIdentification, payload);

      yield put(show('Дані оновлено', 'success', 3000));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(updateIncome)
  public *updateIncome(
    payload: Payload<typeof updateIncome>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      yield call(api.client.updateIncome, payload);

      yield put(show('Дані оновлено', 'success', 3000));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(updateActivityCode)
  public *updateActivityCode(
    payload: Payload<typeof updateIncome>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      yield call(api.client.updateActivityCode, payload);

      yield put(show('Дані оновлено', 'success', 3000));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(updateFatca)
  public *updateFatca(
    payload: Payload<typeof updateFatca>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      yield call(api.client.updateFatca, payload);

      yield put(show('Дані оновлено', 'success', 3000));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(updateCustomer)
  public *updateCustomer(
    payload: Payload<typeof updateCustomer>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      yield call(api.client.updateCustomer, payload);

      yield put(show('Дані оновлено', 'success', 3000));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(updateLegalCustomer)
  public *updateLegalCustomer(
    payload: Payload<typeof updateLegalCustomer>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      yield call(api.client.updateLegalCustomer, payload);

      yield put(show('Дані оновлено', 'success', 3000));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getAttributeGroupInfo)
  public *getAttributeGroupInfo(
    clientId: Payload<typeof getAttributeGroupInfo>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getAttributeGroupInfo> =
        yield call(api.client.getAttributeGroupInfo);

      yield put(getAttributeGroupInfo.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getOwnershipTypes)
  public *getOwnershipTypes(
    clientId: Payload<typeof getOwnershipTypes>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getOwnershipTypes> = yield call(
        api.client.getOwnershipTypes
      );

      yield put(getOwnershipTypes.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getSubTypes)
  public *getSubTypes(
    clientId: Payload<typeof getSubTypes>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getSubTypes> = yield call(
        api.client.getSubTypes
      );

      yield put(getSubTypes.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getCodesInfo)
  public *getCodesInfo(
    clientId: Payload<typeof getCodesInfo>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getCodesInfo> = yield call(
        api.client.getCodesInfo,
        clientId
      );

      yield put(getCodesInfo.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getProvenanceSource)
  public *getProvenanceSource(
    clientId: Payload<typeof getProvenanceSource>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getProvenanceSource> =
        yield call(api.client.getProvenanceSource);

      yield put(getProvenanceSource.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getMonthlyIncome)
  public *getMonthlyIncome(
    clientId: Payload<typeof getMonthlyIncome>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getMonthlyIncome> = yield call(
        api.client.getMonthlyIncome
      );

      yield put(getMonthlyIncome.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getQuarterlyIncome)
  public *getQuarterlyIncome(
    clientId: Payload<typeof getQuarterlyIncome>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getQuarterlyIncome> = yield call(
        api.client.getQuarterlyIncome
      );

      yield put(getQuarterlyIncome.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getCustomerFullName)
  public *getCustomerFullName(
    clientId: Payload<typeof getCustomerFullName>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getCustomerFullName> =
        yield call(api.client.getCustomerFullName, clientId);

      yield put(getCustomerFullName.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getRestrictionInfo)
  public *getRestrictionInfo(
    clientId: Payload<typeof getRestrictionInfo>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getRestrictionInfo> = yield call(
        api.client.getRestrictionInfo,
        clientId
      );

      yield put(getRestrictionInfo.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
  @Saga(getRestriction)
  public *getRestriction(
    restrictionId: Payload<typeof getRestriction>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getRestriction> = yield call(
        api.client.getRestriction,
        restrictionId
      );

      yield put(getRestriction.success(data));
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  @Saga(getResponsibleManagers)
  public *getResponsibleManagers(
    _: Payload<typeof getResponsibleManagers>,
    { api }: StoreContext
  ) {
    try {
      yield put(loading.start());

      const { data }: Called<typeof api.client.getResponsibleManagers> =
        yield call(api.client.getResponsibleManagers);

      yield put(
        getResponsibleManagers.success(
          data.map(({ id, employeeLdapId }) => ({
            label: `${
              employeeLdapId === 'null' ? 'Відсутній менеджер' : employeeLdapId
            }`,
            value: id
          }))
        )
      );
    } catch (error) {
      yield put(handleError(error));
    } finally {
      yield put(loading.finish());
    }
  }
}

export { ClientsSaga };
