import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { matchPath } from 'react-router';

const useIsClientPage = () => {
  const { pathname } = useLocation();

  return useMemo(
    () =>
      !!matchPath('/clients', {
        exact: true,
        strict: true,
        path: pathname
      }),
    [pathname]
  );
};

export { useIsClientPage };
