import React from 'react';
import { hoc } from '@core';
import { Field } from '@core/components';
import { columns, useRestrictionProps } from './restriction.props';
import styles from './restriction.module.scss';
import { EditDataGrid } from '@shared/ui/edit-data-grid';
import { SectionLayout } from '../atoms';
import { Role } from '@entities/role';

const Restrictions = hoc(
  useRestrictionProps,
  ({
    restrictionVariant,
    sources,
    criteria,
    types,
    identification,
    restrictionInfo,
    onOpen
  }) => (
    <SectionLayout read={Role.RestrictionsRead}>
      {() => (
        <EditDataGrid
          form={restrictionInfo}
          rows={identification}
          columns={columns}
          onOpen={onOpen}
          canUpdate={false}
        >
          {restrictionVariant == 'contact' ? (
            <>
              <Field.Input
                className={styles.input}
                theme='primary'
                name='contactId'
                label='Ідентифікатор'
                editable={false}
              />

              <Field.Input
                className={styles.input}
                theme='primary'
                name='contactValue'
                label='Обмеження по номеру'
                editable={false}
              />
              <Field.Input
                className={styles.input}
                theme='primary'
                name='contactType'
                label='Обмеження по Типу'
                editable={false}
              />
            </>
          ) : restrictionVariant == 'docs' ? (
            <>
              <Field.Input
                className={styles.redirectInput}
                theme='primary'
                name='docId'
                label='Ідентифікатор'
                editable={false}
              />

              <Field.Input
                className={styles.input}
                theme='primary'
                name='number'
                label='Обмеження по номеру'
                editable={false}
              />
              <Field.Input
                className={styles.input}
                theme='primary'
                name='series'
                label='Обмеження по Серії'
                editable={false}
              />
              <Field.Input
                className={styles.input}
                theme='primary'
                name='docType'
                label='Обмеження по типу документа'
                editable={false}
              />
            </>
          ) : restrictionVariant == 'address' ? (
            <>
              <Field.Input
                className={styles.input}
                theme='primary'
                name='addressId'
                label='Ідентифікатор'
                editable={false}
              />

              <Field.Input
                className={styles.input}
                theme='primary'
                name='cityName'
                label='Обмеження по назві міста'
                editable={false}
              />
              <Field.Input
                className={styles.input}
                theme='primary'
                name='streetName'
                label='Обмеження по назві вулиці'
                editable={false}
              />
              <Field.Input
                className={styles.input}
                theme='primary'
                name='building'
                label='Обмеження по номеру будинку'
                editable={false}
              />
              <Field.Input
                className={styles.input}
                theme='primary'
                name='flat'
                label='Обмеження по номеру квартири'
                editable={false}
              />
            </>
          ) : restrictionVariant == 'profile' ? (
            <>
              {window.location.href.includes('legal-entity') ? (
                <Field.Input
                  className={styles.input}
                  theme='primary'
                  name='nameJur'
                  label='Обмеження по імені'
                  editable={false}
                />
              ) : (
                <>
                  <Field.Input
                    className={styles.input}
                    theme='primary'
                    name='firstName'
                    label='Обмеження по імені'
                    editable={false}
                  />
                  <Field.Input
                    className={styles.input}
                    theme='primary'
                    name='secondName'
                    label='Обмеження по батькові'
                    editable={false}
                  />
                </>
              )}

              <Field.Input
                className={styles.input}
                theme='primary'
                name='building'
                label='Обмеження по інн'
                editable={false}
              />
            </>
          ) : null}

          <Field.Select
            className={styles.input}
            options={types}
            name='restrTypeId'
            label='Тип'
            disabled
            placeholder=''
          />

          <Field.Select
            className={styles.input}
            options={sources}
            name='restrSourceId'
            label='Джерело'
            disabled
            placeholder=''
          />

          <Field.Select
            className={styles.input}
            options={criteria}
            name='restrCriterionId'
            label='Критерія'
            disabled
            placeholder=''
          />
        </EditDataGrid>
      )}
    </SectionLayout>
  )
);

export { Restrictions };
