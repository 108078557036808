import { useLayoutEffect, useState } from 'react';

const useTableFilter = () => {
  const [isFilterOpen, setFilterOpen] = useState(false);

  const openFilter = () => {
    setFilterOpen(true);
  };

  const closeFilter = () => {
    setFilterOpen(false);
  };

  useLayoutEffect(() => {
    document.body.style.overflow = !isFilterOpen ? 'auto' : 'hidden';
  }, [isFilterOpen]);

  return {
    isFilterOpen,
    openFilter,
    closeFilter
  };
};

export { useTableFilter };
