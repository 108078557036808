const filterMap = {
  innCode: 'РНОКПП',
  passportSeries: 'Серія',
  passportNumber: 'Номер',
  lastName: 'Прізвище',
  firstName: "Ім'я",
  middleName: 'По батькові',
  ugbCode: 'Код UGB',
  responsibleManagerId: 'Відповідальний менеджер',
  edrpou: 'ЄДРПОУ',
  companyName: 'Найменування'
};

export { filterMap };
