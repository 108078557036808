import { nanoid } from 'nanoid';

import { HttpService, MockHttp } from '@shared/lib/http';

import {
  ApplyChangesToDirectoryDto,
  CreateDirectoryDto,
  DirectoryVariant,
  ProductOwnerDto,
  SearchDirectoryContract,
  SearchDirectoryItemsContract,
  Directories
} from './models';

class MockDirectoryService extends MockHttp {
  protected directories: SearchDirectoryContract[] = [
    { id: nanoid(), name: 'Довідник ФО', variant: 'fo-fop' },
    { id: nanoid(), name: 'Довідник ФОП', variant: 'fo-fop' },
    { id: nanoid(), name: 'Довідник ЮО', variant: 'yo' },
    { id: nanoid(), name: 'Довідник ЮО 2', variant: 'yo' },
    { id: nanoid(), name: 'Довідник продуктів', variant: 'product' },
    { id: nanoid(), name: 'Довідник продуктів 2', variant: 'product' }
  ];

  protected productOwners: ProductOwnerDto[] = [
    { id: nanoid(), title: 'МММ' },
    { id: nanoid(), title: 'HNM' },
    { id: nanoid(), title: 'CCC' },
    { id: nanoid(), title: 'IRL' },
    { id: nanoid(), title: 'WTF' },
    { id: nanoid(), title: 'ISR' },
    { id: nanoid(), title: 'SSG' },
    { id: nanoid(), title: 'SSR' },
    { id: nanoid(), title: 'CSR' },
    { id: nanoid(), title: 'MKS' }
  ];

  protected directoryItemsMap: Record<
    SearchDirectoryContract['id'],
    SearchDirectoryItemsContract[]
  > = {
    [this.directories[0].id]: [
      { id: nanoid(), title: 'Кредит А' },
      { id: nanoid(), title: 'Кредит Б' }
    ],
    [this.directories[1].id]: [
      { id: nanoid(), title: 'Кредит В' },
      { id: nanoid(), title: 'Кредит Г' }
    ],
    [this.directories[2].id]: [
      { id: nanoid(), title: 'Кредит Д' },
      { id: nanoid(), title: 'Кредит Р' }
    ],
    [this.directories[3].id]: [
      { id: nanoid(), title: 'Кредит Х' },
      { id: nanoid(), title: 'Кредит С' }
    ],
    [this.directories[4].id]: [
      { id: nanoid(), title: 'Кредит А', owner: 'МСБ' },
      { id: nanoid(), title: 'Кредит Б', owner: 'ММБ' }
    ],
    [this.directories[5].id]: [
      { id: nanoid(), title: 'Кредит А', owner: 'RGB' },
      { id: nanoid(), title: 'Кредит Б', owner: 'HSL' }
    ]
  };
}

class DirectoryService extends MockDirectoryService {
  constructor(private readonly http: HttpService) {
    super();
  }

  public getDirectories = async () =>
    this.http.request({
      url: 'v1/directories',
      method: 'GET'
    });
  public getProductDirectories = async () =>
    this.http.request({
      url: '/v1/product-directories/products',
      method: 'GET'
    });
  public getBusinessDirectories = async () =>
    this.http.request({
      url: '/v1/product-directories/business',
      method: 'GET'
    });
  public getDirectory = async (directoryId: Directories['id']) =>
    this.http.request({
      url: `v1/directories/${directoryId}`,
      method: 'GET'
    });
  public postDirectory = async ({ ...data }) =>
    this.http.request({
      url: `/v1/directories`,
      method: 'POST',
      data
    });

  public postProduct = async ({ ...data }) =>
    this.http.request({
      url: `/v1/product-directories/products`,
      method: 'POST',
      data
    });
  public postBusinessOwner = async ({ ...data }) =>
    this.http.request({
      url: `/v1/product-directories/business`,
      method: 'POST',
      data
    });
  public updateDirectory = async ({ id, ...data }) =>
    this.http.request({
      url: `/v1/directories/${id}`,
      method: 'PUT',
      data
    });
  public searchDirectories = (query: string, variant: DirectoryVariant) =>
    this.getMockResponse<SearchDirectoryContract[]>(
      this.directories.filter(
        (directory) =>
          directory.variant === variant &&
          directory.name.toLowerCase().includes(query.toLowerCase())
      )
    );

  public searchDirectoryItems = (directoryId: SearchDirectoryContract['id']) =>
    this.getMockResponse<SearchDirectoryItemsContract[]>(
      this.directoryItemsMap[directoryId] ?? []
    );

  public applyChangesToDirectory = (
    directoryId: SearchDirectoryContract['id'],
    dto: ApplyChangesToDirectoryDto
  ) => {
    const itemsWithoutDeleted = this.directoryItemsMap[directoryId].filter(
      ({ id }) => !dto.deleted.includes(id)
    );

    const variant = this.directories.find(
      (directory) => directory.id === directoryId
    ).variant;

    const createdItems = dto.created.map(({ title, owner }) => {
      if (variant === 'product') {
        return {
          id: nanoid(),
          title,
          owner: this.productOwners.find(
            (productOwner) => productOwner.id === owner
          ).title
        };
      }

      return { id: nanoid(), title };
    });

    const newItems = [...itemsWithoutDeleted, ...createdItems];

    this.directoryItemsMap[directoryId] = newItems;

    return this.getMockResponse<SearchDirectoryItemsContract[]>(newItems);
  };

  public getProductOwners = () => this.getMockResponse(this.productOwners);

  public createDirectory = (
    variant: DirectoryVariant,
    dto: CreateDirectoryDto
  ) => {
    const directory: SearchDirectoryContract = {
      id: nanoid(),
      variant,
      name: dto.name
    };

    const createdItems = dto.created.map((createDto) => {
      if (variant === 'product') {
        const owner = this.productOwners.find(
          (productOwner) => productOwner.id === createDto.owner
        ).title;

        return { id: nanoid(), title: createDto.title, owner };
      }

      return { id: nanoid(), title: createDto.title };
    });

    this.directories.push(directory);

    this.directoryItemsMap[directory.id] = createdItems;

    return this.getMockResponse(directory);
  };
}

export { DirectoryService };
