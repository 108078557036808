import { createFilter, Styles } from 'react-select';
import { ThemeConfig } from 'react-select/src/theme';
import { SelectContainer } from './select-container';
import { Option } from './option';
import { MultiValue } from './multi-value';
import { Placeholder } from './placeholder';
import { Input } from './input';
import { Control } from './control';
import { ValueContainer } from './value-container';

const allOption = {
  label: 'Всі',
  value: '*'
};

const fileOption = {
  label: 'Завантажити файл',
  value: 'file'
};

const customComponents = {
  Control,
  SelectContainer,
  Option,
  MultiValue,
  Placeholder,
  Input,
  ValueContainer
};

const selectStyles: Partial<Styles> = {
  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
  menu: (styles) => ({ ...styles, zIndex: 101 }),
  singleValue: (styles) => ({ ...styles, top: '65%' }),
  multiValue: (styles) => ({ ...styles, maxWidth: '70%' }),
  multiValueRemove: (styles) => ({ ...styles, display: 'none' }),
  menuList: (styles) => ({ ...styles, overflowX: 'hidden' }),
  container: (styles) => ({ ...styles, minWidth: 0 })
};

const theme: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#6D62E2',
    neutral5: '#ffffff'
  }
});

const filterOption = createFilter({ ignoreAccents: false });

export {
  customComponents,
  selectStyles,
  theme,
  allOption,
  fileOption,
  filterOption
};
