import React from 'react';
import { components, InputProps } from 'react-select';
import classNames from 'classnames';
import styles from './input.module.scss';

const Input: React.FC<InputProps> = ({ children: _, className, ...rest }) => (
  <components.Input {...rest} className={classNames(className, styles.input)} />
);

export { Input };
