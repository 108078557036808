import React, { useEffect } from 'react';
import { LastLegalClientsShown } from '@features/clientsID';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@shared/lib/use-typed-selector';
import { Client } from '@api';

import { useSearchbar, useTableFilter } from '@app';
import { navigate } from '@store/router';
import {
  getClients,
  getClientsByPhone,
  getFullProfileCustomer,
  getLegalCustomer,
  getLegalCustomersById,
  setEditing,
  setLegalClientId
} from '@clients/store';

const useLegalEntityClientsListProps = () => {
  const dispatch = useDispatch();

  const { client, legalEntityClients } = useTypedSelector(
    (state) => state.clients
  );

  const { isFilterOpen, closeFilter, openFilter } = useTableFilter();

  const [openedId, setOpenedId] = React.useState(null);

  const onOpenClick = (clientId: Client['clientId']) => {
    dispatch(navigate(`/clients/legal-entity/${clientId}/edit`));
    dispatch(getLegalCustomer(clientId));
    dispatch(getFullProfileCustomer(clientId));
    dispatch(setLegalClientId([clientId]));
  };

  const onIconClick = (clientId: Client['clientId']) => {
    dispatch(getLegalCustomer(clientId));
  };

  useEffect(() => {
    const clientsIdList = new LastLegalClientsShown().getLegalClientsID();

    dispatch(getLegalCustomersById({ clientIdList: clientsIdList?.reverse() }));
  }, []);

  useEffect(() => {
    dispatch(getClients());
    dispatch(setEditing(false));
  }, []);

  useSearchbar((query) => {
    dispatch(getClientsByPhone(query));
  });

  return {
    names: {
      eddrCode: client?.eddrCode,
      ugbId: client?.ugbId,
      ownershipType: client?.ownershipType?.title
    },
    clients: legalEntityClients ?? [],
    isFilterOpen,
    openedId,
    closeFilter,
    openFilter,
    onOpenClick,
    onIconClick,
    onRowOpen: setOpenedId
  };
};

export { useLegalEntityClientsListProps };
