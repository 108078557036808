import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Theme } from '@api';
import { Formatter } from '@core/utils';
import { ButtonProps } from './button.props';
import styles from './button.module.scss';

/**
 * Renders Button
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { icon, theme, rounded, children, className, interactive, ...props },
    ref
  ) => (
    <button
      ref={ref}
      className={classNames(
        className,
        styles.button,
        styles['buttonTheme' + Formatter.capitalize(Theme[theme])],
        {
          [styles.buttonIcon]: icon,
          [styles.buttonRounded]: rounded,
          [styles.interactive]: interactive
        }
      )}
      {...props}
    >
      {children}
    </button>
  )
);

Button.defaultProps = {
  type: 'button',
  theme: Theme.primary
};

export { Button };
