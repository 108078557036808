import React, { memo } from 'react';
import classNames from 'classnames';
import { components } from 'react-select';
import { useLabel } from '../new-select.context';
import { SelectControlProps } from './value-container.props';
import styles from './value-container.module.scss';

const CHIPS_LIMIT = 1;

const ValueContainer: React.FC<SelectControlProps> = ({
  label: _,
  children,
  ...props
}) => {
  if (!props.hasValue || !props.isMulti) {
    return (
      <components.ValueContainer
        {...props}
        className={classNames(props.className, styles.container, styles.single)}
      >
        <SelectLabel isDisabled={props?.selectProps?.isDisabled} />

        <div className={styles.multi}>{children}</div>
      </components.ValueContainer>
    );
  }

  const chips: React.ReactNode[] = children[0];
  const otherChildren: React.ReactNode = children[1];

  const counter = props.getValue().length - CHIPS_LIMIT;
  const displayChips = chips.slice(0, CHIPS_LIMIT);

  return (
    <components.ValueContainer
      {...props}
      className={classNames(props.className, styles.container)}
    >
      <SelectLabel isDisabled={props?.selectProps?.isDisabled} />

      <div className={styles.multi}>
        {displayChips}

        <div className={styles.counter}>{counter > 0 && `+ ${counter}`}</div>

        {otherChildren}
      </div>
    </components.ValueContainer>
  );
};

type SelectLabelProps = {
  isDisabled: boolean;
};

const SelectLabel: React.FC<SelectLabelProps> = memo(({ isDisabled }) => {
  const label = useLabel();

  return (
    <div
      className={classNames(styles.label, {
        [styles.disabled]: isDisabled
      })}
    >
      {label}
    </div>
  );
});

export { ValueContainer };
