import React from 'react';
import { Alert } from '@core/components/alert';
import { Button } from '@core';
import { RecentlyAddedClientAlertProps } from './recently-added-client-alert.props';
import styles from './recently-added-client-alert.props.module.scss';

const RecentlyAddedClientAlert: React.FC<RecentlyAddedClientAlertProps> = ({
  onEditClick,
  onCancelClick
}) => (
  <Alert
    className={styles.alert}
    message='Клієнта додано'
    description='Редагувати дані клієнта?'
  >
    <div className={styles.buttonsBlock}>
      <Button
        className={styles.buttonPrimary}
        theme='primary'
        onClick={onEditClick}
      >
        редагувати
      </Button>
      <Button
        className={styles.buttonSecondary}
        theme='secondary'
        onClick={onCancelClick}
      >
        пропустити
      </Button>
    </div>
  </Alert>
);

export { RecentlyAddedClientAlert };
