import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useClickOutside, useMediaPoints } from '@core/hooks';
import { setSearchQuery, setSearchResultStatus } from '@store/search';

import { getCustomers, getLegalCustomers, setQueryValue } from '@clients/store';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

import { useIsClientPage, usePagePath } from './hooks';
import { hiddenPaths, placeholders } from './search-bar.constants';
import { Role } from '@entities/role';

const useSearchbarProps = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { isTabletDown } = useMediaPoints();
  const isClientsPage = useIsClientPage();
  const pagePath = usePagePath();

  const {
    search: { query, searchResultStatus },
    clients: { queryValue }
  } = useTypedSelector((state) => state);

  const [searchFocused, setSearchFocused] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const box = useRef<HTMLDivElement>();

  //TODO: drop window usage
  const isLegalEntity = window.location.href.includes('legal-entity');

  const onSearchFocus = () => {
    setSearchFocused(true);
  };

  const onSearchBlur = () => {
    setSearchFocused(false);
  };

  const onPressEnter = (e) => {
    if (e.key !== 'Enter') return;

    onButtonClick(queryValue);
  };
  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setQueryValue(event.target.value));
  };

  const onButtonClick = (queryValue) => {
    const action = isLegalEntity ? getLegalCustomers : getCustomers;
    let newString = queryValue[0] == '+' ? queryValue.slice(1) : queryValue;

    dispatch(action({ phoneNumber: newString }));

    dispatch(setSearchResultStatus(true));

    if (newString == '') {
      setIsSearching(false);

      return;
    }

    if (!searchResultStatus) return;

    setIsSearching(true);
  };

  useEffect(() => {
    dispatch(setSearchQuery(''));
  }, [pagePath]);

  useClickOutside(
    () => {
      if (!searchFocused) return;

      setSearchFocused(false);
    },
    box.current,
    [searchFocused]
  );

  const checkIsDisabled = () => {
    if (!queryValue) return true;

    if (queryValue?.startsWith('+380') || queryValue?.startsWith('380')) {
      return queryValue?.replace('+', '')?.length < 8;
    }

    return false;
  };

  return {
    role: isLegalEntity ? Role.LegalEntityAccess : Role.PhysicalsAccess,

    disabled: checkIsDisabled(),

    placeholder: placeholders[pagePath],

    isSearchbarHidden:
      isTabletDown && hiddenPaths.some((path) => pathname.includes(path)),

    isSearching,
    isClientsPage,

    box,
    query,
    queryValue,
    searchFocused,

    onSearch,
    onPressEnter,
    onSearchBlur,
    onSearchFocus,
    onButtonClick,
    setSearchFocused
  };
};

export { useSearchbarProps };
