import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Button } from '@mui/material';

import { hoc } from '@core';

import { useSearchbarProps } from './search-bar.props';
import styles from './search-bar.module.scss';
import { RoleAccess } from '@entities/role';

const Searchbar = hoc(
  useSearchbarProps,
  ({
    box,
    role,

    disabled,
    isSearchbarHidden,

    query,
    placeholder,
    queryValue,

    onSearch,
    onButtonClick,
    onPressEnter,
    onSearchFocus
  }) => {
    if (isSearchbarHidden) return null;

    return (
      <RoleAccess role={role} fallback={<div />}>
        <div className={styles.searchbar} ref={box}>
          <DebounceInput
            className={styles.input}
            type='text'
            value={query}
            placeholder={placeholder}
            onFocus={onSearchFocus}
            onChange={onSearch}
            onKeyDown={onPressEnter}
            minLength={1}
            debounceTimeout={300}
          />

          <div className={styles.button}>
            <Button
              variant='contained'
              disabled={disabled}
              onClick={() => onButtonClick(queryValue)}
            >
              Пошук
            </Button>
          </div>
        </div>
      </RoleAccess>
    );
  }
);

export { Searchbar };
