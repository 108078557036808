import { setEditing, setOpenModal } from '@clients/store';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@shared/lib/use-typed-selector';
import { Breakpoint } from '@mui/material';

//TODO: check
type DataGridProps = {
  children: any;
  rows: any;
  columns: any;
  form: any;
  styles?: any;
  isOpenedModal?: boolean;
  isOpenModal?: boolean;
  onOpen?: (event: any) => void;
  onSubmit?: (value: any) => void;
  onReset?: (value: any) => void;
  onDropClick?: (value: any) => void;
  withoutButtons?: boolean;

  canUpdate?: boolean;

  dialogMaxWidth?: false | Breakpoint;
};

const useEditDataGridProps = ({
  withoutButtons,
  onSubmit,
  onOpen,
  isOpenModal
}: DataGridProps) => {
  const dispatch = useDispatch();
  const { editing } = useTypedSelector((state) => state.clients);

  const [open, setOpen] = React.useState(isOpenModal);

  const onButtonClick = (event) => {
    onSubmit(event);
    setEditing(false);
    setOpen(false);
  };
  const handleOpen = (event) => {
    setOpen(true);
    onOpen(event);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(setOpenModal(false));
  };

  return {
    withoutButtons,
    editing,
    onButtonClick,
    handleClose,
    handleOpen,
    open,
    setOpen
  };
};

export { useEditDataGridProps };
