import { CommonResponseType, Labels, NavElement } from '@api';
import { HiddenColumnFeature } from '@features';
import {
  Adapter,
  ClientAddressResponse,
  ClientContactsResponseDto,
  ClientDocumentResponse,
  ClientEstatesResponseDto,
  ClientIdentHistoryResponseDto,
  ClientSecuritiesResponseDto,
  ClientVehiclesResponseDto,
  ShortExtendedCustomerInfoResponse,
  ShortLegalExtendedCustomerInfoResponse
} from 'src/shared/api/models';
import {
  AttributeGroupsResponseDto,
  ClientAdditionalInfoRequestDto,
  ClientAttributesResponseDto,
  ClientIncomesResponseDto,
  ClientRelationshipResponseDto,
  ClientRestrictionResponseDto,
  contactTypes,
  Countries,
  CreateCustomerRequestDto,
  CreateIndividualEntepreneurDto,
  FatcaResponseDto,
  Segments
} from '@shared/api/data-contracts';

class ClientsState {
  public queryValue: string = null;
  public clientsID = null;
  public legalClientsID = null;
  //
  public legalEntityClients: ShortLegalExtendedCustomerInfoResponse[] = null;
  public legalEntityClient: ShortLegalExtendedCustomerInfoResponse = null;

  public responsibleManagers: { label: string; value: string }[] = [];

  /**
   * Clients
   */
  public contactTypes: contactTypes[] = [];
  public contactStatus: contactTypes[] = [];
  public clients: ShortExtendedCustomerInfoResponse[] = null;

  public countries: Countries[] = [];
  public countriesPending = false;
  public regions: Segments[] = [];
  public regionsPending = false;
  public settlements: Segments[] = [];
  public settlementsPending = false;

  public segments: Segments[] = [];
  public channels: Segments[] = [];
  public documentTypes: Segments[] = [];
  public documentStatus: Segments[] = [];
  public addressTypes: Segments[] = [];
  public settlementTypes: Segments[] = [];
  public warningCodes: Segments[] = [];
  public incomesSources: Segments[] = [];
  public streetTypes: Segments[] = [];
  public houseTypes: Segments[] = [];
  public estatesTypes: Segments[] = [];
  public currency: Segments[] = [];
  public vehiclesTypes: Segments[] = [];
  public vehiclesGroups: Segments[] = [];
  public vehiclesBrands: Segments[] = [];
  public securityTypes: Segments[] = [];
  public roomTypes: Segments[] = [];
  public attributesGroups: Segments[] = [];
  public activCodes: Segments[] = [];
  public relationshipRoles: Segments[] = [];
  public restrTypes: Segments[] = [];
  public restrSources: Segments[] = [];
  public restrCriteria: Segments[] = [];
  public ownershipTypes: Segments[] = [];
  public subTypes: Segments[] = [];
  public genders: Segments[] = [];
  public clientGoals: Segments[] = [];
  public provenanceSource: Segments[] = [];
  public quarterlyIncome: Segments[] = [];
  public monthlyIncome: Segments[] = [];
  public random: Segments[] = [];
  public fullName: ShortExtendedCustomerInfoResponse = null;
  public groupName: any;
  public contStatus: string;

  /**
   * Client
   */
  public fullClient: ShortExtendedCustomerInfoResponse = null;

  public client: ShortExtendedCustomerInfoResponse = null;

  public extendedCustomer: ClientAdditionalInfoRequestDto = null;

  /*
    Contact Information
  */
  public contact: ClientContactsResponseDto[] = null;
  public selectedContact: ClientContactsResponseDto = null;

  /*
    Document Information
  */

  public document: ClientDocumentResponse[] = null;
  public selectedDocument: ClientDocumentResponse = null;

  /*
    Adress Information
  */

  public adress: ClientAddressResponse[] = null;
  public selectedAddress: ClientAddressResponse = null;

  /*
    Ident Information
  */

  public ident: ClientIdentHistoryResponseDto[] = null;
  public selectedIdent: ClientIdentHistoryResponseDto = null;

  /*
    FATCA
  */
  public fatca: FatcaResponseDto = null;

  /*
    Estates Info
  */
  public ownership: Adapter = null;

  public selectedVehicle: ClientVehiclesResponseDto = null;

  public selectedSecurity: ClientSecuritiesResponseDto = null;
  public selectedEstate: ClientEstatesResponseDto = null;

  /*
    Vehicle Info
  */

  /*
    Securities Info
  */

  // Income Info

  public sources: ClientIncomesResponseDto = null;
  public selectedSource: ClientIncomesResponseDto = null;

  public relationships: ClientRelationshipResponseDto[] = null;
  public selectedRelationship: ClientRelationshipResponseDto = null;

  public attributes: ClientAttributesResponseDto[] = null;
  public attribute: ClientAttributesResponseDto = null;

  public attributeGroups: AttributeGroupsResponseDto[] = null;

  public activityCode: CreateIndividualEntepreneurDto = null;

  public goals: CreateCustomerRequestDto = null;

  public restrictions: ClientRestrictionResponseDto[] = null;
  public selectedRestriction: ClientRestrictionResponseDto = null;

  /**
   * Editing
   */
  public editing: boolean = false;
  public editable: boolean = false;

  /**
   * Is show block statuses inputs
   */
  public isShowPhoneBlocks: boolean = false;

  public isShowClientBlocks: boolean = false;

  public blockReasons: CommonResponseType[] = null;

  /**
   * Client gruops
   */
  public clientGroups: CommonResponseType[] = null;

  /**
   * Registration channels
   */
  public registrationChannels: CommonResponseType[] = null;

  /**
   * Open mobile side menu
   */
  public isMobileMenuOpened = false;

  /**
   * Import file
   */
  public importFile = null;

  /**
   * Recently added client id
   */
  public recentlyAddedClientId: string = null;

  /**
   * Client filters
   */
  public filters = new HiddenColumnFeature().getHiddenColumns('client');

  /**
   * Active tab
   */
  public activeTab: string = 'personalInfo';

  public isOpenModal: boolean = null;

  public navList: NavElement[] = [
    {
      label: 'Персональная інформація',
      anchor: 'personalSection',
      isNavActive: true,
      writable: true
    },
    {
      label: 'Контакти',
      anchor: 'idSection',
      isNavActive: false,
      writable: true
    },
    {
      label: 'Характеристики клієнта',
      anchor: 'charactersSection',
      isNavActive: false,
      writable: true
    },
    {
      label: 'Сегментація клієнта',
      anchor: 'segmentSection',
      isNavActive: false,
      writable: true
    },
    {
      label: 'Маркетингові активності',
      anchor: 'communicationHistorySection',
      isNavActive: false,
      writable: true
    }
  ];

  public sections = [
    {
      title: 'Контакти',
      isOpened: false
    },
    {
      title: 'Персональная інформація',
      isOpened: false
    },
    {
      title: 'Характеристики клієнта',
      isOpened: false
    },
    {
      title: 'Сегментація клієнта',
      isOpened: false
    },
    {
      title: 'Маркетингові активності', //'Історія комунікацій',
      isOpened: false
    }
  ];

  public labels: Labels[] = [
    {
      label: 'Дата народження',
      filterName: 'dateOfBirth'
    },

    {
      label: 'Прізвище',
      filterName: 'lastName'
    },
    {
      label: "Ім'я",
      filterName: 'firstName'
    },
    {
      label: 'По батькові',
      filterName: 'middleName'
    },
    {
      label: 'Стать',
      filterName: 'gender'
    }
  ];
}

export { ClientsState };
