import { InputProps } from '../input/input.props';
import { useEffect, useRef, useState } from 'react';
import { useClickOutside } from '@core/hooks';
import { DateUtils } from '@core/utils';

type DatePickerProps = InputProps & {
  calendarContainerClassName?: string;
  errorMessageClassName?: string;
  name?: string;
};

const useDatePickerProps = ({
  value,
  onChange,
  onEdit,
  touched,
  error
}: DatePickerProps) => {
  const container = useRef();

  const [visible, setVisible] = useState(false);

  const [formatted, setFormatted] = useState('');

  const onCalendarChange = (newValue: string, hide: boolean = false) => {
    setFormatted(DateUtils.formatDotsYear(newValue));

    onChange(DateUtils.formatDateOnly(newValue));

    onEdit?.();

    if (!hide) return;

    setVisible(false);
  };

  const onCalendarIconClick = () => {
    setVisible(!visible);
  };

  const onInputChange = (newValue: string) => {
    const empty = newValue.search(/\d/) === -1;
    const sections = newValue.toString().split('.');
    const [day, month, year] = sections;

    setFormatted(!empty ? newValue : null);

    onChange(!empty ? `${year}-${month}-${day}` : null);
  };

  useClickOutside(() => {
    setVisible(false);
  }, container.current);

  useEffect(() => {
    if (!value) {
      setFormatted('');
      return;
    }

    const sections = value.toString().split('-');

    switch (true) {
      case !value.toString().includes('_') && DateUtils.isValid(value): {
        const result = DateUtils.formatDateEurHypen(value);

        if (result == formatted) return;

        setFormatted(result);

        break;
      }

      case sections.length == 3: {
        const [year, month, day] = sections;
        const result = `${day}.${month}.${year}`;

        if (result == formatted) return;

        setFormatted(result);

        break;
      }
    }
  }, [value]);

  return {
    isShowError: !!(touched && error),
    visible,
    container,
    formatted,
    onInputChange,
    onCalendarChange,
    onCalendarIconClick
  };
};

export { DatePickerProps, useDatePickerProps };
