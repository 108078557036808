import React from 'react';
import Picker from 'react-day-picker';
import moment from 'moment';
import classNames from 'classnames';
import { hoc } from '@core/utils';
import { months, weekdaysShort } from './calendar.constants';
import { useCalendarProps } from './calendar.props';
import styles from './calendar.module.scss';
import {
  ChevronLeft,
  ChevronRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight
} from '@mui/icons-material';

/**
 * <Calendar />
 */
const Calendar = hoc(
  useCalendarProps,
  ({
    date,
    isValid,
    className,
    onPickerChange,
    showNextYear,
    showPreviousYear
  }) => (
    <Picker
      fixedWeeks
      selectedDays={date}
      weekdaysShort={weekdaysShort}
      month={isValid ? date : null}
      onDayClick={onPickerChange}
      className={classNames(styles.calendar, className)}
      classNames={styles as any}
      firstDayOfWeek={1}
      captionElement={() => null}
      navbarElement={({ month, onPreviousClick, onNextClick }) => {
        const newDate = moment(month);
        const caption = `${months[newDate.month()]} ${newDate.year()}`;

        return (
          <div className={styles.navbar}>
            <div className={styles.controls}>
              <KeyboardDoubleArrowLeft
                className={styles.back}
                onClick={() => showPreviousYear()}
              />
            </div>

            <div className={styles.controls}>
              <ChevronLeft
                className={styles.back}
                onClick={() => onPreviousClick()}
              />
            </div>

            <div className={styles.monthCaption}>{caption}</div>

            <div className={styles.controls}>
              <ChevronRight
                className={styles.next}
                onClick={() => onNextClick()}
              />
            </div>

            <div className={styles.controls}>
              <KeyboardDoubleArrowRight
                className={styles.next}
                onClick={() => showNextYear()}
              />
            </div>
          </div>
        );
      }}
    />
  )
);

export { Calendar };
