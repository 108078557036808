import {
  ClientContactsResponseDto,
  ClientDocumentResponse,
  ClientEstatesResponseDto,
  ClientIdentHistoryResponseDto,
  ClientSecuritiesResponseDto,
  ClientVehiclesResponseDto,
  ShortExtendedCustomerInfoResponse
} from './models';
import {
  AttributeGroupsResponseDto,
  ClientIncomesResponseDto,
  ClientRelationshipResponseDto,
  CreateIndividualEntepreneurDto,
  FatcaResponseDto
} from './data-contracts';
import { HttpService, MockHttp } from '@shared/lib/http';

class CustomerService extends MockHttp {
  public constructor(private http: HttpService) {
    super();
  }
  /*
        Get Customers
    */
  public getLegalCustomers = async (data) =>
    this.http.request({
      url: 'v1/legal-entity/search',
      method: 'POST',
      data
    });
  public getContactTypes = async () =>
    this.http.request({
      url: 'v1/contacts/directory/type'
    });
  public getRandDirectory = async (
    clientId: ShortExtendedCustomerInfoResponse['id']
  ) =>
    this.http.request({
      url: `/v1/directories/${clientId}`
    });
  public getGenders = async () =>
    this.http.request({
      url: '/v1/directories/genders'
    });
  public getClientGoals = async () =>
    this.http.request({
      url: '/v1/directories/account_goals'
    });
  public getContactStatus = async () =>
    this.http.request({
      url: 'v1/contacts/directory/status'
    });
  public getCountryNames = async () =>
    this.http.request({
      url: 'v1/addresses/directory/country'
    });
  public getSegments = async () =>
    this.http.request({
      url: 'v1/client-segment'
    });
  public getChannels = async () =>
    this.http.request({
      url: 'v1/registration-channels'
    });
  public getDocumentTypes = async () =>
    this.http.request({
      url: '/v1/directories/document_type'
    });
  public getDocumentStatus = async () =>
    this.http.request({
      url: '/v1/directories/document_status'
    });
  public getGoals = async () =>
    this.http.request({
      url: '/v1/directories/account_goals'
    });
  public getAttributesGroups = async () =>
    this.http.request({
      url: '/v1/additional-attributes/groups'
    });
  public getActivityCodesDictionary = async () =>
    this.http.request({
      url: 'v1/individual-entrepreneur/economic-activity-codes'
    });
  public getRelationshipRoles = async () =>
    this.http.request({
      url: 'v1/relationships/relationship-roles/directory'
    });

  public getAddressTypes = async () =>
    this.http.request({
      url: 'v1/addresses/directory/address-type'
    });
  public getRegions = async (countryId: string) =>
    this.http.request({
      url: 'v1/addresses/directory/region',
      params: { countryId }
    });
  public getSettlementTypes = async () =>
    this.http.request({
      url: '/v1/directories/settlement_types'
    });
  public getRestrTypes = async () =>
    this.http.request({
      url: '/v1/directories/restr_type'
    });
  public getRestrSources = async () =>
    this.http.request({
      url: '/v1/directories/restr_source'
    });
  public getProvenanceSource = async () =>
    this.http.request({
      url: '/v1/incomes/directory/provenance-source'
    });
  public getMonthlyIncome = async () =>
    this.http.request({
      url: '/v1/incomes/directory/monthly-income'
    });
  public getQuarterlyIncome = async () =>
    this.http.request({
      url: '/v1/incomes/directory/quarterly-income'
    });
  public getRestrCriteria = async () =>
    this.http.request({
      url: '/v1/directories/restr_criteria'
    });

  public getSettlements = async (regionId: string) =>
    this.http.request({
      url: '/v1/addresses/directory/settlement',
      params: { regionId }
    });

  public getCodes = async () =>
    this.http.request({
      url: 'v1/identification-history/warning-code/directory'
    });
  public getSources = async () =>
    this.http.request({
      url: '/v1/incomes/directory/source-list'
    });
  public getOwnershipTypes = async () =>
    this.http.request({
      url: '/v1/legal-entity/ownership-types'
    });
  public getSubTypes = async () =>
    this.http.request({
      url: 'v1/general-client/client-sub-types'
    });
  public getStreetTypes = async () =>
    this.http.request({
      url: 'v1/addresses/directory/street-type'
    });

  public getEstatesTypes = async () =>
    this.http.request({
      url: 'v1/ownership/estates-type'
    });
  public getCurrency = async () =>
    this.http.request({
      url: 'v1/ownership/currency'
    });
  public getVehiclesTypes = async () =>
    this.http.request({
      url: 'v1/ownership/vehicles-types'
    });
  public getVehiclesGroups = async () =>
    this.http.request({
      url: 'v1/ownership/vehicle-group'
    });
  public getVehiclesBrands = async () =>
    this.http.request({
      url: 'v1/ownership/vehicle-brands'
    });
  public getSecurityTypes = async () =>
    this.http.request({
      url: 'v1/ownership/securities-type'
    });
  public getHouseTypes = async () =>
    this.http.request({
      url: '/v1/directories/house_types'
    });
  public getRoomTypes = async () =>
    this.http.request({
      url: '/v1/directories/room_types'
    });

  public getLegalCustomer = async (
    clientId: ShortExtendedCustomerInfoResponse['id']
  ) =>
    this.http.request({
      url: `/v1/legal-entity/short/${clientId}`
    });
  public getFullProfileCustomer = async (
    clientId: ShortExtendedCustomerInfoResponse['id']
  ) =>
    this.http.request({
      url: `v1/legal-entity/full-profile/${clientId}`
    });
  public getFullProfileFoCustomer = async (
    clientId: ShortExtendedCustomerInfoResponse['id']
  ) =>
    this.http.request({
      url: `/v1/customer/full-profile/${clientId}`
    });

  public getCustomersById = async (data) =>
    this.http.request({
      url: 'v1/customer/short/multiple',
      method: 'POST',
      data
    });

  public getLegalCustomersById = async (data) =>
    this.http.request({
      url: '/v1/legal-entity/short/multiple',
      method: 'POST',
      data
    });
  public getCustomers = async (data) =>
    this.http.request({
      url: 'v1/customer/search',
      method: 'POST',
      data
    });

  public clientsFilter = ({ ...data }) =>
    this.http.request({
      method: 'POST',
      url: `v1/customer/search`,
      data
    });
  public legalClientsFilter = ({ ...data }) =>
    this.http.request({
      method: 'POST',
      url: `v1/legal-entity/search`,
      data
    });
  /*
    Get Customer
*/
  public getCustomer = async (
    clientId: ShortExtendedCustomerInfoResponse['id']
  ) =>
    this.http.request({
      url: `/v1/customer/short/${clientId}`
    });
  public getCustomerFullName = async (
    clientId: ShortExtendedCustomerInfoResponse['id']
  ) =>
    this.http.request({
      url: `/v1/customer/short/${clientId}`
    });

  /*
    Get Contact Type and Status
*/

  public getContactInfo = async (
    clientId: ShortExtendedCustomerInfoResponse['id']
  ) =>
    this.http.request({
      url: `/v1/contacts`,
      params: { clientId },
      method: 'GET'
    });

  public getContact = async (contactId: ClientContactsResponseDto['id']) =>
    this.http.request({
      url: `/v1/contacts/${contactId}`,
      method: 'GET'
    });

  public updateContact = async ({ id, ...data }) =>
    this.http.request({
      url: `/v1/contacts/${id}`,
      method: 'PUT',
      data
    });

  /*6f32feaf-5db4-4cf6-8b8c-1c0b9211ba79
        Get Document Info
      */

  public getDocumentInfo = async (
    clientId: ShortExtendedCustomerInfoResponse['id']
  ) =>
    this.http.request({
      url: `/v1/documents?clientId=${clientId}`
    });

  public getDocument = async (documentId: ClientDocumentResponse['id']) =>
    this.http.request({
      url: `/v1/documents/${documentId}`
    });

  public updateDocument = async ({ id, ...data }) =>
    this.http.request({
      url: `/v1/documents/${id}`,
      method: 'PUT',
      data
    });

  /*/v1/documents/:documentId
        Get Adress Info
      */
  public getAdressInfo = async (
    clientId: ShortExtendedCustomerInfoResponse['id']
  ) =>
    this.http.request({
      url: `/v1/addresses?clientId=${clientId}`
    });

  public getAddress = async (
    clientId: ShortExtendedCustomerInfoResponse['id']
  ) =>
    this.http.request({
      url: `/v1/addresses/${clientId}`
    });

  public updateAddress = async ({ id, ...data }) =>
    this.http.request({
      url: `/v1/addresses/${id}`,
      method: 'PUT',
      data
    });
  /*/v1/adresses/:adressId
          Get Identification Information
        */

  public getIdentInfo = async (
    clientId: ShortExtendedCustomerInfoResponse['id']
  ) =>
    this.http.request({
      url: `/v1/identification-history?clientId=${clientId}`
    });

  public getIdent = async (identId: ClientIdentHistoryResponseDto['id']) =>
    this.http.request({
      url: `/v1/identification-history/${identId}`
    });

  /* /v1/identification-history/
        FATCA
      */
  public getFatcaInfo = async (clientId: FatcaResponseDto['id']) =>
    this.http.request({
      url: `/v1/fatca?clientId=${clientId}`
    });

  /*
        Estates
      */
  public getOwnershipInfo = async (
    clientId: ShortExtendedCustomerInfoResponse['id']
  ) =>
    this.http.request({
      url: `/v1/ownership`,
      params: { clientId }
    });

  public getVehicle = async (vehicleId: ClientVehiclesResponseDto['id']) =>
    this.http.request({
      url: `/v1/ownership/vehicles?vehicleId=${vehicleId}`
    });
  public updateVehicle = async ({ id, ...data }) =>
    this.http.request({
      url: `/v1/ownership/vehicles/${id}`,
      method: 'PUT',
      data
    });
  public getEstate = async (estateId: ClientEstatesResponseDto['id']) =>
    this.http.request({
      url: `v1/ownership/estates?estateId=${estateId}`
    });
  public updateEstate = async ({ id, ...data }) =>
    this.http.request({
      url: `/v1/ownership/estates/${id}`,
      method: 'PUT',
      data
    });

  public getSecurity = async (securityId: ClientSecuritiesResponseDto['id']) =>
    this.http.request({
      url: `/v1/ownership/securities?securityId=${securityId}`
    });
  public updateSecurity = async ({ id, ...data }) =>
    this.http.request({
      url: `/v1/ownership/securities/${id}`,
      method: 'PUT',
      data
    });

  public getIncomeInfo = async (clientId: ClientIncomesResponseDto['id']) =>
    this.http.request({
      url: `/v1/incomes?clientId=${clientId}`
    });
  public getSelectedIncomeInfo = async (
    incomeId: ClientIncomesResponseDto['id']
  ) =>
    this.http.request({
      url: `/v1/incomes/${incomeId}`
    });

  public updateIdentification = async ({ id, ...data }) =>
    this.http.request({
      url: `/v1/identification-history/${id}`,
      method: 'PUT',
      data
    });
  public updateIncome = async ({ id, ...data }) =>
    this.http.request({
      url: `/v1/incomes/${id}`,
      method: 'PUT',
      data
    });
  public updateActivityCode = async ({ id, ...data }) =>
    this.http.request({
      url: `/v1/individual-entrepreneur/${id}`,
      method: 'PUT',
      data
    });
  public updateFatca = async ({ id, ...data }) =>
    this.http.request({
      url: `/v1/fatca?clientId=${id}`,
      method: 'PUT',
      data
    });
  public updateCustomer = async ({ id, ...data }) =>
    this.http.request({
      url: `/v1/customer/${id}`,
      method: 'PUT',
      data
    });

  public updateLegalCustomer = async ({ id, ...data }) =>
    this.http.request({
      url: `/v1/legal-entity/${id}`,
      method: 'PUT',
      data
    });

  public getRelationshipsInfo = async (
    clientId: ShortExtendedCustomerInfoResponse['id']
  ) =>
    this.http.request({
      url: `v1/relationships?clientId=${clientId}`
    });

  public getRelationship = async (
    relationshipId: ClientRelationshipResponseDto['id']
  ) =>
    this.http.request({
      url: `/v1/relationships/${relationshipId}`
    });
  public updateRelationship = async ({ id, ...data }) =>
    this.http.request({
      url: `/v1/relationships/${id}`,
      method: 'PUT',
      data
    });

  public getAttributeGroupInfo = async () =>
    this.http.request({
      url: `v1/additional-attributes/groups?clientType=CUSTOMER`
    });
  public getAttributes = async ({ groupId, clientId }) =>
    this.http.request({
      url: `/v1/additional-attributes?clientId=${clientId}&groupId=${groupId}&clientType=CUSTOMER`
    });
  public getAttribute = async (attributeId: AttributeGroupsResponseDto['id']) =>
    this.http.request({
      url: `/v1/additional-attributes/${attributeId}`
    });
  public updateAttribute = async ({ id, ...data }) =>
    this.http.request({
      url: `/v1/additional-attributes/${id}`,
      method: 'PUT',
      data
    });

  public getCodesInfo = async (
    clientId: CreateIndividualEntepreneurDto['clientId']
  ) =>
    this.http.request({
      url: `v1/individual-entrepreneur?clientId=${clientId}`
    });

  public getRestriction = async (
    restrictionId: ShortExtendedCustomerInfoResponse['id']
  ) =>
    this.http.request({
      url: `/v1/restrictions/${restrictionId}`
    });

  public getRestrictionInfo = async (
    clientId: ShortExtendedCustomerInfoResponse['id']
  ) =>
    this.http.request({
      url: `/v1/restrictions?clientId=${clientId}`
    });

  public getResponsibleManagers = async () =>
    this.http.request<
      {
        id: string;
        employeeLdapId: string;
        clientId: string;
        isActive: boolean;
        createdAt: string;
        updatedAt: string;
        managerProfile: {
          firstName: string;
          lastName: string;
          middleName: string;
          dateOfBirth?: string;
        };
      }[]
    >({
      url: '/v1/responsible-manager/search',
      method: 'POST'
    });
}

export { CustomerService };
