export const getCompanyName = ({
  companyName,
  firstName,
  lastName,
  middleName
}: {
  firstName: string;
  lastName: string;
  middleName: string;
  companyName: string;
}): string => {
  if (!companyName && !firstName) {
    return '';
  }

  return companyName ? companyName : `${lastName} ${firstName} ${middleName}`;
};
