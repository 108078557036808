import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  getDocument,
  getDocumentInfo,
  getDocumentStatus,
  getDocumentTypes,
  setEditing,
  updateDocument
} from '@clients/store';
import { useFormik } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import { useLocation } from 'react-router';
import { isEqual } from 'lodash';

import { useClientId } from '../../hooks';
import { useTypedSelector } from '@shared/lib/use-typed-selector';
import moment from 'moment';

const useDocumentProps = () => {
  const dispatch = useDispatch();
  const clientId = useClientId();
  const { pathname } = useLocation();

  const { selectedDocument, isOpenModal, document } = useTypedSelector(
    (state) => state.clients
  );

  const documentInfo = useFormik({
    initialValues: {
      docSeries: selectedDocument?.series,
      docNumber: selectedDocument?.number,
      archiveId: selectedDocument?.archiveId,
      typeId: selectedDocument?.type.title,
      issueDate: selectedDocument?.dateIssue,
      validUntilDate: selectedDocument?.validUntil,
      issuedBy: selectedDocument?.issuedBy,
      statusId: selectedDocument?.status.title
    },
    onSubmit: (values, { setSubmitting }) => {
      const updateValues = {
        docSeries: values?.docSeries,
        docNumber: values?.docNumber,
        archiveId: values?.archiveId,

        issueDate: values?.issueDate,
        validUntilDate: values?.validUntilDate,
        issuedBy: values?.issuedBy
      };
      dispatch(updateDocument({ ...updateValues, id: selectedDocument.id }));
      setSubmitting(false);
      dispatch(setEditing(false));
      dispatch(getDocumentInfo(clientId));
    },
    enableReinitialize: true
  });

  const onOpen = ({ id }: { id: string }) => dispatch(getDocument(id));

  useEffect(() => {
    dispatch(
      setEditing(!isEqual(documentInfo.values, documentInfo.initialValues))
    );
  }, [documentInfo.values, documentInfo.initialValues]);

  useEffect(() => {
    dispatch(getDocumentInfo(clientId));
    dispatch(getDocumentTypes(''));
    dispatch(getDocumentStatus(''));
  }, [pathname]);

  return {
    identification: (document ?? []).map((value) => ({
      id: value?.id,
      type: value?.type?.title,
      status: value?.status.title,
      validUntil: value?.validUntil
        ? moment(value?.validUntil).format('DD-MM-YYYY')
        : ''
    })),
    documentInfo,
    columns,
    isOpenModal,
    onOpen
  };
};

const columns: GridColDef[] = [
  {
    field: 'type',
    headerName: 'Тип',
    width: 320,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'status',
    headerName: 'Статус',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'validUntil',
    headerName: 'Дійсний до',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  }
];

export { useDocumentProps };
