import { useRouteMatch } from 'react-router-dom';
import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAppData } from '@store/general';
import { useMediaPoints } from '@core';
import { navigate } from '@store/router';
import { deleteRecentlyAddedId } from '@clients/store';
import { useTypedSelector } from '@shared/lib/use-typed-selector';

type MainLayoutProps = {
  withRecentlyAddedClientAlert?: boolean;
  basePath: string;

  left?: ReactNode;

  mainPartClassName?: string;
  className?: string;
};

const useMainLayout = ({
  withRecentlyAddedClientAlert,
  basePath
}: MainLayoutProps) => {
  const dispatch = useDispatch();
  const { isTabletDownOrEqual } = useMediaPoints();
  const isMobile = isTabletDownOrEqual && useRouteMatch(`${basePath}/popup`);

  const { menu, user } = useTypedSelector((state) => state.general);
  const { recentlyAddedClientId, isMobileMenuOpened } = useTypedSelector(
    (state) => state.clients
  );

  const onEditClick = () => {
    dispatch(deleteRecentlyAddedId(recentlyAddedClientId));
    dispatch(navigate(`/clients/${recentlyAddedClientId}/edit`));
  };

  const onCancelClick = () => {
    dispatch(deleteRecentlyAddedId(recentlyAddedClientId));
  };

  useEffect(() => {
    dispatch(getAppData());
  }, []);

  return {
    menu,
    user,
    isMobile,
    isMobileMenuOpened,
    recentlyAddedClientId,
    withRecentlyAddedClientAlert,
    onEditClick,
    onCancelClick
  };
};

export { useMainLayout };
