import React from 'react';
import classNames from 'classnames';
import { AlertProps } from './alert.props';
import styles from './alert.module.scss';
import { CheckCircle } from '@mui/icons-material';

/**
 * Renders Alert
 */
const Alert: React.FC<AlertProps> = ({
  children,
  className,
  message,
  description
}) => (
  <div className={classNames(styles.alert, className)}>
    <div>
      <CheckCircle className={styles.icon} name='check-circle' />
    </div>
    <div className={styles.main}>
      <h2 className={styles.message}>{message}</h2>
      <div className={styles.description}>{description}</div>
      {children}
    </div>
  </div>
);

export { Alert };
