import React from 'react';
import { hoc } from '@core';
import { Field } from '@core/components';
import styles from './adress.module.scss';
import { EditDataGrid } from '@shared/ui/edit-data-grid';
import { columns, useAdressProps } from './adress.props';
import { SectionLayout } from '../atoms';
import { Role } from '@entities/role';

const Adress = hoc(
  useAdressProps,
  ({
    isRegionEntered,
    isCountryEntered,

    countriesPending,
    regionsPending,
    settlementsPending,

    regionsNames,
    countriesNames,
    types,
    strTypes,
    identification,
    addressInfo,
    addressValues,
    onOpen,
    houseTypesNames,
    roomTypesNames,
    settlementsNames,
    onCountryChange,
    onRegionChange,
    onSettlementChange
  }) => (
    <SectionLayout read={Role.AddressesRead} update={Role.AddressesUpdate}>
      {({ canUpdate }) => (
        <EditDataGrid
          form={addressInfo}
          rows={identification}
          columns={columns}
          onOpen={onOpen}
          onSubmit={addressInfo.handleSubmit}
          onReset={addressInfo.handleReset}
          canUpdate={canUpdate}
        >
          <Field.Select
            className={styles.inputMargin}
            name='addressTypeId'
            options={types}
            label='Тип'
            disabled={!canUpdate}
          />
          <Field.Select
            className={styles.inputMargin}
            name='countryId'
            options={countriesNames}
            label='Країна'
            disabled={!canUpdate}
            onChange={onCountryChange}
            searchable
            notFoundText='Не знайдено значень'
            loading={countriesPending}
          />
          <Field.Select
            className={styles.inputMargin}
            name='settlementRegionId'
            options={regionsNames}
            disabled={!canUpdate || !isCountryEntered}
            label='Область'
            onChange={onRegionChange}
            searchable
            notFoundText='Не знайдено значень'
            loading={regionsPending}
          />

          <Field.Select
            className={styles.inputMargin}
            name='settlementId'
            options={settlementsNames}
            label='Населений пункт'
            disabled={!canUpdate || !isRegionEntered}
            searchable
            notFoundText='Не знайдено значень'
            loading={settlementsPending}
            onChange={onSettlementChange}
          />

          <Field.Input
            className={styles.input}
            name='settlementTypeId'
            theme='primary'
            editable={false}
            label='Тип населеного пункту'
          />

          <Field.Select
            className={styles.inputMargin}
            name='streetTypeId'
            options={strTypes}
            label='Тип вулиці'
            disabled={!canUpdate}
          />
          <Field.Select
            className={styles.inputMargin}
            name='houseTypeId'
            options={houseTypesNames}
            label='Тип Будинку'
            disabled={!canUpdate}
          />
          <Field.Select
            className={styles.inputMargin}
            name='roomTypeId'
            options={roomTypesNames}
            disabled={!canUpdate}
            label='Тип Приміщення'
          />

          {addressValues?.map((value) => (
            <Field.Input
              className={styles.input}
              name={value.name}
              label={value.label}
              theme='primary'
              key={value.name + value.label}
              editable={canUpdate}
            />
          ))}
          <Field.Input
            className={styles.fullWidthInput}
            name='fullAddress'
            editable={canUpdate}
            label='Повна адреса'
          />
        </EditDataGrid>
      )}
    </SectionLayout>
  )
);

export { Adress };
