import { hoc } from '@core';
import { Button, Field } from '@core/components';
import { useIncomeProps } from './income.props';
import styles from './income.module.scss';
import React from 'react';
import { SectionLayout } from '../atoms';
import { Role } from '@entities/role';
import { Divider } from '@mui/material';
import { Form } from '@core/form';

const Incomes = hoc(
  useIncomeProps,
  ({ incomesForm, source = [], quart, month, provenanceSources = [] }) => (
    <SectionLayout read={Role.IncomesRead} update={Role.IncomesUpdate}>
      {({ canUpdate }) => (
        <Form use={incomesForm}>
          <Field.Input
            name='details'
            label='Опис способу надходжень'
            className={styles.input}
            theme='primary'
            editable={canUpdate}
          />

          {/* <Field.Input
            className={styles.input}
            theme='primary'
            name='documentId'
            label='Підтверджувальний документ'
            editable={canUpdate}
          /> */}

          <Field.Select
            className={styles.inputMargin}
            name='quarterlyIncomeId'
            label='Квартальний дохід'
            options={quart}
            disabled={!canUpdate}
          />

          <Divider style={{ width: '100%', margin: '20px auto' }} />

          <Field.NewMultipleSelect
            name='sourceIds'
            names={source || []}
            inputLabel='Джерела доходу'
            disabled
          />

          {/** FIXME */}
          {window.location.href.includes('fo') && (
            <>
              <Field.Select
                name='monthlyIncomeId'
                className={styles.inputMargin}
                options={month}
                label='Місячний дохід'
                disabled={!canUpdate}
              />

              <Field.NewMultipleSelect
                name='provenanceSourceIds'
                names={provenanceSources}
                inputLabel='Походження особистого доходу'
                disabled
              />
            </>
          )}
          <div className={styles.actions}>
            <Button
              onClick={() => incomesForm.handleSubmit()}
              disabled={!incomesForm.dirty}
            >
              Зберегти
            </Button>
          </div>
        </Form>
      )}
    </SectionLayout>
  )
);

export { Incomes };
