import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { hoc } from '@core';
import { useMenuProps } from './menu.props';
import styles from './menu.module.scss';
import { Logout } from '@mui/icons-material';
import { RoleAccess } from '@entities/role';

/**
 * Renders Menu
 */
const Menu = hoc(
  useMenuProps,
  ({ links, activeLinkIndex, onLogoutClick, onLinkClick, userDisplayRole }) => (
    <nav className={styles.menu}>
      <ul className={styles.siteNav}>
        <li className={classNames(styles.item, styles.itemAvatar)}>
          <a className={classNames(styles.link)}>
            <div className={classNames(styles.avatar, styles.placeholder)}>
              {userDisplayRole && <span>{userDisplayRole}</span>}
            </div>

            {userDisplayRole && (
              <span className={styles.navText}>{userDisplayRole}</span>
            )}
          </a>
        </li>

        {links.map((link, index) => {
          const isActive = activeLinkIndex === index;

          return (
            <RoleAccess key={link.to} role={link.role} fallback={null}>
              <li
                className={classNames(styles.item, {
                  [styles.itemActive]: isActive
                })}
              >
                <Link
                  to={link.to}
                  className={classNames(styles.link, {
                    [styles.linkActive]: isActive
                  })}
                  onClick={onLinkClick}
                >
                  {link.icon && <link.icon className={styles.icon} />}

                  <span className={styles.navText}>{link.text}</span>
                </Link>
              </li>
            </RoleAccess>
          );
        })}

        <li
          className={classNames(styles.item, styles.logOut)}
          onClick={onLogoutClick}
        >
          <a className={styles.link}>
            <Logout className={styles.icon} />

            <span className={styles.navText}>Вийти</span>
          </a>
        </li>
      </ul>
    </nav>
  )
);

export { Menu };
