import { Payload, Saga } from 'redux-chill';
import {
  authorize,
  changeTriedToRefresh,
  fetchError,
  getAppData,
  loading,
  logout,
  start
} from './actions';
import { StoreContext } from '@store/context';
import { StorageKey } from '@store/storage';
import { call, delay, put, select } from 'redux-saga/effects';
import { State } from '@store';
import { show } from '@store/toast';
import { UserException } from '@api';
import { enviroment } from '@env';
import { Called } from '@core';
import { setRoles } from '@entities/role/model/actions';
import { Role } from '@entities/role';

class GeneralSaga {
  /**
   * Authorize
   */
  @Saga(authorize)
  public *authorize(
    token: Payload<typeof authorize>,
    { storage }: StoreContext
  ) {
    storage.set(StorageKey.token, token.token);
    storage.set(StorageKey.refreshToken, token.refreshToken);
    yield put(changeTriedToRefresh(false));
    yield put(authorize.success());
  }

  /**
   * Get authorized data
   */
  @Saga(getAppData)
  public *getAppData(_, { api }: StoreContext) {
    try {
      yield put(loading.start());
      const { data }: Called<typeof api.user.current> = yield call(
        api.user.current
      );

      yield put(getAppData.success(data));
      yield put(setRoles.asArray(data.accessGroup.roles as Role[]));
    } catch (error) {
      yield put(fetchError(error));
    } finally {
      yield put(loading.finish());
    }
  }

  /**
   * Start app
   */
  @Saga()
  public *start({ api }: StoreContext) {
    try {
      const { data }: Called<typeof api.user.current> = yield call(
        api.user.current
      );

      yield put(getAppData.success(data));
      yield put(setRoles.asArray(data.accessGroup.roles as Role[]));

      yield put(start({ authorized: true }));
    } catch (e) {
      yield put(start({ authorized: false }));
    }
  }

  /**
   * Logout
   */
  @Saga(logout)
  public *logout() {
    try {
      yield put(loading.start());

      yield put(start({ authorized: false }));

      window.location.replace(enviroment.logoutUrl);
    } finally {
      yield put(loading.finish());
    }
  }

  /**
   * Fetch error
   */
  @Saga(fetchError)
  public *fetchError({ error }: Payload<typeof fetchError>) {
    const {
      general: { isTriedToRefresh }
    }: State = yield select();

    const {
      status,
      data: { code }
    } = error;

    if (status === 404 && isTriedToRefresh) {
      yield put(authorize.failed());
    }

    if (code === UserException.unauthorizedException) return;

    yield put(show('Час дії сесії вичерпано', 'warning'));
    yield put(authorize.failed());
    yield delay(1000);
    yield put(logout());
  }
}

export { GeneralSaga };
