import React from 'react';
import { hoc } from '@core';
import { Field } from '@core/components';
import { EditDataGrid } from '@shared/ui/edit-data-grid';

import { columns, useEstatesProps } from './estates.props';
import styles from './estates.module.scss';

const Estates = hoc(
  useEstatesProps,
  ({
    identification,
    estatesInfo,
    onOpen,
    currencyNames,
    types,
    canUpdate
  }) => (
    <>
      <h1>Нерухомість</h1>

      <EditDataGrid
        form={estatesInfo}
        rows={identification}
        columns={columns}
        styles={{ minHeight: 400, width: '100%' }}
        onOpen={onOpen}
        onSubmit={estatesInfo.handleSubmit}
        onReset={estatesInfo.handleReset}
        canUpdate={canUpdate}
      >
        <Field.Input
          className={styles.input}
          theme='primary'
          name='cost'
          label='Ціна'
          editable={canUpdate}
        />

        <Field.Select
          className={styles.inputMargin}
          name='typeId'
          options={types}
          label={'Тип'}
          disabled={!canUpdate}
        />

        <Field.Input
          className={styles.input}
          theme='primary'
          name='addressId'
          label='Адреса'
          editable={canUpdate}
        />

        <Field.Select
          className={styles.inputMargin}
          name='currencyId'
          options={currencyNames}
          label={'Валюта'}
          disabled={!canUpdate}
        />

        <Field.Date
          className={styles.input}
          theme='primary'
          name='purchaseDate'
          label='Дата покупки'
          editable={canUpdate}
          disabled={!canUpdate}
        />
        <Field.Input
          className={styles.input}
          theme='primary'
          name='comment'
          label='Коментар'
          editable={canUpdate}
          disabled={!canUpdate}
        />
      </EditDataGrid>
    </>
  )
);

export { Estates };
