import { RouterSaga } from './router';
import { GeneralSaga } from './general';
import { ToastSaga } from './toast';
import { ClientsSaga } from '@clients/store';

import { DirectorySaga } from '@entities/directory';

/**
 * App sagas
 */
const sagas = [
  new ToastSaga(),
  new RouterSaga(),
  new GeneralSaga(),
  new ClientsSaga(),
  new DirectorySaga()
];

export { sagas };
