import { SearchDirectoryItemsContract } from '@shared/api';

const getTableRowKey = (item: SearchDirectoryItemsContract) => item.id;

const baseKeysOrder: (keyof SearchDirectoryItemsContract)[] = ['id', 'title'];

const productsKeysOrder: (keyof SearchDirectoryItemsContract)[] = [
  ...baseKeysOrder,
  'owner'
];

export { getTableRowKey, baseKeysOrder, productsKeysOrder };
