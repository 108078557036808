import React, { memo } from 'react';
import classNames from 'classnames';
import Select, { components, FormatOptionLabelMeta } from 'react-select';
import { SingleSelectOption } from '@api';
import { hoc } from '@core/utils';
import { useNewSelect } from './new-select.props';
import styles from './new-select.module.scss';
import {
  customComponents,
  filterOption,
  selectStyles,
  theme
} from './new-select.constants';
import { WindowedMenuList } from 'react-windowed-select';
import { LabelContext } from './new-select.context';

const NewSelect = memo(
  hoc(
    useNewSelect,
    ({
      isTabletDownOrEqual,
      selectRef,
      _onChange,
      _isOptionSelected,
      _noOptionMessage,
      options: _____ = [],
      onChange: ____,
      isOptionSelected: ______,
      selectOptions,
      onSelectChange: ___,
      noOptionMessage: __,
      label,
      isError,
      withFileOption: _,
      isOptimized,
      caption,
      className,
      classnames,
      closeMenuOnScroll,
      ...props
    }) => (
      <div className={classnames?.root}>
        <LabelContext.Provider value={label}>
          <Select
            {...props}
            className={classNames(className, classnames?.select)}
            ref={selectRef}
            filterOption={filterOption}
            isOptionSelected={isOptimized ? undefined : _isOptionSelected}
            options={selectOptions as any}
            onChange={_onChange}
            noOptionsMessage={_noOptionMessage}
            formatOptionLabel={isOptimized ? undefined : formatOptionLabel}
            hideSelectedOptions={false}
            onMenuClose={() => selectRef.current?.blur?.()}
            blurInputOnSelect={false}
            placeholder=''
            menuPlacement='auto'
            menuPosition={isTabletDownOrEqual ? 'absolute' : 'fixed'}
            captureMenuScroll
            closeMenuOnSelect={isOptimized || !props.isMulti}
            closeMenuOnScroll={isTabletDownOrEqual ? true : closeMenuOnScroll}
            theme={theme}
            components={{
              ...customComponents,
              MenuList: isOptimized ? WindowedMenuList : components.MenuList,
              ...props.components
            }}
            styles={{
              ...selectStyles,
              control: (controlStyles) => ({
                ...controlStyles,
                borderColor: isError
                  ? '#D92C2C !important'
                  : controlStyles.borderColor
              })
            }}
          />

          {caption && (
            <p className={classNames(styles.caption, classnames?.caption)}>
              {caption}
            </p>
          )}
        </LabelContext.Provider>
      </div>
    )
  )
);

const formatOptionLabel = (
  option: SingleSelectOption,
  labelMeta: FormatOptionLabelMeta<SingleSelectOption>
) => {
  const highlighted = option.label?.replace(
    new RegExp(labelMeta.inputValue?.toString(), 'gi'),
    (highlighted) => highlighted.bold()
  );

  return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
};

export { NewSelect };
