import React from 'react';
import { ReplaceWithFieldProps } from '@core/form';
import { NewInputProps, NewTextAreaProps } from './new-text-field.props';
import { NewInput } from './new-input.component';
import { NewTextArea } from './new-text-area.component';

const NewInputForm: React.VFC<ReplaceWithFieldProps<NewInputProps>> = ({
  onChange,
  touched,
  error,
  isError: _,
  onTouch: __,
  ...props
}) => (
  <NewInput
    onChange={(event) => onChange?.(event.target.value)}
    error={touched && error}
    {...props}
  />
);

const NewTextAreaForm: React.VFC<ReplaceWithFieldProps<NewTextAreaProps>> = ({
  onChange,
  touched,
  error,
  isError: _,
  onTouch: __,
  ...props
}) => (
  <NewTextArea
    onChange={(event) => onChange?.(event.target.value)}
    error={touched && error}
    {...props}
  />
);

export { NewInputForm, NewTextAreaForm };
