import React from 'react';
import { hoc } from '@core/utils';
import { Button } from '@core';
import { useTableHeader } from './table-header.props';
import styles from './table-header.module.scss';
import { Filter } from '@mui/icons-material';

const TableHeader = hoc(
  useTableHeader,
  ({ isFilterTextHidden, tableHeader, onOpenFilterClick, additionalMenu }) => (
    <div className={styles.header}>
      <h1 className={styles.title}>{tableHeader}</h1>

      <div className={styles.buttons}>
        <Button
          className={styles.filterButton}
          onClick={onOpenFilterClick}
          theme='secondary'
        >
          <Filter className={styles.filterIcon} name='filter' />

          {!isFilterTextHidden && 'Інші критерії пошуку'}
        </Button>

        {additionalMenu}
      </div>
    </div>
  )
);

export { TableHeader };
