import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  getRelationship,
  getRelationshipRoles,
  getRelationshipsInfo,
  setEditing,
  updateRelationship
} from '@clients/store';
import { useFormik } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import { useClientId } from '@clients/pages/edit-client/hooks';
import { useLocation } from 'react-router';
import { isEqual } from 'lodash';
import { toSelectOption } from '../../lib';
import { useTypedSelector } from '@shared/lib/use-typed-selector';
import moment from 'moment';
import { getCompanyName } from '@shared/utils';

const useRelationshipsProps = () => {
  const dispatch = useDispatch();
  const clientId = useClientId();
  const { pathname } = useLocation();

  const { relationshipRoles, selectedRelationship, relationships } =
    useTypedSelector((state) => state.clients);

  const relationshipsInfo = useFormik({
    initialValues: {
      client1Id: selectedRelationship?.client1Id,
      client1Role: selectedRelationship?.client1Role?.id,
      client1RoleCompany: selectedRelationship?.client1Role?.group?.title,
      documentId: selectedRelationship?.documentId,
      createdAt: moment(selectedRelationship?.createdAt?.slice(0, 10)).format(
        'DD-MM-YYYY'
      ),
      client2Role: selectedRelationship?.client2Role?.id,
      firstName:
        clientId === selectedRelationship?.client1Id
          ? selectedRelationship?.client2?.firstName
          : selectedRelationship?.client1?.firstName,
      middleName:
        clientId === selectedRelationship?.client1Id
          ? selectedRelationship?.client2?.middleName
          : selectedRelationship?.client1?.middleName,
      lastName:
        clientId === selectedRelationship?.client1Id
          ? selectedRelationship?.client2?.lastName
          : selectedRelationship?.client1?.lastName,
      companyName:
        clientId === selectedRelationship?.client1Id
          ? getCompanyName({
              companyName: selectedRelationship?.client2?.companyName,
              lastName: selectedRelationship?.client2?.lastName,
              firstName: selectedRelationship?.client2?.firstName,
              middleName: selectedRelationship?.client2?.middleName
            })
          : getCompanyName({
              companyName: selectedRelationship?.client1?.companyName,
              lastName: selectedRelationship?.client1?.lastName,
              firstName: selectedRelationship?.client1?.firstName,
              middleName: selectedRelationship?.client1?.middleName
            })
    },
    onSubmit: (values, { setSubmitting }) => {
      const updateValues = {
        client1RoleId: values?.client1Role,

        client2RoleId: values?.client2Role
      };

      dispatch(
        updateRelationship({ ...updateValues, id: selectedRelationship.id })
      );
      setSubmitting(false);

      setTimeout(() => {
        dispatch(getRelationshipsInfo(clientId));
      }, 1000);
    },
    enableReinitialize: true
  });
  const newRelationships = relationships?.filter(
    (item) => item?.isActive && item?.group?.type?.title == 'Корпоративні'
  );
  const newSelfRelationships = relationships?.filter(
    (item) => item?.isActive && item?.group?.type?.title == 'Особисті'
  );
  const identification = (newRelationships ?? []).map((value) => {
    const client1Params = {
      id: value?.client1Id,
      role: value?.client1Role?.title,
      roleGroup: value?.client1Role?.group?.title,
      companyName: getCompanyName({
        companyName: value?.client1?.companyName,
        lastName: value?.client1?.lastName,
        firstName: value?.client1?.firstName,
        middleName: value?.client1?.middleName
      })
    };

    const client2Params = {
      id: value?.client2Id,
      role: value?.client2Role?.title,
      roleGroup: value?.client2Role?.group?.title,
      companyName: getCompanyName({
        companyName: value?.client2?.companyName,
        lastName: value?.client2?.lastName,
        firstName: value?.client2?.firstName,
        middleName: value?.client2?.middleName
      })
    };

    const owner = clientId === value.client1Id ? client1Params : client2Params;
    const target = clientId === value.client1Id ? client2Params : client1Params;

    return {
      id: value?.id,
      client1Role: owner.role,
      client1RoleCompany: owner.roleGroup,
      createdAt: moment(value?.createdAt?.slice(0, 10)).format('DD-MM-YYYY'),
      documentId: value?.documentId,
      client2Role: target.role,
      companyName: target.companyName
    };
  });
  const selfIdentification = (newSelfRelationships ?? []).map((value) => ({
    id: value?.id,
    client1Role: value?.client1Role?.title,
    client1RoleCompany: value?.client1Role?.group?.title,
    createdAt: moment(value?.createdAt?.slice(0, 10)).format('DD-MM-YYYY'),
    documentId: value?.documentId,
    client2Role: value?.client2Role?.title
  }));

  const onOpen = ({ id }: { id: string }) => {
    dispatch(getRelationship(id));
  };

  useEffect(() => {
    dispatch(
      setEditing(
        !isEqual(relationshipsInfo.values, relationshipsInfo.initialValues)
      )
    );
  }, [relationshipsInfo.values, relationshipsInfo.initialValues]);

  useEffect(() => {
    dispatch(getRelationshipsInfo(clientId));
    dispatch(getRelationshipRoles(''));
  }, [pathname]);

  return {
    identification,
    relationshipsInfo,
    onOpen,
    roles: relationshipRoles?.map(toSelectOption),
    selfIdentification
  };
};
const columns: GridColDef[] = [
  {
    field: 'client1Role',
    headerName: 'Назва моєї ролі',
    width: 150,
    editable: false,
    sortable: false
  },
  {
    field: 'companyName',
    headerName: 'Назва компанії',
    width: 150,
    editable: false,
    sortable: false
  },
  {
    field: 'createdAt',
    headerName: "Дата створення зв'язку",
    width: 200,
    editable: false,
    sortable: false
  },
  {
    field: 'documentId',
    headerName: 'Документ',
    width: 150,
    editable: false,
    sortable: false
  }
];
const detailColumns: GridColDef[] = [
  {
    field: 'client2Role',
    headerName: 'Назва моєї ролі',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'client1Role',
    headerName: 'Назва ролі іншої ФО',
    width: 180,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'createdAt',
    headerName: "Дата створення зв'язку",
    width: 200,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'documentId',
    headerName: 'Документ',
    width: 150,
    sortable: false,
    disableColumnMenu: true
  }
];

export { useRelationshipsProps, columns, detailColumns };
