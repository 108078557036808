import React, { useEffect } from 'react';

import { LastClientsShown } from '@features/clientsID';

import { useDispatch } from 'react-redux';
import { Client } from '@api';
import { navigate } from '@store/router';
import {
  getClients,
  getClientsByPhone,
  getCustomer,
  getCustomersById,
  getFullProfileFoCustomer,
  setClientId,
  setEditing
} from '@clients/store';

import { useSearchbar, useTableFilter } from '@app';

import { useTypedSelector } from '@shared/lib/use-typed-selector';

const useClientsListProps = () => {
  const dispatch = useDispatch();
  const { client, clients } = useTypedSelector((state) => state.clients);

  const { isFilterOpen, closeFilter, openFilter } = useTableFilter();

  const [openedId, setOpenedId] = React.useState(null);

  const onOpenClick = (clientId: Client['clientId']) => {
    dispatch(navigate(`/clients/fo/${clientId}/edit`));
    dispatch(setClientId([clientId]));
    dispatch(getFullProfileFoCustomer(clientId));
  };

  const onIconClick = (id: Client['clientId']) => {
    dispatch(getCustomer(id));
  };

  useEffect(() => {
    const clientsIdList = new LastClientsShown().getClientsID() ?? [];

    dispatch(getCustomersById({ clientIdList: clientsIdList }));
  }, []);

  useEffect(() => {
    dispatch(getClients());
    dispatch(setEditing(false));
  }, []);

  useSearchbar((query) => {
    dispatch(getClientsByPhone(query));
  });

  return {
    names: {
      rnokpp: client?.inn,
      phone: client?.financialPhone,
      ugbId: client?.ugbId,
      seria: client?.passport?.series,
      number: client?.passport?.number
    },

    clients: clients ?? [],
    openedId,
    isFilterOpen,
    closeFilter,
    openFilter,
    onOpenClick,
    onIconClick,
    onRowOpen: setOpenedId
  };
};

export { useClientsListProps };
