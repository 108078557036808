import axios, { AxiosRequestConfig } from 'axios';
import { History } from 'history';
import { Store } from 'redux';

import { HttpService } from '@shared/lib/http';

import { enviroment } from '@env';
import { GeneralService, UserService } from '@api';
import { DirectoryService } from '@shared/api';

import { Storage } from './storage';
import { CustomerService } from '@shared/api/customer.service';

/**
 * Get context
 */
const getContext = (history: History, store: Store) => {
  const storage = new Storage();

  const getConfig = (baseURL: string): AxiosRequestConfig => ({
    baseURL,
    withCredentials: true
  });

  const http = new HttpService(() => getConfig(enviroment.api.url), {
    401: () => true
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        window.location.href = error.response.data.data.loginUrl;
      }

      throw error;
    }
  );

  const api = {
    user: new UserService(http),
    //client: new ClientService(http),
    client: new CustomerService(http),
    general: new GeneralService(http),
    dictionary: new DirectoryService(http)
  };

  return {
    api,
    store,
    storage,
    history
  };
};

/**
 * Saga context
 */
type StoreContext = ReturnType<typeof getContext>;

export { StoreContext, getContext };
