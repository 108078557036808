import React from 'react';
import { Field, FilterSectionWrapper, hoc, TableFilterWrapper } from '@core';
import { useClientsTableFilter } from './clients-table-filter.props';
import { removeWhitespaces } from '@shared/utils/string';

const ClientsTableFilter = hoc(
  useClientsTableFilter,
  ({
    form,
    badges,
    responsibleManagers,
    closeFilter,
    onBadgeClose,
    onResetClick,
    onClearFilters
  }) => (
    <TableFilterWrapper
      use={form}
      onBackdropClick={closeFilter}
      onResetClick={onResetClick}
      badges={badges}
      onBadgeClose={onBadgeClose}
      onClearFilter={onClearFilters}
    >
      <FilterSectionWrapper header='РНОКПП'>
        <div>
          <Field.Input
            name='innCode'
            label=''
            formatValue={removeWhitespaces}
            showPlaceholder={false}
          />
        </div>
      </FilterSectionWrapper>

      <FilterSectionWrapper header='Паспорт'>
        <div>
          <Field.Input
            name='passportSeries'
            label='Серія'
            showPlaceholder={false}
          />
          <Field.Input
            name='passportNumber'
            label='Номер'
            formatValue={removeWhitespaces}
            showPlaceholder={false}
          />
        </div>
      </FilterSectionWrapper>

      <FilterSectionWrapper header='ПІБ'>
        <div>
          <Field.Input
            name='lastName'
            label='Прізвище'
            showPlaceholder={false}
          />

          <Field.Input name='firstName' label="Ім'я" showPlaceholder={false} />

          <Field.Input
            name='middleName'
            label='По-батькові'
            showPlaceholder={false}
          />
        </div>
      </FilterSectionWrapper>

      <FilterSectionWrapper header='Код UGB'>
        <div>
          <Field.Input
            name='ugbCode'
            formatValue={removeWhitespaces}
            showPlaceholder={false}
          />
        </div>
      </FilterSectionWrapper>

      <FilterSectionWrapper header='Відповідальний менеджер'>
        <div>
          <Field.NewSelect
            options={responsibleManagers}
            name='responsibleManagerId'
            showPlaceholder={false}
          />
        </div>
      </FilterSectionWrapper>
    </TableFilterWrapper>
  )
);

export { ClientsTableFilter };
