import React, { memo, ReactNode } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import classNames from 'classnames';

import { Formatter, hoc } from '@core/utils';
import { Label } from '../label';
import { Input } from '../input';

import { SelectThemes, useSelectProps } from './select.props';
import styles from './select.module.scss';

/**
 * DEPRECATED COMPONENT USAGE, WITH WRONG IMPLEMENTATION. Use @mui/material Select|Autocomplete
 */
const Select = hoc(
  useSelectProps,
  ({
    label,
    error,
    theme,
    query,
    select,
    focused,
    isError,
    options,
    onClick,
    selected,
    hasValue,
    caption,
    filtered,
    disabled,
    className,
    setQuery,
    selectors,
    reference,
    placeholder,
    onOptionClick,
    inputFocused,
    inputRef,
    setInputFocused,
    onSearchBlur,
    searchable,
    loading,
    notFoundText,
    loadingText,

    classnames
  }) => (
    <div
      className={classNames(styles.select, className, {
        [styles.selectFocused]: focused,
        [styles.selectHasError]: isError || !!error,
        [styles.selectHasValue]: hasValue,
        [styles.selectHasLabel]: !!label,
        [styles['select' + Formatter.capitalize(theme)]]: theme
      })}
      ref={reference}
    >
      {label && <Label>{label}</Label>}

      <div className={styles.fieldBox}>
        {searchable ? (
          <Input
            className={styles.inputMargin}
            select
            editable
            reference={inputRef}
            onFocus={() => setInputFocused(true)}
            onBlur={onSearchBlur}
            disabled={disabled}
            visibleValue
            value={inputFocused ? query : caption}
            onChange={setQuery}
          />
        ) : (
          <div className={styles.field} onClick={onClick}>
            {selected ? select(selectors.name, selected) : placeholder || ''}
          </div>
        )}

        <div className={styles.icon} onClick={onClick}>
          <KeyboardArrowDown
            className={styles.iconChevron}
            name='chevron-down'
          />
        </div>

        <SelectBox
          select={select}
          options={options}
          focused={focused}
          inputFocused={inputFocused}
          onOptionClick={onOptionClick}
          selectors={selectors}
          filtered={filtered}
          notFoundText={notFoundText}
          loading={loading}
          loadingText={loadingText}
        />
      </div>

      <SelectError
        error={error}
        isError={isError}
        theme={theme}
        className={classnames?.error}
      />
    </div>
  )
);

/**
 * DEPRECATED COMPONENT USAGE, WITH WRONG IMPLEMENTATION. Use @mui/material Select|Autocomplete
 */
const SelectBox = ({
  focused,
  inputFocused,
  filtered,
  options,
  select,
  selectors,
  onOptionClick,
  notFoundText,
  loadingText,
  loading
}) => {
  if (!(focused || inputFocused)) return null;

  const renderOptions: any[] = (inputFocused ? filtered : options) ?? [];

  const isNotFound = !renderOptions.length && !!notFoundText;

  return (
    <div className={styles.box}>
      <div style={{ minHeight: '100%', maxHeight: '215px' }}>
        {loading ? (
          <div
            key={loadingText}
            className={classNames(styles.option, styles.optionDisabled)}
            title={loadingText}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          >
            {loadingText}
          </div>
        ) : isNotFound ? (
          <div
            key={notFoundText}
            className={classNames(styles.option, styles.optionDisabled)}
            title={notFoundText}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          >
            {notFoundText}
          </div>
        ) : (
          renderOptions.map((option, index) => {
            const id = select(selectors.id, option);
            const name = select(selectors.name, option);

            return (
              <div
                data-id={id}
                key={`${index}-${id}`}
                className={styles.option}
                onMouseDown={() => onOptionClick(option)}
                title={typeof name == 'string' ? name : ''}
              >
                {name}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

const SelectError: React.VFC<{
  isError?: boolean;
  theme?: SelectThemes;
  error?: ReactNode;
  className?: string;
}> = memo(({ isError, theme, error, className }) => {
  if (
    !isError ||
    [SelectThemes.compact, SelectThemes.moreCompact].includes(theme)
  )
    return null;

  return <div className={classNames(styles.error, className)}>{error}</div>;
});

Select.defaultProps = {
  shape: {},
  value: null,
  options: [],
  theme: SelectThemes.default,
  placeholder: 'Вибрати...',
  loadingText: 'Пошук...'
};

export { Select };
