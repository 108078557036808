import React from 'react';
import { Field } from '@core/components';
import styles from './main-information.module.scss';
import { Role } from '@entities/role';
import { SectionLayout } from '../atoms';
import classNames from 'classnames';

import { useIsFo } from '../../hooks';

const MainInformation = () => {
  const isFo = useIsFo();

  return (
    <SectionLayout
      read={Role.PhysicalsRead}
      update={Role.PhysicalsUpdate}
      disableFlex
    >
      {() => (
        <>
          <Field.Input
            className={styles.input}
            name='lastName'
            label='Прізвище'
            theme='primary'
            editable={false}
          />

          <Field.Input
            className={styles.input}
            name='firstName'
            label="Ім'я"
            theme='primary'
            editable={false}
          />

          <Field.Input
            className={styles.input}
            name='middleName'
            label='По батькові'
            theme='primary'
            editable={false}
          />

          <Field.Input
            className={styles.input}
            name='dateOfBirth'
            label='Дата народження'
            theme='primary'
            editable={false}
          />

          <Field.Input
            className={styles.input}
            name='financialPhone'
            label='Фінансовий номер'
            theme='primary'
            editable={false}
          />

          <Field.Input
            className={styles.input}
            name='gender'
            label='Стать'
            theme='primary'
            editable={false}
          />

          <Field.Input
            className={styles.input}
            name='inn'
            label='РНОКПП'
            theme='primary'
            editable={false}
          />
          {!isFo && (
            <Field.Input
              className={classNames(styles.input, styles.fullwidth)}
              name='ownershipType'
              label='Форма власності'
              theme='primary'
              editable={false}
            />
          )}
          <Field.Input
            className={classNames(styles.input, styles.fullwidth)}
            name='responsibleManager'
            label='Відповідальний менеджер'
            theme='primary'
            editable={false}
          />
        </>
      )}
    </SectionLayout>
  );
};

export { MainInformation };
