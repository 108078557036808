import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store';
import { clearSearchResults } from '@store/search';

const useSearchbar = (onSearch: (query: string) => void) => {
  const dispatch = useDispatch();
  const { query } = useSelector((state: State) => state.search);

  useEffect(() => {
    if (!query) return;

    onSearch?.(query);
  }, [query]);

  useEffect(
    () => () => {
      dispatch(clearSearchResults());
    },
    []
  );
};

export { useSearchbar };
