import { reducer as createReducer } from 'redux-chill';

import { RoleState } from './state';

import { setRoles } from './actions';
import { Role } from '@entities/role';

const reducer = createReducer(new RoleState())
  .on(setRoles, (state, { roles }) => {
    state.roles = roles;
  })
  .on(setRoles.asArray, (state, { roles }) => {
    state.roles = roles.reduce(
      (kv, role) => ({ ...kv, [role]: true }),
      {} as Record<Role, true>
    );
  });

export { reducer };
