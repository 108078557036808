import React from 'react';
import { hoc } from '@core';
import { Field } from '@core/components';

import { EditDataGrid } from '@shared/ui/edit-data-grid';

import { useDocumentProps } from './documents.props';
import styles from './documents.module.scss';
import { SectionLayout } from '../atoms';
import { Role } from '@entities/role';
import classNames from 'classnames';

const Documents = hoc(
  useDocumentProps,
  ({ identification, documentInfo, columns, isOpenModal, onOpen }) => (
    <SectionLayout read={Role.DocumentRead} update={Role.DocumentUpdate}>
      {({ canUpdate }) => (
        <EditDataGrid
          form={documentInfo}
          rows={identification}
          columns={columns}
          isOpenModal={isOpenModal}
          onOpen={onOpen}
          onSubmit={documentInfo.handleSubmit}
          onReset={documentInfo.handleReset}
          canUpdate={canUpdate}
          dialogMaxWidth='xl'
        >
          <Field.Input
            className={classNames(styles.input, styles.fullwidth)}
            name='typeId'
            theme='primary'
            label={'Тип'}
            editable={false}
          />

          <div className={styles.block}>
            <Field.Input
              className={classNames(styles.input)}
              name='docSeries'
              label='Серія'
              theme='primary'
              editable={canUpdate}
            />
            <Field.Input
              className={classNames(styles.input)}
              name='docNumber'
              label='Номер'
              theme='primary'
              editable={canUpdate}
            />
            <br />
          </div>
          <Field.Input
            className={classNames(styles.input)}
            name='statusId'
            theme='primary'
            label='Статус'
            editable={false}
          />
          <Field.Date
            datepicker
            className={styles.input}
            name='issueDate'
            label='Дата видачі'
            theme='primary'
            editable={canUpdate}
            disabled={!canUpdate}
          />
          <Field.Date
            className={styles.input}
            name='validUntilDate'
            label='Дійсний до'
            theme='primary'
            editable={canUpdate}
            disabled={!canUpdate}
          />
          <Field.Input
            className={classNames(styles.input, styles.fullwidth)}
            name='issuedBy'
            label='Виданий'
            theme='primary'
            editable={canUpdate}
          />

          <Field.Input
            className={classNames(styles.input, styles.fullwidth)}
            name='clientId'
            label='UUID'
            theme='primary'
            editable={canUpdate}
          />
        </EditDataGrid>
      )}
    </SectionLayout>
  )
);

export { Documents };
