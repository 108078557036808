import React from 'react';
import { hoc } from '@core';
import { Field } from '@core/components';
import { SectionLayout } from '../../atoms';
import { useLegalExtendedInformationProps } from './legal-extended-information.props';
import styles from './legal-extended-information.module.scss';
import { Role } from '@entities/role';
import classNames from 'classnames';

const LegalExtendedInformation = hoc(
  useLegalExtendedInformationProps,
  ({
    names,
    clientSubTypes,
    types,
    customerSegments,
    customerChannels,
    activityCodes
  }) => (
    <SectionLayout
      read={Role.LegalEntityFullRead}
      update={Role.LegalEntityUpdate}
      disableFlex
    >
      {({ canUpdate }) => (
        <>
          <Field.Input
            className={classNames(styles.input, styles.fullwidth)}
            name='name'
            label='Назва ЮО'
            theme='primary'
            editable={false}
          />
          <Field.Input
            className={styles.input}
            name='status'
            label='Статус'
            theme='primary'
            editable={false}
          />

          <Field.Input
            className={styles.input}
            name='edrpou'
            label='ЄДРПОУ'
            theme='primary'
            editable={canUpdate}
          />
          <Field.Input
            className={styles.input}
            name='eddrCode'
            label='ЄДДР код'
            theme='primary'
            editable={canUpdate}
          />

          <Field.Input
            className={styles.input}
            name='expectedIncome'
            label='Очікуваний заробіток'
            theme='primary'
            editable={canUpdate}
          />
          <Field.Select
            className={styles.inputMargin}
            name='ownershipTypeId'
            label='Форма власності'
            options={types}
            disabled={!canUpdate}
          />
          <Field.Select
            className={styles.inputMargin}
            name='clientSegmentId'
            options={customerSegments}
            label={'Сегмент'}
            disabled={!canUpdate}
          />
          <Field.Select
            className={styles.inputMargin}
            name='registrationChannelId'
            options={customerChannels}
            label={'Джерело реєстрації'}
            disabled={!canUpdate}
          />
          <Field.Input
            className={styles.input}
            name='registrationPlaceCode'
            label='Код місця реєстрації'
            theme='primary'
            editable={canUpdate}
          />

          <Field.Input
            className={styles.input}
            name='registrationDate'
            label='Дата реєстрації'
            theme='primary'
            editable={false}
          />

          <Field.Select
            className={styles.inputMargin}
            name='subTypeId'
            label='Підтип клієнта'
            options={clientSubTypes}
            disabled={!canUpdate}
          />

          <Field.NewMultipleSelect
            name='codes'
            names={names}
            inputLabel='Цілі'
            disabled={!canUpdate}
          />

          <div className={styles.switch}>
            <div className={styles.child}>
              <Field.NewSwitch
                className={styles.input}
                name='isResident'
                label='Резидент'
                disabled={!canUpdate}
              />
            </div>

            <div className={styles.child}>
              <Field.NewSwitch
                className={styles.input}
                name='employeesExistence'
                label='Наймані працівники'
                disabled={!canUpdate}
              />
            </div>

            <div className={styles.child}>
              <Field.NewSwitch
                className={styles.input}
                name='licenseExistence'
                label='Ліцензії'
                disabled={!canUpdate}
              />
            </div>

            <div className={styles.child}>
              <Field.NewSwitch
                className={styles.input}
                name='otherBankAccountsExistence'
                label='Рахунки в інших банках'
                disabled={!canUpdate}
              />
            </div>

            <div className={styles.child}>
              <Field.NewSwitch
                className={styles.input}
                name='otherBankRejection'
                label='Відмови в інших банках'
                disabled={!canUpdate}
              />
            </div>

            <div className={styles.child}>
              <Field.NewSwitch
                className={styles.input}
                name='canDisturb'
                label='Можна турбувати'
                disabled={!canUpdate}
              />
            </div>

            <div className={styles.child}>
              <Field.NewSwitch
                className={styles.input}
                name='permProcessData'
                label='Дозвіл на обробку даних'
                disabled={!canUpdate}
              />
            </div>

            <div className={styles.child}>
              <Field.NewSwitch
                className={styles.input}
                name='permReceivePromoMailings'
                label='Дозвіл на отримання розсилок'
                disabled={!canUpdate}
              />
            </div>

            <div className={styles.child}>
              <Field.NewSwitch
                className={styles.input}
                name='existsCredOut'
                label='Кредити в інших банках'
                disabled={!canUpdate}
              />
            </div>
          </div>
          <Field.NewMultipleSelect
            name='clientEconomicActivities'
            names={activityCodes}
            inputLabel='КВЕД'
            disabled={!canUpdate}
          />
        </>
      )}
    </SectionLayout>
  )
);

export { LegalExtendedInformation };
